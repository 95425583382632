const DrawerIcon = () => {
  return (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M18.7498 11.2752C18.0983 11.2752 17.5666 10.7448 17.5666 10.0933C17.5666 9.44074 18.0983 8.91029 18.7498 8.91029C19.4012 8.91029 19.9329 9.44074 19.9329 10.0933C19.9329 10.7448 19.4012 11.2752 18.7498 11.2752ZM14.4463 6.8423C13.7936 6.8423 13.2632 6.31185 13.2632 5.66041C13.2632 5.00774 13.7936 4.47729 14.4463 4.47729C15.0978 4.47729 15.6282 5.00774 15.6282 5.66041C15.6282 6.31185 15.0978 6.8423 14.4463 6.8423ZM8.80207 8.17696C8.15062 8.17696 7.62018 7.64529 7.62018 6.99385C7.62018 6.34118 8.15062 5.81196 8.80207 5.81196C9.45473 5.81196 9.9852 6.34118 9.9852 6.99385C9.9852 7.64529 9.45473 8.17696 8.80207 8.17696ZM5.69151 13.1929C5.04007 13.1929 4.50962 12.6625 4.50962 12.0098C4.50962 11.3571 5.04007 10.8267 5.69151 10.8267C6.34418 10.8267 6.8734 11.3571 6.8734 12.0098C6.8734 12.6625 6.34418 13.1929 5.69151 13.1929ZM21.3849 4.62274C19.4146 2.25774 16.3029 0.949962 12.624 0.941406H12.5898C6.35151 0.941406 1.75718 4.85252 0.880844 10.9122C0.3834 14.3455 1.51151 17.8288 3.97796 20.4688C6.19507 22.8436 9.16873 24.1636 12.2439 24.1636C12.5115 24.1636 12.7816 24.1538 13.0518 24.1342C14.1236 24.0523 14.9535 23.6075 15.3862 22.8802C15.8128 22.1652 15.8189 21.2596 15.4033 20.3319C15.1466 19.7575 14.9071 18.9508 15.2383 18.3715C15.5414 17.841 16.2491 17.6577 16.7893 17.5966C19.3132 17.3155 21.3629 16.2106 22.557 14.4836C24.6592 11.4525 23.5592 7.22729 21.3849 4.62274Z"
        fill="black"
      />
    </svg>
  );
};

export default DrawerIcon;
