import BoonModal from "../../Modal";
import Button from "../../Button";
import ModalHeader from "../../ModalHeader.tsx";
import Table, { TableColumn } from "../../Table";
import useGetMissionStats from "../../../hooks/useMissions/useGetMissionStats";
import * as XLSX from "xlsx";
import { Loader } from "../../Loader";

const StatColumn = ({ label, value }: { label: string; value: string }) => (
  <div className="flex flex-col border border-solid border-surface-150 rounded-lg gap-2.5 pb-3 w-full">
    <p className="bg-surface-150 w-full p-3 body-sm-medium text-surface-700">
      {label}
    </p>
    <p className="pl-3 title-lg-medium text-surface-900">{value}</p>
  </div>
);

const missionStatsColumns: TableColumn[] = [
  { _id: "name", title: "Name", type: "text" },
  { _id: "email", title: "E-mail", type: "text" },
  { _id: "completedTasks", title: "Completed tasks", type: "text" },
  { _id: "nextTask", title: "Next task", type: "text" },
];

const calculatePercentage = (number1?: number, number2?: number) =>
  number1 && number2 ? `${Math.floor((number1 / number2) * 100)}%` : "0%";

const MissionStatsModal = ({
  onClose,
  missionId,
}: {
  onClose: () => void;
  missionId: string;
}) => {
  const { data, isLoading } = useGetMissionStats({ _id: missionId });
  const totalUsers = data?.users?.length || 0;

  const missionStatsRows = data?.users?.map((user: any) => ({
    ...user,
    nextTask: user.nextTask?.type,
  }));

  const exportUserBreakdown = () => {
    if (!missionStatsRows || missionStatsRows.length === 0) {
      alert("No data available to export!");
      return;
    }

    const data = missionStatsRows.map((row: any) => {
      const result: Record<string, string> = {};
      missionStatsColumns.forEach((column) => {
        result[column.title] = row[column._id];
      });
      return result;
    });

    const worksheet = XLSX.utils.json_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "User Breakdown");
    XLSX.writeFile(workbook, "user_breakdown.xlsx");
  };

  return (
    <BoonModal onClose={onClose} width="754px">
      {isLoading ? (
        <div className="w-full h-full flex justify-center items-center">
          <Loader size="lg" />
        </div>
      ) : (
        <>
          <ModalHeader title="Mission Statistic" onClose={onClose} />
          <div className="flex flex-col gap-8">
            <div className="flex flex-col gap-3">
              <p className="body-lg-medium text-surface-700">Overview</p>
              <div className="flex gap-3 w-full">
                <StatColumn
                  label="Members who started mission"
                  value={`${totalUsers}`}
                />
                <StatColumn
                  label="Completed mission"
                  value={`${data?.totalCompleted}`}
                />
                <StatColumn
                  label="Average completion"
                  value={calculatePercentage(data?.totalCompleted, totalUsers)}
                />
              </div>
              <div className="flex flex-col max-h gap-3 w-full">
                <p className="body-lg-medium text-surface-700">
                  User Breakdown
                </p>
                <Table
                  bodyClassName="max-h-72 overflow-auto"
                  rows={missionStatsRows}
                  columns={missionStatsColumns}
                />
              </div>
              <div className="flex justify-end">
                <Button className="py-3 px-5" onClick={exportUserBreakdown}>
                  Export User Breakdown
                </Button>
              </div>
            </div>
          </div>
        </>
      )}
    </BoonModal>
  );
};

export default MissionStatsModal;
