import { ReactNode } from "react";
import AnalyticsCard, { AnalyticsCardTypes } from "./AnalyticsCard";
import Table, { TableColumn } from "../../Table";
import { Loader } from "../../Loader";

const AnalyticsSection = ({
  title,
  children,
  className = "",
}: {
  title: string;
  children: ReactNode;
  className?: string;
}) => {
  return (
    <div className={`flex flex-col w-full gap-3 ${className}`}>
      <h3 className="text-body-lg text-[#4F575E] capitalize">{title}</h3>
      {children}
    </div>
  );
};

export interface Analytics {
  averageCompletionPercentage: number;
  communityId: string;
  totalMembers: number;
  totalMissionCompletedMembers: number;
  totalMissionStartedMembers: number;
  users: {
    completedMissions: number;
    completedTasks: number;
    email: string;
    name: string;
    startedMissions: number;
    totalMissions: number;
    totalTasks: number;
  }[];
}

const CommunityAnalytics = ({ analytics }: { analytics: Analytics }) => {
  const analyticBoxs: AnalyticsCardTypes[] = [
    {
      title: "members",
      value: analytics?.totalMembers,
    },
    {
      title: "members who have started mission",
      value: analytics?.totalMissionStartedMembers,
    },
    {
      title: "members who have completed mission",
      value: analytics?.totalMissionCompletedMembers,
    },
    {
      title: "avarage completion",
      value: `${analytics?.averageCompletionPercentage} %`,
    },
  ];

  const dummyTableColumns: TableColumn[] = [
    { _id: "name", title: "Name", type: "text" },
    { _id: "email", title: "E-mail", type: "text" },
    { _id: "completedTasks", title: "Completed tasks", type: "text" },
    { _id: "start", title: "start", type: "text" },
    { _id: "end", title: "end", type: "text" },
  ];

  // const dummyTableRows = analytics?.users.map((user:any) => ({
  //   name: user.name,
  //   email: user.email,
  //   completedTasks: `${user.completedTasks}/${user.totalTasks}`,
  //   start: `${user.startedMissions}/${user.totalMissions}`,
  //   end: `${user.completedMissions}/${user.totalMissions}`
  // }))

  const dummyTableRows = analytics?.users.map((user: any) => ({
    name: user.name,
    email: user.email,
    completedTasks: `${user.completedTasks}/${user.totalTasks}`,
    start: `${user.startedMissions}/${user.totalMissions}`,
    end: `${user.completedMissions}/${user.totalMissions}`,
  }));

  return (
    <>
      {!analytics ? (
        <div className="w-full flex items-center h-full justify-center">
          <Loader />
        </div>
      ) : (
        <div className="w-full px-6 py-6">
          <div className="w-full flex flex-col gap-6 rounded-xl bg-white p-6">
            <h3 className="text-title-lg text-[#4F575E]">Welcome to</h3>
            <div className="flex flex-col gap-6">
              <AnalyticsSection title="overview">
                <div className="w-full flex gap-3 mmd:flex-col">
                  {analyticBoxs.map((analytic, index) => (
                    <AnalyticsCard key={index} {...analytic} />
                  ))}
                </div>
              </AnalyticsSection>
              <AnalyticsSection
                title="user breakedown"
                className="mmd:overflow-x-auto"
              >
                <Table
                  columns={dummyTableColumns}
                  rows={dummyTableRows as any}
                />
              </AnalyticsSection>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
export default CommunityAnalytics;
