import { useNavigate } from "react-router-dom";
import { useAuthContext } from "../../hooks";
import NavbarItem from "./NavbarItem";
import UserLoggedIn from "./UserLoggedIn";

const HeaderWithLeftNavbar = () => {
  const { isValidating } = useAuthContext();

  const navigate = useNavigate();

  return (
    <div className="sticky top-0 z-10 navbar items-center flex justify-end py-[30px] px-10 w-full gap-4 bg-surface-100">
      <div className="flex gap-6 py-2 px-4">
        <NavbarItem
          text="Dashboard"
          onClick={() => navigate("/dashboard")}
          disabled={isValidating}
        />
        <NavbarItem
          text="Discover"
          onClick={() => navigate("/discovery")}
          disabled={isValidating}
        />
      </div>
      <div className="flex justify-between items-center">
        <UserLoggedIn />
      </div>
    </div>
  );
};

export default HeaderWithLeftNavbar;
