import React from "react";
import Button from "../Button";

type ColumnType = "text" | "button";

export interface TableColumn {
  _id: string;
  title: string;
  type: ColumnType;
}

export interface TableRow {
  _id: string;
  [key: string]: string;
}

const componentMapper: Record<
  ColumnType,
  React.ComponentType<{ value: string; action: () => void }>
> = {
  text: ({ value }) => <p>{value}</p>,
  button: ({ value, action }) => <Button action={action}>{value}</Button>,
};

export default function Table({
  columns,
  rows,
  action,
  bodyClassName = "",
}: {
  columns: TableColumn[];
  rows: TableRow[];
  action?: (data: TableRow) => void;
  bodyClassName?: string;
}) {
  return (
    <div className="flex w-full justify-center">
      <div className="w-full rounded-lg border border-surface-250 overflow-hidden">
        <table className="w-full">
          <thead>
            <tr className="border-b border-solid border-surface-250 bg-surface-50">
              {columns?.map((column) => (
                <th
                  key={column._id}
                  className="px-3 py-4 body-sm-medium text-surface-500 text-start"
                >
                  {column.title}
                </th>
              ))}
            </tr>
          </thead>
          <tbody className={bodyClassName}>
            {rows?.map((row, rowIndex) => (
              <tr
                key={row._id}
                className={`${
                  rowIndex % 2 !== 0 ? "bg-surface-50" : "bg-surface-150"
                }`}
              >
                {columns?.map((column) => {
                  const Component = componentMapper[column.type];
                  return (
                    <td
                      key={column._id}
                      className="text-start px-3 py-4 body-sm-medium text-surface-700"
                    >
                      <Component
                        value={row[column._id] as string}
                        action={() => (action ? action(row) : undefined)}
                      />
                    </td>
                  );
                })}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}
