import { useEffect, useRef } from "react";
import cn from "classnames";
import Button from "../Button";
import TagInput from "../TagInput";
import { TextInput } from "../TextInput";
import UploadFile from "../UploadFile";
import { useForm } from "react-hook-form";
import SocialMediaEditor from "../SocialMediaEditor";
import { SocialNetworkLink } from "../../types";
import Criteria from "../Criteria";
import { TextAreaInput } from "../TextAreaInput";
import DateInput from "../DateInput";
import CalendarIcon from "../../assets/icons/calendar";
import { Loader } from "../Loader";
import moment from "moment-timezone";

export interface CreatorFormTypes {
  socialNetworkLinks?: SocialNetworkLink[];
  setSocialNetworkLinks?: (data: any) => void;
  handleDelete?: (prop1?: string) => void;
  handleUpdate?: (
    prop1: "networkName" | "url",
    prop2: string,
    prop3?: string,
  ) => void;
  formFields: {
    label?: string;
    name: string;
    type: string;
    placeholder?: string;
    description?: string;
    onChange?: (input: any) => void;
    validation?: any;
    element?: any;
  }[];
  onSubmit: (data: any) => any;
  defaultValues?: any;
  submitButtonTitle?: string;
  isPending?: boolean;
  disableMaxHeight?: boolean;
}

const CreatorForm = ({
  formFields,
  onSubmit,
  defaultValues,
  submitButtonTitle = "submit",
  socialNetworkLinks = [],
  setSocialNetworkLinks = () => {},
  handleDelete = () => {},
  handleUpdate = () => {},
  isPending,
  disableMaxHeight,
}: CreatorFormTypes) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    control,
    reset,
    getValues,
  } = useForm();

  // Add URL validation function
  const isValidUrl = (url: string) => {
    if (!url.includes(window.origin + "/@") && url !== window.origin + "/@") {
      return false;
    }

    const slug = url.replace(window.origin + "/@", "");

    const regex = /^[a-zA-Z0-9]*$/;
    if (!regex.test(slug)) {
      return false;
    }

    return true;
  };

  // Add URL change handler
  const handleUrlChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    const newUrl = e.target.value;
    const currentValue = getValues("url") || "";

    if (isValidUrl(newUrl)) {
      setValue("url", newUrl);
    } else {
      // Keep the previous valid value
      setValue("url", currentValue);
      // Reset the input value to the last valid state
      e.target.value = currentValue;
    }
  };

  useEffect(() => {
    if (defaultValues) {
      reset(defaultValues);
    }
  }, [defaultValues]);

  const isSubmittingRef = useRef(false);

  const submit = async (data: any) => {
    if (isSubmittingRef.current) return;
    isSubmittingRef.current = true;

    try {
      const isSubmited = await onSubmit(data);
      if (isSubmited) {
        reset();
      }
    } finally {
      isSubmittingRef.current = false;
    }
  };

  const checkKeyDown = (e: any) => {
    if (e.key === "Enter") e.preventDefault();
  };

  return (
    <form
      onSubmit={handleSubmit(submit)}
      onKeyDown={(e) => checkKeyDown(e)}
      className="flex flex-col gap-8 overflow-auto"
    >
      <div
        className={cn("flex flex-col gap-3 w-full overflow-auto", {
          "max-h-[500px]": !disableMaxHeight,
        })}
      >
        {formFields.map((form: any, index: number) => {
          switch (form.type) {
            case "text":
              return (
                <TextInput
                  {...form}
                  key={index}
                  registerInForm={register}
                  errorMessage={errors?.[form.name]?.message as string}
                  onChange={
                    form.name === "url" ? handleUrlChange : form.onChange
                  }
                />
              );
              break;

            case "file":
              return (
                <UploadFile
                  {...form}
                  key={index}
                  setValue={setValue}
                  errorMessage={errors?.[form.name]?.message as string}
                  control={control}
                  label={form.label}
                />
              );
              break;

            case "tagable":
              return (
                <TagInput
                  key={index}
                  setValueInForm={setValue}
                  registerInForm={register}
                  errorMessage={errors?.[form.name]?.message as string}
                  defaultValue={defaultValues?.[form.name]}
                  {...form}
                />
              );
              break;

            case "repeatable":
              return (
                <SocialMediaEditor
                  socialNetworkLinks={socialNetworkLinks}
                  setSocialNetworkLinks={setSocialNetworkLinks}
                  handleDelete={handleDelete}
                  handleUpdate={handleUpdate}
                  direction="row"
                  key={index}
                />
              );
              break;

            case "criteria":
              return (
                <Criteria
                  key={index}
                  setValueInForm={setValue}
                  registerInForm={register}
                  errorMessage={errors?.[form.name]?.message as string}
                  {...form}
                />
              );
              break;
            case "date":
              return (
                <>
                  <div className="flex flex-col gap-3">
                    <div className="flex flex-col justify-between gap-1">
                      <p className="body-lg text-surface-900">{form.label}</p>
                      <div className="flex items-center border border-surface-250 rounded-lg pl-3">
                        <CalendarIcon />
                        <div className="flex items-center">
                          <DateInput
                            name="validityPeriodStart"
                            placeholder={form.placeholder}
                            value={form.defaultValue}
                            onChange={(data) => {
                              const utcDate = moment(data).utc().format();
                              setValue(form.name, utcDate);
                            }}
                            startFrom="left"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              );
              break;

            case "textarea":
              return (
                <TextAreaInput
                  key={index}
                  setValueInForm={setValue}
                  registerInForm={register}
                  errorMessage={errors?.[form.name]?.message as string}
                  {...form}
                />
              );
              break;

            default:
              return "";
          }
        })}
      </div>
      {isPending ? (
        <div className="flex justify-center items-center">
          <Loader size="md" />
        </div>
      ) : (
        <Button
          type="submit"
          disabled={isSubmittingRef.current}
          className="w-full py-3"
        >
          {submitButtonTitle}
        </Button>
      )}
    </form>
  );
};

export default CreatorForm;
