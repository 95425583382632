import { cloneElement, useEffect, useRef, useState } from "react";
import { DropDownTypes } from "../Dropdown";
import ArrowDown from "../../assets/icons/arrowDown";
import classNames from "classnames";
import QuestionMarkIcon from "../../assets/icons/questionMark";
import BoonTooltip from "../Tooltip";

interface RowProps {
  display: string;
  value: string;
  Icon?: any;
  onClick: () => void;
  isSelected: boolean;
  displayTextOverride?: string;
  tooltip?: string;
  comingSoon?: boolean;
  index?: number;
}

const DropDownRow = ({
  Icon,
  display,
  displayTextOverride,
  onClick,
  isSelected,
  comingSoon,
  tooltip,
  index,
}: RowProps) => {
  const dropDownRowStyle = classNames(
    "flex items-center gap-2 p-2 cursor-pointer hover:bg-blue-100",
    {
      "bg-blue-100": isSelected,
      "!text-[#8E979F]": comingSoon,
      "cursor-default": comingSoon,
    },
  );

  return (
    <div onClick={() => !comingSoon && onClick()} className={dropDownRowStyle}>
      {Icon && <Icon width={18} height={18} color="#8E979F" />}
      <p className="body-md text-surface-900">
        {displayTextOverride || display}
      </p>
      {tooltip && <QuestionMarkIcon id={`dropdown-tip-${index}`} />}
      {tooltip && (
        <BoonTooltip
          style={{ position: "fixed" }}
          id={`dropdown-tip-${index}`}
          content={tooltip}
        />
      )}
    </div>
  );
};

const DropDownFilter = ({
  data,
  name,
  arrowIcon = true,
  defaultIcon,
  setValueForm,
  setStateValue,
  placeholder = "Select an option",
  multiSelector = false,
  value,
}: DropDownTypes) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedValue, setSelectedValue] = useState(value || "");
  const dropdownRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setIsOpen(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleSelect = (name: string, selectedValue: string) => {
    setSelectedValue(selectedValue);

    if (multiSelector) {
      setStateValue?.((prev: string[]) =>
        prev.includes(selectedValue) ? prev : [...prev, selectedValue],
      );
    } else {
      setStateValue?.(selectedValue);
    }

    setValueForm?.(name, selectedValue);
    setIsOpen(false);
  };

  const multiSelectorFilter = (index: number) =>
    multiSelector ? `${name}[${index}]` : name;

  const selectedDisplay = data?.find((item) => item.value === selectedValue);

  const dropDownStyle = classNames(
    "flex items-center text-center justify-between py-2 px-4 bg-transparent border rounded-xl cursor-pointer text-gray-900 body-md w-fit",
    {
      "border-surface-250": !isOpen,
      "border-primary-500": isOpen,
    },
  );

  const activeText = classNames(
    "text-center text-nowrap capitalize body-lg flex gap-2.5 items-center",
    {
      "text-primary-500": isOpen,
      "text-black": !isOpen,
    },
  );

  const comingSoonItems = data.filter((x) => x.comingSoon === true);
  const availableItems = data.filter((x) => !x.comingSoon);

  return (
    <div ref={dropdownRef} className="relative">
      <div className={dropDownStyle} onClick={() => setIsOpen(!isOpen)}>
        <div className="flex items-center w-full gap-2">
          {selectedDisplay?.Icon && (
            <selectedDisplay.Icon width={18} height={18} color="#8E979F" />
          )}
          <p className={activeText}>
            {cloneElement(defaultIcon as any, {
              className: `${isOpen ? "fill-primary-500" : ""}`,
            })}
            {selectedDisplay?.display || placeholder}
          </p>
        </div>
        {arrowIcon ? <ArrowDown /> : ""}
      </div>
      {isOpen && (
        <div className="absolute top-12 left-0 w-full border border-surface-250 bg-white rounded-lg shadow-md max-h-60 overflow-y-auto z-50">
          {availableItems.map((item, index) => (
            <DropDownRow
              key={index}
              Icon={item.Icon}
              value={item.value}
              display={item.display}
              displayTextOverride={item?.displayTextOverride}
              onClick={() =>
                handleSelect(multiSelectorFilter(index), item.value)
              }
              isSelected={item.value === selectedValue}
            />
          ))}
          {comingSoonItems.length > 0 ? (
            <div className="w-full py-1">
              <h3 className="border-b text-body-md p-2 border-solid py-1 border-[#EBEBEB] text-[#FF8800] pb-2 mb-1">
                Coming soon
              </h3>
              {comingSoonItems.map((item, index) => (
                <DropDownRow
                  key={item.value}
                  Icon={item.Icon}
                  display={item.display}
                  value={item.value}
                  onClick={() =>
                    handleSelect(multiSelectorFilter(index), item.value)
                  }
                  isSelected={selectedValue === item.value}
                  tooltip={item.tooltip}
                  index={index}
                  comingSoon={item.comingSoon}
                />
              ))}
            </div>
          ) : (
            ""
          )}
        </div>
      )}
    </div>
  );
};

export default DropDownFilter;
