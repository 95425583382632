import { useContext } from "react";
import { PrivacyContext } from "../context/PrivacyContext";

const usePrivacyContext = () => {
  const context = useContext(PrivacyContext);

  if (!context) {
    throw new Error("useAuthContext must be used within an AuthProvider");
  }

  return context;
};

export default usePrivacyContext;
