import classNames from "classnames";
import { useContext } from "react";
import { CheckCircleIcon } from "../../assets/icons";
import { useRewards } from "../../hooks";
import { Reward } from "../../hooks/useCommunity";
import Button, { ButtonType } from "../Button";
import { Loader } from "../Loader";
import { RewardContext } from "../../context/RewardContext";
import RewardRedemptionModal from "../RewardRedemption";

const dummyImage =
  "https://s3-alpha-sig.figma.com/img/1936/272c/e41088ff5c73dfe2c528602fdf802a20?Expires=1735516800&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=b0aF1-z4xe~QlNzEOBJ3vtRkEieTfcgxZmO1Rq6SzxcFTO2ThZ4qfq-dawa7sod12zGP3Mbh0~zSm85cI6qSKTmG6j6ToyhYbpv6TnXElGnEMLRKg6aH~HmqFS1V9cyYeYTb~52FJZoQHbnGudpWYmwa8QCk50~ltE~7cCk6o6vfurWn2G0ITCQdADNFKvATBcDmafFpSv6yvY2spk1wSelOIOP0xo~AmBCYbJFVEVa3K9dYHXJe~Xusy95sBdWiVkqc3298e4W7UpcmhlabxkPgTbNlo8N-OvOKra8QPnQd2B3Nkz8-gms8Ex0y5XiOSaxoy4syY58HaYPKEF2kuA__";

export const RedeemableReward = ({
  name,
  fileLink,
  rewardImage,
  rewardEmoji,
  onClick,
}: Reward & { onClick: () => void }) => {
  const image = classNames("rounded-t-lg", {
    "h-full": !rewardEmoji,
    "w-full": !rewardEmoji,
  });
  const imageLayout = classNames("h-32", {
    flex: rewardEmoji,
    "justify-center": rewardEmoji,
    "items-center": rewardEmoji,
  });
  return (
    <div className="flex flex-col rounded-lg border border-solid border-surface-250 pb-2 gap-1 justify-between">
      <div className={imageLayout}>
        <img src={rewardImage || rewardEmoji || fileLink} className={image} />
      </div>
      <div className="px-2 flex justify-between items-center">
        <p className="body-sm text-surface-900">{name}</p>
        <Button
          category={ButtonType.CUSTOM}
          className="bg-primary-400 text-white hover:bg-primary-500 active:bg-primary-600 rounded-xl px-2.5 py-1.5"
          action={onClick}
        >
          Redeem
        </Button>
      </div>
    </div>
  );
};

export const RedeemedReward = ({
  name,
  fileLink,
  rewardImage,
  rewardEmoji,
  onClick,
}: Reward & { onClick: () => void }) => {
  const image = classNames("rounded-t-lg", {
    "h-full": !rewardEmoji,
    "w-full": !rewardEmoji,
  });
  const imageLayout = classNames("h-32", {
    flex: rewardEmoji,
    "justify-center": rewardEmoji,
    "items-center": rewardEmoji,
  });

  return (
    <div className="flex flex-col rounded-lg border border-solid border-surface-250 pb-2 gap-1">
      <div className={imageLayout}>
        <img src={rewardImage || rewardEmoji || fileLink} className={image} />
      </div>
      <div className="px-2 flex flex-col gap-2">
        <p className="body-sm text-surface-900">{name}</p>
        <div className="flex justify-center w-full">
          <Button
            category={ButtonType.CUSTOM}
            className="bg-surface-250 text-surface-900 rounded-xl w-fit px-4 py-2"
            action={onClick}
          >
            <div className="flex items-center gap-2">
              <CheckCircleIcon />
              <p>Redeemed</p>
            </div>
          </Button>
        </div>
      </div>
    </div>
  );
};

const ItemsManagement = () => {
  const { data: rewards, isLoading: isRewardsLoading } = useRewards();
  const { activeRedeemableReward, setActiveRedeemableReward } =
    useContext(RewardContext);

  if (isRewardsLoading) {
    return (
      <div className="bg-surface-100 flex h-screen justify-center items-center">
        <Loader size="lg" />
      </div>
    );
  }

  return (
    <div className="w-fu flex flex-col gap-4 rounded-xl ml-6 mr-6 mmd:mt-6 mmd:mx-4">
      {activeRedeemableReward && <RewardRedemptionModal />}
      <div className="p-6 rounded-xl gap-6 flex flex-col bg-surface-50 w-full">
        <p className="title-lg-medium text-surface-700">Redeemable</p>
        <div className="grid grid-cols-2 md:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-6 gap-4 w-full">
          {rewards?.redeemableRewards?.map((reward: Reward) => (
            <RedeemableReward
              {...reward}
              onClick={() =>
                setActiveRedeemableReward({ ...reward, status: "redeemable" })
              }
            />
          ))}
        </div>
      </div>
      <div className="p-6 rounded-xl gap-6 flex flex-col bg-surface-50 w-full">
        <p className="title-lg-medium text-surface-700">Redeemed</p>
        <div className="grid grid-cols-2 md:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-6 gap-4 w-full">
          {rewards?.redeemedRewards?.map((reward: Reward) => (
            <RedeemedReward
              {...reward}
              onClick={() =>
                setActiveRedeemableReward({ ...reward, status: "redeemed" })
              }
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default ItemsManagement;
