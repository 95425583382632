import CreatorForm from "../CreatorForm";
import { useCallback, useState } from "react";
import ModalHeader from "../ModalHeader.tsx";
import PreviewBox from "./PreviewBox";
import {
  useCreateMission,
  useUpdateMission,
} from "../../hooks/useCreateMission";
import { useAuthContext, useCommunityManagementContext } from "../../hooks";
import { convertToTimezone } from "../../utils";
import { useNavigate } from "react-router-dom";

export interface CreateMissionTypes {
  onClose: () => void;
}

const CreateMission = ({ onClose }: CreateMissionTypes) => {
  const { activeMission, activeCommunity, activeSection, setActiveMission } =
    useCommunityManagementContext();
  const { user } = useAuthContext();
  const [currentValues, setCurrentValues] = useState<Record<string, string>>(
    activeMission ? { ...activeMission, mission_name: activeMission.name } : {},
  );

  const navigate = useNavigate();

  const {
    mutate: createMission,
    isPending: isCreateMissionPending,
    isSuccess: isCreateMissionSuccess,
  } = useCreateMission({ communityId: activeCommunity?._id });
  const {
    mutate: updateMission,
    isPending: isUpdateMissionPending,
    isSuccess: isUpdateMissionSuccess,
  } = useUpdateMission();

  const handleSubmit = async (data: any) => {
    const payload = {
      ...data,
      tags: data.tags?.filter((tag: any) => tag),
      name: data.mission_name,
      entryCriteria: {
        minimumTwitterFollowers: data.minimumTwitterFollowers,
      },
    };

    if (activeSection?._id) payload.sectionId = activeSection._id;

    if (activeMission?._id) {
      updateMission(
        { ...payload, _id: activeMission._id },
        {
          onSuccess: () => {
            setActiveMission(undefined);
            onClose();
          },
        },
      );

      return isUpdateMissionSuccess;
    } else {
      createMission(payload, {
        onSuccess: (data) => {
          onClose();
          setActiveMission(undefined);
          navigate(
            `/community/management/${activeCommunity._id}/mission-manager/${data._id}`,
          );
        },
      });

      return isCreateMissionSuccess;
    }
  };

  const handleChange = useCallback((input: any) => {
    const { name, value, files } = input.target;
    setCurrentValues((prev) => ({
      ...prev,
      [name]: files ? files[0] : value,
    }));
  }, []);

  const formFields = [
    {
      label: "Mission Name",
      name: "mission_name",
      type: "text",
      placeholder: "Name",
      validation: {
        required: "name is required",
      },
      onChange: handleChange,
      defaultValue: activeMission?.name,
    },
    {
      label: "Cover Image",
      name: "coverImageUrl",
      type: "file",
      placeholder: "Click to upload or drag and drop",
      description: "SVG, PNG, (max. 800x400px)",
      validation: {
        required: "Cover Image is required",
      },
      onChange: handleChange,
      defaultValue: activeMission?.imageUrl,
    },
    {
      label: "Description",
      name: "description",
      type: "textarea",
      placeholder: "description",
      validation: {
        required: "description is required",
      },
      onChange: handleChange,
      defaultValue: activeMission?.description,
    },
    {
      label: "Disclaimer",
      name: "disclaimer",
      type: "textarea",
      placeholder: "e.g. You can only redeem the reward for this mission ...",
      validation: {
        required: "Disclaimer is required",
      },
      onChange: handleChange,
      defaultValue: activeMission?.disclaimer,
    },
    {
      label: "Valid From",
      name: "validFrom",
      type: "date",
      placeholder: "Valid From",
      defaultValue: activeMission?.validFrom
        ? convertToTimezone(activeMission.validFrom, user?.timezone?.value)
        : undefined,
    },
    {
      label: "Valid To",
      name: "validTo",
      type: "date",
      placeholder: "Valid To",
      defaultValue: activeMission?.validTo
        ? convertToTimezone(activeMission.validTo, user?.timezone?.value)
        : undefined,
    },
    {
      label: "Choose Tag",
      name: "tags",
      type: "tagable",
      validation: {
        required: "tags is required",
      },
      onChange: handleChange,
      defaultValue: activeMission?.tags,
    },
    {
      label: "Choose entry criteria",
      name: "minimumTwitterFollowers",
      type: "criteria",
      placeholder: "0",
      criteriaLabel: "Followers minimum on X",
      validate: {
        required: "Criteria is required",
      },
      defaultValue: activeMission?.entryCriteria?.minimumTwitterFollowers || 0,
    },
  ];

  return (
    <div className="flex items-start gap-7 w-full">
      <div className="border-surface-200 border-solid border p-6 w-full rounded-2xl bg-surface-50 h-full">
        <ModalHeader
          title={activeMission ? "Update a Mission" : "Create a Mission"}
          onClose={onClose}
        />
        <div className="flex flex-col gap-3 overflow-auto h-full">
          <CreatorForm
            formFields={formFields}
            onSubmit={handleSubmit}
            submitButtonTitle={
              activeMission ? "Update Mission" : "Create Mission"
            }
            isPending={isCreateMissionPending || isUpdateMissionPending}
          />
        </div>
      </div>
      <PreviewBox values={currentValues} />
    </div>
  );
};

export default CreateMission;
