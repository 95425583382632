import { ReactNode, useMemo } from "react";
import Modal from "react-modal";
import { useScreenSize } from "../../hooks";

type BoonModalProps = {
  onClose: () => void;
  label?: string;
  children: ReactNode;
  style?: object;
  width?: string;
  fullscreen?: boolean;
  options?: ModalOptions;
};

type ModalOptions = Partial<{
  mobileMinVh: string;
  mobileHeight: string;
  maxHeight: string;
}>;

const BoonModal = ({
  onClose,
  label,
  children,
  style,
  width = "552px",
  fullscreen = false,
  options,
}: BoonModalProps) => {
  const { isMobile } = useScreenSize();

  const modalStyle = useMemo(() => {
    const customStyles = getCustomStyles(options);
    return {
      content: {
        ...customStyles.content,
        ...style,
        width: isMobile ? "100%" : width,
        ...(isMobile ? customStyles.mobile.content : {}),
        ...(fullscreen ? customStyles.fullscreen.content : {}),
      },
      overlay: { ...customStyles.overlay },
    };
  }, [fullscreen, isMobile, options, style, width]);

  return (
    <Modal
      style={{
        content: modalStyle.content as ReactModal.Styles["content"],
        overlay: modalStyle.overlay,
      }}
      isOpen={true}
      onRequestClose={onClose}
      ariaHideApp={false}
      contentLabel={label}
    >
      {children}
    </Modal>
  );
};

const getCustomStyles = (options: ModalOptions | undefined) => ({
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    overflow: "visible",
    transform: "translate(-50%, -50%)",
    borderRadius: "24px",
    transition: "all 0.3s ease-in-out",
  },
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    zIndex: "999",
  },
  mobile: {
    content: {
      top: "auto",
      left: "0",
      right: "0",
      bottom: "0",
      marginRight: "0",
      transform: "none",
      minHeight: options?.mobileMinVh || "70vh",
      borderRadius: "24px 24px 0 0",
      maxHeight: options?.maxHeight || "90vh",
      overflowY: "auto",
      ...(options?.mobileHeight && {
        height: options.mobileHeight,
      }),
    },
  },
  fullscreen: {
    content: {
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      marginRight: 0,
      transform: "none",
      borderRadius: 0,
      width: "100%",
      height: "100%",
      maxHeight: "100vh",
    },
  },
});

Modal.setAppElement("#root");

export default BoonModal;
