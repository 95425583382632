import { useState } from "react";
import HorizontalMenu from "../../../assets/icons/horizontalMenu.jsx";
import MenuIcon from "../../../assets/icons/menu.jsx";
import { Mission } from "../../../hooks/useCommunity/index.js";
import ScoreCard from "../../CommunityCards/ScoreCard";
import Popover from "../../Popover";
import useDuplicateMission from "../../../hooks/useMissions/useDuplicateMission";
import DeactivateMissionModal from "../EditMission/DeactivateModal";
import MissionStatsModal from "../EditMission/MissionStatsModal";
import MissionStatusTag from "./MissionStatusTag";

function removeElClass(elements: any, elClass: any, elClass2: any) {
  elements = [].slice.call(elements);
  for (const el of elements) {
    el.classList.remove(elClass);
    el.classList.remove(elClass2);
  }
  return elements;
}

function setHoverEffect(event: any) {
  const childList = removeElClass(
    event.currentTarget.parentElement.children,
    "border-surface-250",
    "border-primary-500",
  );
  if (
    !childList.some((child: any) => child.classList.contains("sortable-chosen"))
  ) {
    event.currentTarget.classList.add("border-primary-500");
  }
}

const DropdownItem = ({
  text,
  onClick,
  danger,
  success,
}: {
  text: string;
  onClick?: () => void;
  className?: string;
  danger?: boolean;
  success?: boolean;
}) => {
  return (
    <p
      className={`text-body-md hover:bg-surface-150 px-6 py-2 w-full cursor-pointer ${danger ? "text-danger-200" : success ? "text-success-200" : "text-surface-900"}`}
      onClick={onClick}
    >
      {text}
    </p>
  );
};

const DropdownContent = ({
  missionId,
  setMissionId,
  setModal,
  duplicateMission,
  missionActive,
}: {
  missionId: string;
  setMissionId: (data: string) => void;
  setModal: (data: string) => void;
  duplicateMission: () => void;
  missionActive: boolean;
}) => {
  return (
    <div className="flex flex-col border border-solid border-surface-250 rounded-xl ease-in-out py-6 bg-surface-50">
      <DropdownItem
        text="Copy mission link"
        onClick={() => {
          const currentPath = window.location.origin;
          navigator.clipboard.writeText(`${currentPath}/mission/${missionId}`);
        }}
      />
      <DropdownItem
        text="Edit Mission"
        onClick={() => setMissionId(missionId)}
      />
      <DropdownItem
        text="See mission stats"
        onClick={() => setModal("stats")}
      />
      <DropdownItem text="Duplicate mission" onClick={duplicateMission} />
      <DropdownItem
        text={missionActive ? "Deactivate mission" : "Activate mission"}
        danger={missionActive}
        success={!missionActive}
        onClick={() => setModal("deactivate")}
      />
    </div>
  );
};

const CommunityManagerSingleMission = ({
  mission,
  setMissionId,
}: {
  mission: Mission;
  setMissionId: (data: string) => void;
}) => {
  const [modal, setModal] = useState<string | undefined>();
  const { duplicateMission } = useDuplicateMission({ missionId: mission._id });

  return (
    <div
      className="flex rounded-xl border border-solid border-surface-250 justify-between p-4 cursor-pointer"
      onMouseOver={setHoverEffect}
      onDragEnd={setHoverEffect}
      onDrag={setHoverEffect}
    >
      {modal === "deactivate" && (
        <DeactivateMissionModal
          onClose={() => setModal(undefined)}
          missionActive={mission.active}
          missionId={mission._id}
        />
      )}

      {modal === "stats" && (
        <MissionStatsModal
          onClose={() => setModal(undefined)}
          missionId={mission._id}
        />
      )}

      <div className="flex gap-3 items-center">
        <MenuIcon />
        <p className="body-lg-medium text-surface-900">{mission.name}</p>
        <p className="rounded-full bg-[#DFFAFE] body-md-medium text-[#55A8D9] px-2 py-1.5">
          {mission.tasks?.length || 0}
        </p>
      </div>
      <div className="flex gap-5">
        <MissionStatusTag mission={mission} />
        <div className="flex gap-16 items-center ">
          <div className="flex gap-4 mmd:hidden">
            {mission.tags?.map((tag) => (
              <p
                key={`${mission._id}-${tag}`}
                className="rounded p-1.5 bg-surface-250 body-sm-medium text-surface-700"
              >
                {tag}
              </p>
            ))}
            {mission.rewards?.map((reward) => (
              <ScoreCard key={reward._id} score={reward.name || "unknown"} />
            ))}
          </div>
          <Popover
            className="flex items-center p-0 m-0 transition-all duration-500"
            closeOnClick={true}
            content={
              <DropdownContent
                missionId={mission._id}
                setMissionId={setMissionId}
                missionActive={mission.active}
                setModal={setModal}
                duplicateMission={duplicateMission}
              />
            }
          >
            <HorizontalMenu />
          </Popover>
        </div>
      </div>
    </div>
  );
};

export default CommunityManagerSingleMission;
