import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useAPI } from "../useApi";
import { QUERY_KEYS } from "../../constants";
import { useErrorHandler } from "../useErrorHandler";

export const useCreateSection = () => {
  const { post } = useAPI();
  const queryClient = useQueryClient();
  const { handleApiError } = useErrorHandler();

  return useMutation({
    mutationFn: async (data: any) =>
      post(`/api/community/${data.communityId}/section`, { ...data.payload }),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.COMMUNITY] });
    },
    onError: (error) => {
      handleApiError(error);
    },
  });
};

export const useUpdateSection = () => {
  const { put } = useAPI();
  const queryClient = useQueryClient();
  const { handleApiError } = useErrorHandler();

  return useMutation({
    mutationFn: async (data: any) =>
      put(`/api/community/${data.communityId}/section/${data._id}`, {
        ...data.payload,
      }),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.COMMUNITY] });
    },
    onError: (error) => {
      handleApiError(error);
    },
  });
};
