import { useState, useRef, useEffect, SetStateAction, ReactNode } from "react";
import ArrowDown from "../../assets/icons/arrowDown";
import QuestionMarkIcon from "../../assets/icons/questionMark";
import BoonTooltip from "../Tooltip";
import classNames from "classnames";

export interface DropDownTypes {
  data: {
    display: string;
    value: string;
    Icon?: any;
    displayTextOverride?: string;
    tooltip?: string;
    comingSoon?: boolean;
  }[];
  name: string;
  validation?: any;
  registerInForm?: (name: string, validation: any) => object;
  setValueForm?: (name: string, value: any) => void; // require setValue prop
  setStateValue?: SetStateAction<any>;
  placeholder?: string;
  multiSelector?: boolean;
  arrowIcon?: boolean;
  defaultIcon?: ReactNode;
  value?: string | string[];
  label?: string;
  labelClassName?: string;
}

interface RowProps {
  display: string;
  value: string;
  Icon?: any;
  onClick: () => void;
  isSelected: boolean;
  tooltip?: string;
  comingSoon?: boolean;
  index?: number;
}

const DropDownRow = ({
  Icon,
  display,
  onClick,
  isSelected,
  tooltip,
  index,
  comingSoon,
}: RowProps) => {
  const dropDownRowStyle = classNames(
    "flex items-center hover:bg-blue-100 gap-2 p-2 cursor-pointer",
    {
      "bg-blue-100": isSelected,
      "!text-[#8E979F]": comingSoon,
      "cursor-default": comingSoon,
    },
  );

  return (
    <div onClick={() => !comingSoon && onClick()} className={dropDownRowStyle}>
      {Icon && <Icon width={18} height={18} color="#8E979F" />}
      <p className="body-md cursor">{display}</p>
      {tooltip && <QuestionMarkIcon id={`dropdown-tip-${index}`} />}
      {tooltip && (
        <BoonTooltip
          style={{ position: "fixed" }}
          id={`dropdown-tip-${index}`}
          content={tooltip}
        />
      )}
    </div>
  );
};

export const SingleDropDown = ({
  data,
  name,
  setValueForm,
  setStateValue,
  placeholder = "Select an option",
  label,
  labelClassName = "",
  value,
}: DropDownTypes) => {
  const [selectedValue, setSelectedValue] = useState<string>(placeholder);
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (setStateValue && placeholder !== selectedValue) {
      setStateValue(selectedValue);
    }
  }, [selectedValue]);

  useEffect(() => {
    if (value && typeof value === "string") {
      setSelectedValue(value);
    }

    function handleClickOutside(event: MouseEvent) {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setIsOpen(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleSelect = (value: string) => {
    setSelectedValue(value);
    setValueForm?.(name, value);
    setIsOpen(false);
  };

  const comingSoonItems = data.filter((x) => x.comingSoon === true);
  const availableItems = data.filter((x) => !x.comingSoon);

  return (
    <div className="flex flex-col gap-2 items-start w-full">
      {label && (
        <p className={`body-lg font-medium text-surface-700 ${labelClassName}`}>
          {label}
        </p>
      )}
      <div ref={dropdownRef} className="relative w-full">
        <div
          className="flex items-center justify-between p-4 bg-transparent border border-surface-250 rounded-lg cursor-pointer text-gray-900 body-md w-full"
          onClick={() => setIsOpen(!isOpen)}
        >
          <span>{selectedValue}</span>
          <ArrowDown />
        </div>
        {isOpen && (
          <div className="absolute top-full left-0 mt-2 w-full border border-surface-250 bg-white rounded-lg shadow-md max-h-60 overflow-y-auto z-40">
            {availableItems.map((item, index) => (
              <DropDownRow
                key={item.value}
                Icon={item.Icon}
                display={item.display}
                value={item.value}
                onClick={() => handleSelect(item.value)}
                isSelected={selectedValue === item.value}
                tooltip={item.tooltip}
                index={index}
              />
            ))}
            {comingSoonItems.length > 0 ? (
              <div className="w-full py-1">
                <h3 className="border-b text-body-md p-2 border-solid py-1 border-[#EBEBEB] text-[#FF8800] pb-2 mb-1">
                  Coming soon
                </h3>
                {comingSoonItems.map((item, index) => (
                  <DropDownRow
                    key={item.value}
                    Icon={item.Icon}
                    display={item.display}
                    value={item.value}
                    onClick={() => handleSelect(item.value)}
                    isSelected={selectedValue === item.value}
                    tooltip={item.tooltip}
                    index={index}
                    comingSoon={item.comingSoon}
                  />
                ))}
              </div>
            ) : (
              ""
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export const MultiDropDown = ({
  data,
  name,
  setValueForm,
  value = [],
  setStateValue,
  placeholder = "Select options",
  label,
  labelClassName = "",
}: DropDownTypes) => {
  const [selectedValues, setSelectedValues] = useState<string[]>(
    Array.isArray(value) ? value : [],
  );
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (Array.isArray(value)) {
      setSelectedValues(value);
    }
  }, [value]);

  useEffect(() => {
    if (setStateValue) setStateValue(selectedValues);
  }, [selectedValues, setStateValue]);

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setIsOpen(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleSelect = (value: string) => {
    setSelectedValues((prev) => {
      const isSelected = prev.includes(value);
      const updatedValues = isSelected
        ? prev.filter((v) => v !== value)
        : [...prev, value];
      setValueForm?.(name, updatedValues);
      return updatedValues;
    });
  };

  const comingSoonItems = data.filter((x) => x.comingSoon === true);
  const availableItems = data.filter((x) => !x.comingSoon);

  return (
    <div className="flex flex-col gap-2 items-start w-full">
      {label && (
        <p className={`body-lg font-medium text-surface-700 ${labelClassName}`}>
          {label}
        </p>
      )}
      <div ref={dropdownRef} className="relative w-full">
        <div
          className="flex items-center justify-between p-4 bg-transparent border border-surface-250 rounded-lg cursor-pointer text-gray-900 body-md w-full"
          onClick={() => setIsOpen(!isOpen)}
        >
          <span>
            {selectedValues.length ? selectedValues.join(", ") : placeholder}
          </span>
          <ArrowDown />
        </div>
        {isOpen && (
          <div className="absolute top-full left-0 mt-2 w-full border border-surface-250 bg-white rounded-lg shadow-md max-h-60 overflow-y-auto z-50">
            {availableItems.map((item, index) => (
              <DropDownRow
                key={item.value}
                Icon={item.Icon}
                display={item.display}
                onClick={() => handleSelect(item.value)}
                isSelected={selectedValues.includes(item.value)}
                value={item.value}
                tooltip={item.tooltip}
                index={index}
              />
            ))}
            {comingSoonItems.length > 0 ? (
              <div className="w-full py-1">
                <h3 className="border-b text-body-md p-2 border-solid py-1 border-[#EBEBEB] text-[#FF8800] pb-2 mb-1">
                  Coming soon
                </h3>
                {comingSoonItems.map((item, index) => (
                  <DropDownRow
                    key={item.value}
                    Icon={item.Icon}
                    display={item.display}
                    value={item.value}
                    onClick={() => handleSelect(item.value)}
                    isSelected={selectedValues.includes(item.value)}
                    tooltip={item.tooltip}
                    index={index}
                    comingSoon={item.comingSoon}
                  />
                ))}
              </div>
            ) : (
              ""
            )}
          </div>
        )}
      </div>
    </div>
  );
};
