import { useEffect, useState } from "react";
import EditButton from "../../Button/EditButton";
import Button, { ButtonType } from "../../Button";
import { Mission, Task } from "../../../hooks/useCommunity";
import classNames from "classnames";
import MenuIcon from "../../../assets/icons/menu";
import PlusIcon from "../../../assets/icons/plus";
import { ReactSortable } from "react-sortablejs";
import TaskModal from "./TaskModal";
import { useTask } from "../../../hooks/useTask";
import RewardModal from "./RewardModal";
import { useCommunityManagementContext } from "../../../hooks";
import CreateMissionModal from "../../CreateMissionModal";
import DrawerIcon from "../../../assets/icons/drawer";
import RocketIcon from "../../../assets/icons/rocket";
import LaunchedModal from "../../LaunchedModal";
import useLaunchMission from "../../../hooks/useMissions/useLaunchMission";
import Tooltip from "../../Tooltip";
import ArrowSide from "../../../assets/icons/arrowSide";
import LaunchedWarningModal from "../../LaunchedModal/LaunchedWarningModal";

const dummyImage =
  "https://s3-alpha-sig.figma.com/img/902c/5110/167cbdd56b840b66989e8bb19cbc603e?Expires=1732492800&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=nuXU~Azjx2-W6VWgAdeQpS0R2uweyLkSMHqaVxFuo34fmiupI3JFckzMrTUOFVTLn7yPdb8ZGh-LiLXUor4-Y-sJkZAzsCEK-nj9CLjE6ypFjDAYtaXppzHF3xCXWeRdVE4dyYqBSGCPikeNLMuvtKjcqME2KW46MAfea-UjG2eY4I61Vf4OjeFVyKMBShfPTujhaM3ZVBqTnhaYHrlmmaznNmsuZEddQPkdqlOXV5DTjYBizetbAf7s9SNrI8fMSqHiBmvNLODXzzNdtoxEAwm40V73nXV13-lcpUVWlI0Huoyl2S3QkAXFfIovLpj~53tMUaSfScoieExDsWyrDQ__";

const EditMissionBody = ({
  data,
  goBack,
}: {
  data: Mission | undefined;
  goBack: () => void;
}) => {
  const { mutate: order } = useTask();
  const { setActiveMission, activeMission } = useCommunityManagementContext();
  const [activeTask, setActiveTask] = useState<Task | undefined>();
  const [launchModal, setLaunchModal] = useState<boolean>(false);
  const [launchWarningModal, setLaunchWarningModal] = useState<boolean>(false);

  const { mutate: launcher, isPending: launchIsPending } = useLaunchMission();

  const launcherHandle = () => {
    launcher(activeMission?._id as string, {
      onSuccess: () => {
        setActiveMission((prev: Mission) =>
          prev ? { ...prev, launched: true } : prev,
        );
        setLaunchModal(false);
        setLaunchWarningModal(true);
      },
    });
  };

  const [tasks, setTasks] = useState(
    data?.tasks?.map((elem, index) => ({
      ...elem,
      id: elem._id || `${index}`,
    })) || [],
  );

  const [modalType, setModalType] = useState<string | null>(null);

  useEffect(() => {
    if (data) {
      setTasks(
        data.tasks?.map((elem, index) => ({
          ...elem,
          id: elem._id || `${index}`,
        })),
      );
      setActiveMission(data);
    }

    return () => setActiveMission(undefined);
  }, [data, setActiveMission]);

  const handleDrop = (oldIndex: number, newIndex: number) => {
    if (oldIndex === newIndex) return;
    const updatedTasks = [...tasks];
    const [movedItem] = updatedTasks.splice(oldIndex, 1);
    updatedTasks.splice(newIndex, 0, movedItem);
    setTasks(updatedTasks);
    order({ _id: activeMission?._id, payload: updatedTasks });
  };

  return (
    <div className="flex flex-col bg-surface-50 gap-6">
      {activeMission?.launched ? (
        <>
          <Tooltip id="edit-tooltip" content="launched" />
          <Tooltip id="reward-add-tooltip" content="launched" />
          <Tooltip id="task-add-tooltip" content="launched" />
        </>
      ) : (
        ""
      )}
      {!modalType ? null : ["createTask", "updateTask"].includes(modalType) ? (
        <TaskModal
          modalType={modalType}
          onClose={() => setModalType(null)}
          task={activeTask}
          missionId={activeMission?._id}
        />
      ) : null}
      {!modalType ? null : ["createReward", "updateReward"].includes(
          modalType,
        ) ? (
        <RewardModal
          modalType={modalType}
          onClose={() => setModalType(null)}
          reward={activeMission?.reward}
          missionId={activeMission?._id}
        />
      ) : modalType === "createMission" ? (
        <CreateMissionModal onClose={() => setModalType(null)} />
      ) : null}
      <div className="flex justify-between">
        <p className="title-lg-medium text-surface-700">Mission</p>
        <div className="flex items-center gap-5">
          <Button category={ButtonType.SECONDARY} action={goBack}>
            <p className="flex items-center gap-2">
              <ArrowSide /> Back
            </p>
          </Button>
          {!activeMission?.launched && (
            <Button
              onClick={() => setLaunchModal(true)}
              className="flex items-center gap-2"
            >
              <RocketIcon />
              Launch
            </Button>
          )}
          {activeMission?.launched && (
            <p className="flex items-center gap-2 px-4 py-2 bg-surface-150 rounded-lg">
              ✅
              <span className="text-surface-700 text-body-md-bold">Vetted</span>
            </p>
          )}
          {launchWarningModal ? (
            <LaunchedWarningModal
              onClose={() => setLaunchWarningModal(false)}
            />
          ) : null}
          {launchModal && !activeMission?.launched ? (
            <LaunchedModal
              isPending={launchIsPending}
              onSubmit={launcherHandle}
              onClose={() => setLaunchModal(false)}
            />
          ) : null}
        </div>
      </div>
      <div className="flex flex-col gap-4">
        <div className="flex flex-col border border-solid border-surface-250 gap-4 p-1.5 pb-6 rounded-xl">
          <img
            src={activeMission?.imageUrl || dummyImage}
            alt="mission avatar"
            className="rounded-md max-w-[500px] max-h-[300px] min-h-[160px]"
          />
          <div className="flex px-[18px] justify-between mmd:flex-col mmd:gap-4">
            <div className="flex flex-col">
              <p className="body-xl-medium text-surface-900">
                {activeMission?.name}
              </p>
              <p className="body-lg text-surface-700">
                {activeMission?.description}
              </p>
            </div>
            <EditButton
              id="edit-tooltip"
              text="Edit"
              onClick={() => {
                if (activeMission?.launched) return;

                setModalType("createMission");
              }}
            />
          </div>
        </div>
        <div className="flex flex-col border border-solid border-surface-250 rounded-xl p-6 gap-6">
          <div className="flex justify-between w-full items-center">
            <p className="body-xl-medium text-surface-700">Reward</p>
            {activeMission?.rewards?.length ? (
              <Button
                category={
                  activeMission?.launched
                    ? ButtonType.SECONDARY
                    : ButtonType.PRIMARY
                }
                id="reward-add-tooltip"
                className="px-4 py-2"
                onClick={() => {
                  if (activeMission?.launched) return;

                  setModalType("updateReward");
                }}
              >
                <div className="flex items-center gap-2.5">
                  <PlusIcon
                    color={activeMission?.launched ? "black" : "white"}
                  />{" "}
                  Add
                </div>
              </Button>
            ) : (
              <Button
                id="reward-add-tooltip"
                className="px-4 py-2"
                category={
                  activeMission?.launched
                    ? ButtonType.SECONDARY
                    : ButtonType.PRIMARY
                }
                action={() => {
                  if (activeMission?.launched) return;
                  setModalType("createReward");
                }}
              >
                <div className="flex items-center gap-2.5">
                  <PlusIcon
                    color={activeMission?.launched ? "black" : "white"}
                  />{" "}
                  Add
                </div>
              </Button>
            )}
          </div>
          <div className="flex flex-col gap-2 max-h-[300px] overflow-auto">
            {activeMission?.rewards?.map((elem: any) => (
              <div
                key={elem._id}
                className="flex rounded-lg border border-solid border-surface-250 "
              >
                <div className="flex border-l-4 rounded-l-lg border-[#DFDAEF] p-4 gap-6 w-full">
                  <div className="flex justify-between w-full items-center">
                    <div className="flex gap-3 items-center">
                      <div className="bg-[#DFDAEF]" />
                      <img
                        src={elem.rewardImage || elem.rewardEmoji}
                        alt="reward"
                        className="w-11 h-11 rounded-lg"
                      />
                      <p className="body-lg-bold text-surface-900">
                        {elem.name || elem.type}
                      </p>
                    </div>
                    <p className="py-1.5 px-3 rounded-[5px] bg-[#DFDAEF] h-fit">
                      {elem.totalRedeemLimit || 0}
                    </p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="flex flex-col border border-solid border-surface-250 rounded-xl gap-6 p-6">
          <div className="flex justify-between items-center">
            <p className="body-xl-medium text-surface-700">Task</p>
            <Button
              id="task-add-tooltip"
              className="px-4 py-2"
              category={
                activeMission?.launched
                  ? ButtonType.SECONDARY
                  : ButtonType.PRIMARY
              }
              action={() => {
                if (activeMission?.launched) return;
                setModalType("createTask");
              }}
            >
              <div className="flex items-center gap-2.5">
                <PlusIcon color={activeMission?.launched ? "black" : "white"} />{" "}
                Add
              </div>
            </Button>
          </div>
          <div
            onDragStart={(e) => activeMission?.launched && e.preventDefault()}
            onDrop={(e) => activeMission?.launched && e.preventDefault()}
          >
            <ReactSortable
              list={tasks || []}
              setList={setTasks}
              onEnd={(e) => handleDrop(e.oldIndex || 0, e.newIndex || 0)}
              disabled={activeMission?.launched}
              filter={activeMission?.launched ? "*" : undefined}
              preventOnFilter={true}
              className="flex flex-col gap-2"
              animation={300}
              easing="cubic-bezier(0.25, 0.8, 0.25, 1)"
            >
              {tasks?.map((elem) => (
                <div
                  className={classNames(
                    "flex rounded-lg justify-between border border-solid p-4 gap-6 cursor-pointer mmd:w-full mmd:flex-1",
                    {
                      "border-surface-250": elem?._id !== activeTask?._id,
                      "border-primary-300 pl-2": elem?._id === activeTask?._id,
                    },
                  )}
                  onMouseDown={() => setActiveTask(elem)}
                  key={elem?._id}
                >
                  <div className="flex gap-3 items-center">
                    {elem?._id === activeTask?._id && <MenuIcon />}
                    <div className="w-11 h-11 rounded-lg bg-[#F4E8A3] flex items-center justify-center p-2">
                      <DrawerIcon />
                    </div>
                    <div className="flex flex-col w-full mmd:w-[80px]">
                      <p className="body-lg-bold text-surface-900">
                        {elem.type}
                      </p>
                      <p className="body-sm text-surface-700 truncate break-words">
                        {elem.link}
                      </p>
                    </div>
                  </div>
                  {elem?._id === activeTask?._id && (
                    <EditButton
                      onClick={() => {
                        if (activeMission?.launched) return;
                        setModalType("updateTask");
                      }}
                    />
                  )}
                </div>
              ))}
            </ReactSortable>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditMissionBody;
