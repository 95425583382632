const YoutubeIcon = (props) => (
  <svg
    width={17}
    height={17}
    viewBox="0 0 17 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.0751 7.02923L7.32108 8.59673C7.20633 8.65898 7.08333 8.68972 6.96183 8.68972C6.83208 8.68972 6.70383 8.65448 6.58833 8.58547C6.3663 8.45347 6.23355 8.22173 6.23355 7.96373V4.82797C6.23355 4.56547 6.37005 4.3285 6.59808 4.19725C6.82458 4.066 7.09458 4.0645 7.32258 4.19576L10.0751 5.76323C10.3016 5.89073 10.4426 6.13447 10.4426 6.39547C10.4426 6.65797 10.3016 6.90023 10.0751 7.02923ZM14.7986 2.54275C14.6043 1.74025 13.9983 1.09825 13.2176 0.863505C11.8923 0.426255 4.00155 0.451005 2.79255 0.889755C2.0088 1.11325 1.39905 1.75825 1.2108 2.53975C0.766802 3.838 0.766802 8.97023 1.20405 10.2542C1.42155 11.038 2.03055 11.6582 2.7678 11.863C3.41655 12.0985 5.7333 12.2147 8.03658 12.2147C10.3248 12.2147 12.6011 12.1 13.2116 11.8705C13.9953 11.638 14.6036 10.9937 14.7888 10.2242C15.2313 8.95072 15.2328 3.832 14.7986 2.54275Z"
      fill="#8E979F"
    />
  </svg>
);
export default YoutubeIcon;
