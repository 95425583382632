import { useRedeemReward, useUser } from "../../hooks";
import Button, { ButtonType } from "../Button";
import { useNavigate } from "react-router-dom";
import { TextInput } from "../TextInput";
import { useForm } from "react-hook-form";
import { Loader } from "../Loader";
import { useContext } from "react";
import { RewardContext } from "../../context/RewardContext";
import { formatToLocalDate } from "../../utils";

const FirstPartyRewardRedemption = () => {
  const { user } = useUser();
  const navigate = useNavigate();
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm();
  const { mutate, isPending } = useRedeemReward();
  const { activeRedeemableReward, setActiveRedeemableReward } =
    useContext(RewardContext);

  const onSubmit = (data: any) => {
    if (activeRedeemableReward.status === "redeemable") {
      mutate({ rewardId: activeRedeemableReward._id, payload: data });
    }
  };

  return (
    <div className="flex flex-col p-1.5 pb-6 gap-6">
      <img
        src={activeRedeemableReward.rewardImage}
        className="rounded-2xl w-full aspect-video rounded-t-[10px] object-contain"
      />
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="flex flex-col px-2.5 gap-16">
          <div className="flex flex-col gap-4">
            <div className="flex flex-col gap-4">
              <p className="title-xl-bold text-surface-900">
                Congrats {user?.name}!
              </p>
              <div className="flex flex-col gap-2.5">
                <p className="body-lg text-surface-900">
                  You've completed {activeRedeemableReward.mission?.name}
                </p>
                <p className="body-lg text-surface-500">
                  {activeRedeemableReward.successMessage}
                </p>
              </div>
            </div>
            {activeRedeemableReward.status === "redeemable" && (
              <TextInput
                registerInForm={register}
                name={"address"}
                label="Enter your address"
                labelClassName="body-lg text-surface-900"
                placeholder="Enter your address here"
                id="address"
                errorMessage={errors?.address?.message as string}
                validation={{
                  required: "Address is required",
                }}
              />
            )}
            {activeRedeemableReward.status === "redeemed" && (
              <>
                {(activeRedeemableReward.validityPeriodStart ||
                  activeRedeemableReward.validityPeriodEnd) && (
                  <div className="flex justify-between">
                    <p className="body-lg text-justify">Validity Period</p>
                    <p className="body-md text-surface-500">
                      {activeRedeemableReward.validityPeriodStart
                        ? formatToLocalDate(
                            activeRedeemableReward.validityPeriodStart,
                            user?.timezone?.value,
                            " MMM D, YYYY h:mm A",
                          )
                        : "N/A"}
                      -{" "}
                      {activeRedeemableReward.validityPeriodEnd
                        ? formatToLocalDate(
                            activeRedeemableReward.validityPeriodEnd,
                            user?.timezone?.value,
                            " MMM D, YYYY h:mm A",
                          )
                        : "N/A"}
                    </p>
                  </div>
                )}
                <div className="bg-warning-50 rounded-xl p-4 text-[#FF9100]">
                  <p className="body-md-bold">Redemption Instructions</p>
                  <p>{activeRedeemableReward.redemptionInstructions}</p>
                </div>
                <div className="flex flex-col p-4 rounded-xl gap-2 bg-[#FBFBFB]">
                  <p className="body-lg text-surface-900">Your address</p>
                  <p className="body-md text-surface-900">
                    {activeRedeemableReward.address}
                  </p>
                  <p className="body-md text-surface-500">
                    ⚠️ If you need to change your address please contact
                    support@boonhq.io
                  </p>
                </div>
              </>
            )}
            <div className="flex flex-col p-4 gap-1 rounded-xl bg-[#FBFBFB]">
              <p className="body-mg text-surface-900">Redemption Disclaimer</p>
              <p className="body-sm text-surface-500">
                {activeRedeemableReward.disclaimer}
              </p>
            </div>
          </div>
          {isPending ? (
            <div className="flex justify-center items-center">
              <Loader size="md" />
            </div>
          ) : activeRedeemableReward.status === "redeemed" ? (
            <Button
              category={ButtonType.PRIMARY}
              className="py-3"
              action={() => {
                navigate("/user/management?tab=My Items");
                setActiveRedeemableReward(undefined);
              }}
            >
              See All Your Items
            </Button>
          ) : (
            <Button
              category={ButtonType.PRIMARY}
              className="py-3"
              type="submit"
            >
              Redeem Your Reward
            </Button>
          )}
        </div>
      </form>
    </div>
  );
};

export default FirstPartyRewardRedemption;
