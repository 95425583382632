const PrivacyAndTerms = () => {
  const sectionData = {
    title: "2. Use of Data",
    subtitle:
      "DCZ Entertainment Ltd. uses the collected data for various purposes:",
    items: [
      "To provide and maintain our Services",
      "To notify you about changes to our Services",
      "To allow you to participate in interactive features of our Services when you choose to do so",
      "To provide customer support",
      "To gather analysis or valuable information so that we can improve our Services",
      "To monitor the usage of our Services",
      "To detect, prevent and address technical issues",
      "To share news and information with you, including occasional marketing messages",
    ],
  };

  const Section = ({
    title,
    subtitle,
    items,
  }: {
    title?: string;
    subtitle?: string;
    items?: string[];
  }) => {
    return (
      <div className="flex flex-col gap-4">
        <p className="title-lg-medium text-secondary-200">{title}</p>
        <p className="body-lg text-surface-700">{subtitle}</p>
        <ul className="list-disc pl-5">
          {items?.map((item, index) => (
            <li key={index} className="body-lg text-surface-700">
              {item}
            </li>
          ))}
        </ul>
      </div>
    );
  };

  return (
    <div className="px-15 py-5 flex flex-col bg-surface-50 gap-[35px] mmd:px-4">
      <div className="flex justify-between items-center mmd:flex-col border-b border-[rgba(235, 235, 235, 1)] pb-10 mmd:pb-2">
        <div className="flex flex-col gap-3">
          <p className="heading-md-medium text-surface-900">
            Terms and Conditions for Boon (under DCZ Entertainment)
          </p>
        </div>
      </div>
      <Section
        title="1. Introduction"
        subtitle="Welcome to Boon, a platform provided by DCZ Entertainment. By accessing and using Boon, you agree to comply with these Terms and Conditions. Please read these terms carefully before using our services."
      />
      <Section
        title="2. Account Registration"
        subtitle="To access certain features of Boon, you must create an account. You agree to provide accurate and complete information during registration. You are responsible for maintaining the confidentiality of your account details and for all activities under your account."
      />
      <Section
        title="3. Use of the Platform"
        subtitle="Boon allows users to participate in missions created by third-party creators and community owners. By participating, you agree to adhere to the rules of each mission and respect the intellectual property of the creators."
      />
      <Section
        title="4. User Conduct"
        subtitle="You agree to use Boon in a manner that is legal and respectful. You must not engage in:"
        items={[
          "Any activity that violates any laws or regulations.",
          "The transmission of harmful, offensive, or abusive content.",
          "Any attempt to disrupt the operation of Boon.",
        ]}
      />
      <Section
        title="5. Data Sharing with Creators and Community Owners"
        subtitle="By participating in missions on Boon, you consent to the sharing of your personal data with the creators and community owners responsible for those missions. This data may include, but is not limited to, your name, contact information, and mission performance details. Creators and community owners may use this data to monitor mission progress, evaluate participants, and reward users accordingly.
Creators and community owners are required to comply with their own privacy policies. You agree that DCZ Entertainment is not responsible for the privacy practices of these third parties."
      />
      <Section
        title="6. Platform Liability"
        subtitle="Boon serves solely as a platform connecting users with creators and community owners who post content, missions, and promotions. Boon is not responsible for the content, promises, or promotions made by these creators and community owners, including rewards, offers, or other obligations they may make to users.
Creators and community owners are entirely responsible for managing their own content, missions, and any related transactions or agreements with users. Boon does not guarantee the fulfillment of any promotions, rewards, or outcomes associated with participation in missions. You agree that Boon shall not be held liable for any disputes, claims, or issues arising between users and creators or community owners."
      />
      <Section
        title="7. Intellectual Property"
        subtitle="All intellectual property on Boon, including but not limited to text, graphics, logos, and software, is owned by DCZ Entertainment or our licensors. You may not use, reproduce, or distribute any content without permission."
      />
      <Section
        title="8. Termination"
        subtitle="We reserve the right to terminate or suspend your access to Boon at our discretion, without notice, if you violate these Terms and Conditions."
      />
      <Section
        title="9.  Limitation of Liability"
        subtitle="DCZ Entertainment is not responsible for any indirect, incidental, or consequential damages arising from your use of Boon. Your sole remedy for dissatisfaction with the platform is to discontinue its use."
      />
      <Section
        title="10. Governing Law"
        subtitle="These terms are governed by the laws of the United Kingdom. Any disputes arising from your use of Boon will be subject to the exclusive jurisdiction of the courts of the United Kingdom."
      />
      <Section
        title="11. Changes to the Terms"
        subtitle="We may modify these Terms and Conditions at any time. Changes will be posted on this page, and your continued use of Boon will constitute acceptance of the modified terms."
      />
      <div className="flex justify-between items-center mmd:flex-col border-b border-[rgba(235, 235, 235, 1)] pb-10 mmd:pb-2 mt-2">
        <div className="flex flex-col gap-3">
          <p className="heading-md-medium text-surface-900">
            Privacy Policy for Boon (under DCZ Entertainment){" "}
          </p>
        </div>
      </div>
      <Section
        title="1. Introduction"
        subtitle="This Privacy Policy describes how DCZ Entertainment collects, uses, and shares your personal data when you use Boon. By using Boon, you consent to the practices outlined in this policy."
      />
      <Section
        title="2. Data Collection"
        subtitle="We collect personal data that you provide when you register for an account or participate in missions. This data may include your name, contact information, and any information related to your mission performance."
      />
      <Section
        title="3. Use Of Data"
        subtitle="We use your personal data to:"
        items={[
          "Provide and manage the services on Boon.",
          "Facilitate your participation in missions.",
          "Communicate with you about updates and relevant information.",
        ]}
      />
      <Section
        title="4. Data Sharing with Creators and Community Owners"
        subtitle="By participating in missions on Boon, you agree to the sharing of your personal data with the creators and community owners who post those missions. This data may include your name, contact information, and mission performance. These third parties use your data to track mission progress, reward participants, and manage their content.
Please note that creators and community owners may have their own privacy policies, and we encourage you to review them prior to participating in their missions. DCZ Entertainment is not responsible for how these third parties handle your data.
"
      />
      <Section
        title="5.  Data Retention"
        subtitle="We retain your personal data for as long as necessary to provide our services or as required by law. If you delete your account, we will delete or anonymize your personal data unless legal obligations require otherwise."
      />
      <Section
        title="6. Your Rights"
        subtitle="You have the right to access, correct, or delete your personal data at any time. If you wish to exercise these rights, please contact us at info@boonhq.io"
      />
      <Section
        title="7. Security"
        subtitle="We take reasonable steps to protect your personal data from unauthorized access, use, or disclosure. However, we cannot guarantee absolute security, and you agree to use Boon at your own risk."
      />
      <Section
        title="8. Changes to the Privacy Policy"
        subtitle="We may update this Privacy Policy from time to time. The updated policy will be posted on this page, and your continued use of Boon will signify your acceptance of the changes."
      />
    </div>
  );
};

export default PrivacyAndTerms;