import * as React from "react";
const ItemsIcon = (props) => (
  <svg
    width={20}
    height={20}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.39166 11.6504H4.79131C3.8848 11.6504 3.31641 12.2926 3.31641 13.1999V15.6502C3.31641 16.5583 3.88236 17.2006 4.79131 17.2006H7.39166C8.30061 17.2006 8.8666 16.5583 8.8666 15.6502V13.1999C8.8666 12.2926 8.30061 11.6504 7.39166 11.6504Z"
      stroke={props.color || "#202327"}
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.3361 11.4438C16.9821 11.4438 18.3167 12.7785 18.3167 14.4253C18.3167 16.0713 16.9821 17.4059 15.3361 17.4059C13.6901 17.4059 12.3555 16.0713 12.3555 14.4253C12.3555 12.7785 13.6901 11.4438 15.3361 11.4438Z"
      stroke={props.color || "#202327"}
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.01509 8.20319H12.8647C13.3942 8.20319 13.7258 7.63155 13.4639 7.1718L11.0549 2.9425C10.7906 2.4795 10.1241 2.47789 9.85734 2.93925L7.41749 7.16856C7.15235 7.6283 7.48399 8.20319 8.01509 8.20319Z"
      stroke={props.color || "#202327"}
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default ItemsIcon;
