export interface InfoCardTypes {
  icon: JSX.Element;
  label: string;
  value: string;
}

const InfoCard = ({ icon, label, value }: InfoCardTypes) => {
  return (
    <div className={`flex items-center space-x-2 border rounded-lg p-3 w-full`}>
      <div className="text-2xl">{icon}</div>
      <div>
        <p className="body-sm text-surface-900">{label}</p>
        <p className="body-md-bold text-surface-900">{value}</p>
      </div>
    </div>
  );
};

export default InfoCard;
