import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useAPI } from "../useApi";
import { QUERY_KEYS } from "../../constants";
import { Task } from "../useCommunity";

export const useCreateTask = () => {
  const { post } = useAPI();
  const queryClient = useQueryClient();

  return useMutation<Task, Error, { missionId: string }>({
    mutationFn: async (data) => post(`/api/task`, data),
    onSuccess: (data, variables) => {
      queryClient.setQueryData(
        [QUERY_KEYS.MISSION, variables.missionId],
        (oldData: any) => {
          return {
            ...oldData,
            tasks: [...oldData.tasks, data],
          };
        },
      );
    },
  });
};

export const useUpdateTask = () => {
  const { put } = useAPI();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (data: any) =>
      put(`/api/task/${data._id}`, { ...data.payload }),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.MISSION] });
    },
  });
};

export const useTask = () => {
  const { put } = useAPI();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (data: any) =>
      put(`/api/task/order/${data._id}`, { ...data.payload }),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.MISSION] });
    },
  });
};
