import * as React from "react";
const WarningIcon = (props) => (
  <svg
    width={14}
    height={14}
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.80952 11.9214H11.1996C12.1224 11.9214 12.7017 10.9239 12.2432 10.1224L8.05136 2.79285C7.58994 1.9861 6.42677 1.98552 5.96477 2.79227L1.76594 10.1219C1.30744 10.9234 1.88611 11.9214 2.80952 11.9214Z"
      stroke="#ECC207"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.00391 7.82542V6.01709"
      stroke="#ECC207"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7 9.625H7.00583"
      stroke="#ECC207"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default WarningIcon;
