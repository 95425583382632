import * as React from "react";

const DeactivatedIcon = (props) => (
  <svg
    width={14}
    height={14}
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.16169 5.90876L5.74762 9.32278L5.12891 8.7041L8.54295 5.29004L9.16169 5.90876Z"
      fill="#C92929"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.74378 5.28906L9.16013 8.70607L8.54133 9.32476L5.125 5.90771L5.74378 5.28906Z"
      fill="#C92929"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.3125 7.31187C1.3125 4.09019 3.92417 1.47852 7.14583 1.47852C10.3675 1.47852 12.9792 4.09019 12.9792 7.31187C12.9792 10.5335 10.3675 13.1452 7.14583 13.1452C3.92417 13.1452 1.3125 10.5335 1.3125 7.31187ZM7.14583 2.35352C4.40742 2.35352 2.1875 4.57344 2.1875 7.31187C2.1875 10.0503 4.40742 12.2702 7.14583 12.2702C9.88423 12.2702 12.1042 10.0503 12.1042 7.31187C12.1042 4.57344 9.88423 2.35352 7.14583 2.35352Z"
      fill="#C92929"
    />
  </svg>
);
export default DeactivatedIcon;
