import { useCallback, useMemo } from "react";
import { useScreenSize } from "./useScreenSize";
import { toast } from "react-hot-toast";

const showSuccessToast = (
  message: string,
  position: "top-center" | "bottom-left" = "bottom-left",
  duration = 1500,
) => {
  toast.success(message, { position: position, duration: duration });
};

export const useSuccessHandler = () => {
  const { isMobile } = useScreenSize();

  const showSuccessMessage = useCallback(
    (message: string, duration = 1500) => {
      showSuccessToast(
        message,
        isMobile ? "top-center" : "bottom-left",
        duration,
      );
    },
    [isMobile],
  );

  return useMemo(() => ({ showSuccessMessage }), [showSuccessMessage]);
};
