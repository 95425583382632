const InfoIcon = (props) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect width={24} height={24} rx={6} fill="url(#paint0_linear_12215_3179)" />
    <path d="M12 9V14" stroke="white" strokeWidth={1.5} strokeLinecap="round" />
    <circle cx={12} cy={17} r={1} fill="white" />
    <defs>
      <linearGradient
        id="paint0_linear_12215_3179"
        x1={12}
        y1={0}
        x2={12}
        y2={24}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#DBA948" />
        <stop offset={0.0001} stopColor="#FFC46B" />
        <stop offset={1} stopColor="#FFA318" />
      </linearGradient>
    </defs>
  </svg>
);
export default InfoIcon;
