import { useQuery } from "@tanstack/react-query";
import { QUERY_KEYS } from "../../constants";
import { useAPI } from "../useApi";
import { Mission } from "../useCommunity";

const useMission = ({ _id }: { _id: string | undefined }) => {
  const { get } = useAPI();

  return useQuery<Mission, Error>({
    queryKey: [QUERY_KEYS.MISSION, _id],
    queryFn: () => get(`/api/mission/${_id}`),
    refetchOnWindowFocus: false,
  });
};

export default useMission;
