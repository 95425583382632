import * as React from "react";
const WrenchIcon = (props) => (
  <svg
    width={14}
    height={14}
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.6273 2.12817C10.6105 2.21134 10.5698 2.28881 10.5082 2.35034L8.52554 4.33276C8.4959 4.36125 8.47286 4.39542 8.45688 4.43302C8.45577 4.4353 8.45489 4.43872 8.45373 4.441C8.43156 4.49796 8.42555 4.56062 8.4378 4.62215L8.62494 5.56209C8.65031 5.68856 8.75 5.78881 8.87729 5.81388L9.81354 5.99848C9.91778 6.01895 10.0266 5.98593 10.1029 5.90958L12.0856 3.92716C12.1893 3.82348 12.3377 3.77905 12.4812 3.80867C12.6248 3.83716 12.7444 3.93628 12.8 4.07072C13.4494 5.66007 13.125 7.55703 11.831 8.85016C10.615 10.0658 8.86789 10.4224 7.33892 9.92682L5.22378 12.0426C4.48179 12.7843 3.22939 12.9198 2.40651 12.1724C2.01886 11.8204 1.80352 11.3567 1.7588 10.8805C1.70497 10.3062 1.89809 9.71495 2.33531 9.27745L4.51169 7.10133C4.01182 5.56893 4.36871 3.82121 5.58551 2.60441C6.87978 1.31014 8.77164 0.991125 10.363 1.63485C10.4988 1.68953 10.5982 1.80916 10.6276 1.95386C10.6392 2.01196 10.639 2.07121 10.6273 2.12817ZM6.91623 9.11219C7.04013 8.98806 7.22558 8.95043 7.38821 9.01536C8.66653 9.52578 10.1793 9.26491 11.2123 8.23153C12.0748 7.36908 12.4001 6.17388 12.1865 5.06421L10.7213 6.52937L10.412 6.22061L10.7207 6.53053C10.439 6.81077 10.0357 6.93385 9.64349 6.85749L8.70754 6.67182C8.23299 6.5772 7.86159 6.20696 7.76674 5.73299L7.57989 4.79419C7.50097 4.40112 7.62464 3.99552 7.90685 3.71411L9.37061 2.25008C8.25866 2.03817 7.06604 2.36173 6.20416 3.22306C5.17108 4.25643 4.9096 5.76831 5.42372 7.05116C5.48895 7.21408 5.45079 7.39865 5.32688 7.52284L2.95424 9.89608C2.50335 10.3472 2.51418 11.0878 2.99498 11.5242C3.25958 11.7646 3.63328 11.8443 3.98818 11.7646C4.21205 11.7144 4.42852 11.6005 4.60512 11.4239L6.91623 9.11219Z"
      fill="#ECC207"
    />
  </svg>
);
export default WrenchIcon;
