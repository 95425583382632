import { useForm } from "react-hook-form";
import Button from "../Button";
import Checkbox from "../Checkbox";
import BoonModal from "../Modal";
import ModalHeader from "../ModalHeader.tsx";
import { useUser } from "../../hooks";

export interface PrivacyModalTypes {
  onClose: () => void;
  onSubmit?: () => void;
}

const PrivacyModal = ({ onClose, onSubmit }: PrivacyModalTypes) => {
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm();
  const { updateUser } = useUser();

  const submit = () => {
    updateUser({
      isAgreedToV2Terms: true,
    });

    onSubmit?.();
    onClose();
  };

  return (
    <BoonModal onClose={onClose}>
      <div>
        <ModalHeader
          title="Privacy Policy & Terms of Service"
          onClose={onClose}
        />
        <p className="body-sm-regular text-surface-500 mb-8">
          Terms And Condition & Privacy Policy has been updated. Please review
          and accept the new terms and conditions.
        </p>
        <form className="flex flex-col gap-4" onSubmit={handleSubmit(submit)}>
          <div className="flex items-center gap-4">
            <Checkbox
              label="Accept Privacy policy and Terms & Conditions"
              labelClassName="hover:underline"
              onLabelClick={() =>
                window.open(
                  "/privacy-and-terms",
                  "_blank",
                  "noopener,noreferrer",
                )
              }
              registerInForm={register}
              name="privacy_check"
              validation={{
                required: "You must accept the terms and conditions",
              }}
              errorMessage={errors?.["privacy_check"]?.message as string}
            />
          </div>
          <Button type="submit">Continue</Button>
        </form>
      </div>
    </BoonModal>
  );
};

export default PrivacyModal;
