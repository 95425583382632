import { useEffect } from "react";
import { getUserTimezone } from "../utils";
import { useAuthContext } from "./useAuthContext";
import { useUser } from "./useUser";

export const useUserTimezoneSync = () => {
  const { user } = useAuthContext();
  const { updateUser } = useUser();

  useEffect(() => {
    if (user?.timezone?.manual) return;
    const timezone = getUserTimezone();
    if (timezone === user?.timezone?.value) return;
    updateUser({
      timezone: {
        value: timezone,
        manual: false,
      },
    });
  }, [updateUser, user]);
};
