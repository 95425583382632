export interface AnalyticsCardTypes {
  title: string;
  value: any;
}

const AnalyticsCard = ({ title, value }: AnalyticsCardTypes) => {
  return (
    <div className="flex flex-col w-full border border-solid border-[#F5F5F5] rounded-xl">
      <div className="flex-1 bg-[#F5F5F5] p-3 rounded-t-xl">
        <h3 className="text-body-sm first-letter:uppercase">{title}</h3>
      </div>
      <div className="flex-1 p-3">
        <h3 className="text-title-lg">{value}</h3>
      </div>
    </div>
  );
};

export default AnalyticsCard;
