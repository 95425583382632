const QuestionMarkIcon = (props) => (
  <svg
    width={16}
    height={16}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.14064 8.52984L9.04664 8.5825C8.61464 8.82384 8.46197 8.94784 8.4653 9.15984C8.46864 9.43584 8.24797 9.6625 7.97197 9.6665H7.9653C7.69197 9.6665 7.46864 9.44717 7.4653 9.17317C7.45397 8.32784 8.1453 7.94117 8.55797 7.7105L8.65197 7.65717C9.0313 7.4485 9.22064 7.15917 9.22064 6.79517C9.22064 6.19317 8.73064 5.70384 8.12864 5.70384C7.51597 5.70384 7.03597 6.18317 7.03597 6.79517C7.03597 7.07117 6.81197 7.29517 6.53597 7.29517C6.25997 7.29517 6.03597 7.07117 6.03597 6.79517C6.03597 5.64184 6.97464 4.70384 8.12864 4.70384C9.28197 4.70384 10.2206 5.64184 10.2206 6.79517C10.2206 7.5305 9.83664 8.1465 9.14064 8.52984ZM7.96597 11.3065C7.68997 11.3065 7.46597 11.0925 7.46597 10.8165V10.7965C7.46597 10.5205 7.68997 10.2965 7.96597 10.2965C8.24197 10.2965 8.46597 10.5205 8.46597 10.7965C8.46597 11.0725 8.24197 11.3065 7.96597 11.3065ZM8.0013 1.6665C4.50864 1.6665 1.66797 4.50784 1.66797 7.99984C1.66797 11.4918 4.50864 14.3332 8.0013 14.3332C11.4933 14.3332 14.3346 11.4918 14.3346 7.99984C14.3346 4.50784 11.4933 1.6665 8.0013 1.6665Z"
      fill="#55A8D9"
    />
  </svg>
);
export default QuestionMarkIcon;
