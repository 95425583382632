const MenuIcon = (props) => (
  <svg
    width={18}
    height={18}
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M6.77229 13.5253H6.7243M6.76198 13.7197C6.65397 13.7197 6.56641 13.6321 6.56641 13.5241C6.56641 13.4161 6.65397 13.3286 6.76198 13.3286C6.86999 13.3286 6.95755 13.4161 6.95755 13.5241C6.95755 13.6321 6.86999 13.7197 6.76198 13.7197Z"
      stroke="#A7AEB4"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.77229 9.00922H6.7243M6.76198 9.2037C6.65397 9.2037 6.56641 9.11603 6.56641 9.00803C6.56641 8.90003 6.65397 8.8125 6.76198 8.8125C6.86999 8.8125 6.95755 8.90003 6.95755 9.00803C6.95755 9.11603 6.86999 9.2037 6.76198 9.2037Z"
      stroke="#A7AEB4"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.77229 4.4931H6.7243M6.76198 4.68756C6.65397 4.68756 6.56641 4.59992 6.56641 4.49191C6.56641 4.3839 6.65397 4.29639 6.76198 4.29639C6.86999 4.29639 6.95755 4.3839 6.95755 4.49191C6.95755 4.59992 6.86999 4.68756 6.76198 4.68756Z"
      stroke="#A7AEB4"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11.6238 13.5253H11.5758M11.6136 13.7197C11.5056 13.7197 11.418 13.6321 11.418 13.5241C11.418 13.4161 11.5056 13.3286 11.6136 13.3286C11.7216 13.3286 11.8091 13.4161 11.8091 13.5241C11.8091 13.6321 11.7216 13.7197 11.6136 13.7197Z"
      stroke="#A7AEB4"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11.6238 9.00922H11.5758M11.6136 9.2037C11.5056 9.2037 11.418 9.11603 11.418 9.00803C11.418 8.90003 11.5056 8.8125 11.6136 8.8125C11.7216 8.8125 11.8091 8.90003 11.8091 9.00803C11.8091 9.11603 11.7216 9.2037 11.6136 9.2037Z"
      stroke="#A7AEB4"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11.6238 4.4931H11.5758M11.6136 4.68756C11.5056 4.68756 11.418 4.59992 11.418 4.49191C11.418 4.3839 11.5056 4.29639 11.6136 4.29639C11.7216 4.29639 11.8091 4.3839 11.8091 4.49191C11.8091 4.59992 11.7216 4.68756 11.6136 4.68756Z"
      stroke="#A7AEB4"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default MenuIcon;
