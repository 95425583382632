import { useState, useRef, useEffect, SetStateAction } from "react";
import { DayPicker, getDefaultClassNames } from "react-day-picker";
import "react-day-picker/style.css";

export interface DateInputProps {
  name: string;
  validation?: any;
  registerInForm?: (name: string, validation: any) => object;
  onChange?: (value: Date) => void;
  placeholder?: string;
  value?: Date;
  label?: string;
  labelClassName?: string;
  startFrom?: string;
  disabled?: boolean;
}

const DateInput = ({
  placeholder,
  value,
  label,
  labelClassName = "",
  startFrom = "right",
  onChange,
  disabled,
}: DateInputProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedValue, setSelectedValue] = useState<Date | undefined>(
    value && new Date(value),
  );
  const [minute, setMinute] = useState(
    value ? new Date(value).getMinutes().toString().padStart(2, "0") : "00",
  );
  const [hour, setHour] = useState(
    value
      ? (new Date(value).getHours() % 12 || 12).toString().padStart(2, "0")
      : "00",
  );
  const [selectedDayTime, setSelectedDayTime] = useState(
    value ? (new Date(value).getHours() >= 12 ? "PM" : "AM") : "AM",
  );
  const dropdownRef = useRef<HTMLDivElement>(null);
  const defaultClassNames = getDefaultClassNames();

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        updateDate();
        setTimeout(() => setIsOpen(false), 0);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [hour, minute, selectedDayTime]);

  const updateDate = () => {
    if (!selectedValue) return;

    const dateCopy = new Date(selectedValue);
    const parsedHour = parseInt(hour);
    const formattedHour =
      selectedDayTime === "PM" && parsedHour < 12
        ? parsedHour + 12
        : selectedDayTime === "AM" && parsedHour === 12
          ? 0
          : parsedHour;

    dateCopy.setHours(formattedHour);
    dateCopy.setMinutes(parseInt(minute));

    setSelectedValue(dateCopy);
    onChange?.(dateCopy);
  };

  const handleSelect = (selectedValue: any) => {
    if (selectedValue) {
      if (/^\d*$/.test(hour)) {
        selectedValue.setHours(parseInt(hour));
      }
      if (/^\d*$/.test(minute)) {
        selectedValue.setMinutes(parseInt(minute));
      }
      setSelectedValue(selectedValue);
      onChange?.(selectedValue);
    }
  };

  const handleHourChange = (value: string) => {
    if (!selectedValue) return;

    setHour(value);
    if (/^\d*$/.test(value)) {
      const dateCopy = new Date(selectedValue);
      const parsedValue = parseInt(value);
      dateCopy.setHours(
        selectedDayTime === "AM" ? parsedValue : parsedValue + 12,
      );
      setSelectedValue(dateCopy);
      onChange?.(dateCopy);
    }
  };

  const handleMinuteChange = (value: string) => {
    if (!selectedValue) return;

    setMinute(value);
    if (/^\d*$/.test(value)) {
      const dateCopy = new Date(selectedValue || new Date());
      dateCopy.setMinutes(parseInt(value));
      setSelectedValue(dateCopy);
      onChange?.(dateCopy);
    }
  };

  // useEffect(() => {
  //   handleHourChange(hour);
  // }, [selectedDayTime, setIsOpen, isOpen]);

  console.log(hour, minute);

  function handleKeyUp(event: any) {
    if (event.keyCode === 13) {
      event.preventDefault();
      event.target.blur();
    }
  }

  return (
    <div className="flex flex-col gap-2 items-start w-full">
      {label && (
        <p className={`body-lg font-medium text-surface-700 ${labelClassName}`}>
          {label}
        </p>
      )}
      <div ref={dropdownRef} className="relative w-full">
        <div
          className="flex items-center justify-between p-4 bg-transparent rounded-lg cursor-pointer text-gray-900 body-md w-full"
          onClick={() => !disabled && setIsOpen(!isOpen)}
        >
          <div className="flex items-center w-full overflow-hidden whitespace-nowrap">
            {selectedValue
              ? `${selectedValue.toLocaleDateString()} - ${selectedValue.toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" })}`
              : placeholder}
          </div>
        </div>
        {isOpen && (
          <div
            className={`absolute top-full ${startFrom}-0 mt-2 w-fit border border-surface-250 bg-white rounded-lg shadow-md h-fit overflow-y-auto z-50`}
          >
            <div className="flex flex-col ">
              <DayPicker
                mode="single"
                selected={selectedValue}
                onSelect={handleSelect}
                classNames={{
                  ...defaultClassNames,
                  today: `text-primary-500`, // Add a border to today's date
                  selected: `bg-primary-50 rounded-full text-primary-500`, // Highlight the selected day
                  root: `${defaultClassNames.root} p-5 pb-0`, // Add a shadow to the root element
                  chevron: `fill-primary-500`, // Change the color of the chevron
                  caption_label: `${defaultClassNames.caption_label} ml-3`,
                }}
                disabled={disabled}
              />
              <div className="flex justify-between items-center px-8 pb-3">
                <p className="body-lg-bold text-surface-900">Time</p>
                <div className="flex gap-2 items-center">
                  <div className="flex items-center rounded-md bg-surface-100 p-2">
                    <p
                      contentEditable={!disabled}
                      suppressContentEditableWarning
                      className="outline-none unset"
                      onKeyDown={handleKeyUp}
                      onMouseDown={(e) =>
                        handleHourChange(e.currentTarget.textContent || "")
                      }
                      onBlur={(e) =>
                        handleHourChange(e.currentTarget.textContent || "")
                      }
                    >
                      {hour}
                    </p>
                    <p>:</p>
                    <p
                      contentEditable={!disabled}
                      suppressContentEditableWarning
                      className="outline-none"
                      onKeyDown={handleKeyUp}
                      onMouseDown={(e) =>
                        handleMinuteChange(e.currentTarget.textContent || "")
                      }
                      onBlur={(e) =>
                        handleMinuteChange(e.currentTarget.textContent || "")
                      }
                    >
                      {minute}
                    </p>
                  </div>
                  <div className="flex items-center rounded-md bg-surface-100">
                    <p
                      className={`py-2 px-4 rounded-lg cursor-pointer ${selectedDayTime === "AM" && "bg-surface-50 shadow-md"}`}
                      onClick={() => !disabled && setSelectedDayTime("AM")}
                    >
                      AM
                    </p>
                    <p
                      className={`py-2 px-4 rounded-lg cursor-pointer ${selectedDayTime === "PM" && "bg-surface-50 shadow-md"}`}
                      onClick={() => !disabled && setSelectedDayTime("PM")}
                    >
                      PM
                    </p>
                  </div>
                </div>
              </div>
              {(!/^\d*$/.test(hour) ||
                !/^\d*$/.test(minute) ||
                parseInt(hour) >= 12 ||
                parseInt(minute) >= 60) && (
                <p className="text-danger-200 px-8 pb-3">
                  Please input valid time
                </p>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default DateInput;
