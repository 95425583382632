import { useMemo } from "react";
import { Reward } from "../../hooks/useCommunity";
import FirstPartyRewardRedemption from "./FirstPartyRewardRedemption";
import InVenueRewardRedemption from "./InVenueRewardRedemption";
import ThirdPartyRewardRedemption from "./ThirdPartyRewardRedemption";

export enum OfflineGiftFulfilmentOption {
  FIRST_PARTY = "1st party",
  THIRD_PARTY = "3rd party",
  IN_VENUE = "In-venue",
}

const OfflineGiftRewardRedemption = ({ reward }: { reward: Reward }) => {
  const componentMapper = useMemo(
    () => ({
      [OfflineGiftFulfilmentOption.FIRST_PARTY]: <FirstPartyRewardRedemption />,
      [OfflineGiftFulfilmentOption.THIRD_PARTY]: <ThirdPartyRewardRedemption />,
      [OfflineGiftFulfilmentOption.IN_VENUE]: <InVenueRewardRedemption />,
    }),
    [],
  );
  if (reward.fulfilmentOption) return componentMapper[reward.fulfilmentOption];
  return <InVenueRewardRedemption />;
};

export default OfflineGiftRewardRedemption;
