import classNames from "classnames";
import CloseIcon from "../../assets/icons/close";

export interface TagCardTypes {
  onRemove?: () => void;
  value: string;
  variant?: "regular" | "medium";
}

const TagCard = ({ value, variant = "regular", onRemove }: TagCardTypes) => {
  const textClass = classNames("first-letter:uppercase", {
    "body-sm-medium": variant === "medium",
    "body-sm-regular": variant === "regular",
  });

  return (
    <div className="w-fit flex items-center  gap-2 p-1.5 rounded-[4px] bg-[#EBEBEB]">
      {onRemove ? (
        <CloseIcon
          width={20}
          height={20}
          onClick={onRemove}
          className="cursor-pointer"
        />
      ) : (
        ""
      )}
      <p className={textClass}>{value}</p>
    </div>
  );
};

export default TagCard;
