import { useState, useEffect } from "react";
import TagCard from "./TagCard";
import { MultiDropDown } from "../Dropdown";
import { missionTags } from "../../constants";

export interface TagInputTypes {
  label?: string;
  validation?: any;
  errorMessage?: string;
  name: string;
  registerInForm?: (name: string, validation: any) => object;
  setValueInForm?: (name: string, value: any) => void;
  onChange?: (data: any) => void;
  required?: boolean;
  defaultValue?: any;
}

const TagInput = ({
  label,
  onChange,
  setValueInForm,
  name,
  required = true,
  defaultValue = [],
}: TagInputTypes) => {
  const [tags, setTags] = useState<string[]>([]);

  useEffect(() => {
    if (defaultValue.length > 0) setTags(defaultValue);
  }, [defaultValue]);

  useEffect(() => {
    onChange?.({ target: { name, value: tags } });
  }, [tags]);

  const removeTags = (index: number) => {
    const updatedTags = tags.filter((_, i) => i !== index);
    setValueInForm?.(name, updatedTags);
    setTags(updatedTags);
  };

  return (
    <div className="w-full flex flex-col gap-2">
      {label && (
        <p className="body-lg font-medium text-surface-700">
          {label} {required ? "*" : ""}
        </p>
      )}
      <div className="w-full">
        <MultiDropDown
          name={name}
          multiSelector={true}
          setValueForm={setValueInForm}
          setStateValue={setTags}
          value={tags}
          data={missionTags}
        />
        <div className="flex flex-wrap w-full mt-2 gap-2">
          {tags.map((tag, index) => (
            <TagCard
              key={index}
              value={tag}
              onRemove={() => removeTags(index)}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default TagInput;
