import classNames from "classnames";
import { ToggleSlider } from "react-toggle-slider";

export interface ToggleSwitchProps {
  type?: string;
  label?: string;
  className?: string;
  labelClassName?: string;
  containerClassName?: string;
  errorMessage?: string;
  registerInForm?: (name: string, validation: any) => object;
  setValue: (name: string, value: any) => void;
  name: string;
  validation?: any;
  checked?: boolean;
  [key: string]: any;
}

export const ToggleSwitch = ({
  label,
  className,
  errorMessage,
  type,
  registerInForm = () => ({}),
  name,
  validation,
  checked = false,
  labelClassName = "",
  containerClassName = "",
  setValue,
  ...inputProps
}: ToggleSwitchProps) => {
  const errorClasses = classNames(
    "text-red-500 text-sm font-medium mb-3 transition-opacity duration-300 ease-in-out",
    {
      "opacity-100": errorMessage,
      "opacity-0": !errorMessage,
    },
    "pl-2",
  );

  return (
    <div className={classNames(`flex flex-col`, containerClassName)}>
      {label && (
        <p className={`body-lg font-medium text-surface-700 ${labelClassName}`}>
          {label}
        </p>
      )}
      <ToggleSlider
        barBackgroundColorActive="#9382FF"
        barBackgroundColor="#FFFFFF"
        handleBackgroundColor="#B8BDCC"
        handleBackgroundColorActive="#FFFFFF"
        barStyles={{ border: "1px solid #D0D3DA" }}
        onToggle={(e) => setValue(name, e)}
        active={checked}
      />
      {errorMessage ? (
        <p className={errorClasses}>{errorMessage || "\u00A0"}</p>
      ) : (
        ""
      )}
    </div>
  );
};
