import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useAPI } from "./useApi";
import { QUERY_KEYS } from "../constants";
import { SocialNetworkLink, User } from "../types";
import { useAuthContext } from "./useAuthContext";
import { useErrorHandler } from "./useErrorHandler";

export interface UpdateUser {
  firstName?: string;
  lastName?: string;
  email?: string;
  profilePicture?: string;
  socialNetworkLinks?: SocialNetworkLink[];
  timezone?: {
    value: string;
    manual: boolean;
  };
  isAgreedToV2Terms?: boolean;
}

export interface UpdatePassword {
  currentPassword: string;
  newPassword: string;
}

export const useUser = () => {
  const { get, post } = useAPI();
  const queryClient = useQueryClient();
  const { updateUserContext } = useAuthContext();
  const { handleApiError } = useErrorHandler();

  const { data: user, isLoading } = useQuery<User, Error>({
    queryKey: [QUERY_KEYS.CURRENT_USER],
    queryFn: () => get(`/api/user/current`),
  });

  const {
    mutate: updateUser,
    isPending: isUserPending,
    isError: isUserError,
    error: userError,
    isSuccess: isUserSuccess,
  } = useMutation({
    mutationFn: async (userData: UpdateUser) =>
      post("/api/user/update", userData),
    onSuccess: (data) => {
      queryClient.setQueryData([QUERY_KEYS.CURRENT_USER], (oldData: any) => {
        return {
          ...oldData,
          ...data,
        };
      });

      queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.CURRENT_USER] });

      updateUserContext(data);
    },
    onError: (error) => {
      handleApiError(error);
    },
  });

  const {
    mutate: updatePassword,
    isPending: isPasswordPending,
    isError: isPasswordError,
    error: passwordError,
    isSuccess: isPasswordSuccess,
  } = useMutation({
    mutationFn: async (data: UpdatePassword) =>
      post("/api/user/change-password", data),
    onError: (error) => {
      handleApiError(error);
    },
  });

  return {
    user,
    isLoading,
    updateUser,
    isUserPending,
    isUserError,
    userError,
    isUserSuccess,
    updatePassword,
    isPasswordPending,
    isPasswordError,
    passwordError,
    isPasswordSuccess,
  };
};
