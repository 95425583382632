import * as React from "react";
const EditIcon = (props) => (
  <svg
    width={18}
    height={18}
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M10.001 14.6309H14.7837"
      stroke="black"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.0435 3.6442C11.0355 2.8882 9.60603 3.0922 8.85003 4.09945C8.85003 4.09945 5.09028 9.10796 3.78603 10.8457C2.48178 12.5842 3.71553 14.7382 3.71553 14.7382C3.71553 14.7382 6.14853 15.2977 7.43403 13.584C8.72028 11.871 12.498 6.8377 12.498 6.8377C13.254 5.83045 13.0508 4.4002 12.0435 3.6442Z"
      stroke="black"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.87793 5.40857L11.5259 8.1468"
      stroke="black"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default EditIcon;
