import { useRedeemReward, useUser } from "../../hooks";
import { Reward } from "../../hooks/useCommunity";
import Button, { ButtonType } from "../Button";
import { useNavigate } from "react-router-dom";
import { CopyIcon } from "../../assets/icons";
import WebPageIcon from "../../assets/icons/webPage";
import { absoluteLink } from "../../hooks/useTask/useUserTask";
import OpenLinkIcon from "../../assets/icons/openLink";
import CouponIcon from "../../assets/icons/coupon";
import { Loader } from "../Loader";
import { useContext } from "react";
import { RewardContext } from "../../context/RewardContext";
import { formatToLocalDate } from "../../utils";

export interface RewardRedemptionRowProps {
  label: string;
  LabelIcon: any;
  value: string;
  ValueIcon: any;
  action: () => void;
}

export const RewardRedemptionRow = ({
  label,
  LabelIcon,
  value,
  ValueIcon,
  action,
}: RewardRedemptionRowProps) => {
  return (
    <div className="flex border border-solid border-surface-250 bg-[#FBFBFB] rounded-lg p-4">
      <div className="flex gap-2 w-full items-center">
        <div className="bg-[#FF9100] rounded-full p-3">
          <LabelIcon />
        </div>
        <div className="flex justify-between w-full ">
          <p className="body-md text-surface-900">{label}</p>
          <p className="pr-3">{value}</p>
        </div>
      </div>
      <Button className="py-3 w-fit" action={action}>
        <ValueIcon color="#FFFFFF" />
      </Button>
    </div>
  );
};

const ThirdPartyRewardRedemption = () => {
  const { user } = useUser();
  const navigate = useNavigate();
  const { mutate, isPending } = useRedeemReward();
  const { activeRedeemableReward, setActiveRedeemableReward } =
    useContext(RewardContext);

  return (
    <div className="flex flex-col p-1.5 pb-6 gap-6">
      <img
        src={activeRedeemableReward.rewardImage}
        className="rounded-2xl w-full aspect-video rounded-t-[10px] object-contain"
      />
      <div className="flex flex-col px-2.5 gap-16">
        {activeRedeemableReward.status === "redeemed" ? (
          <>
            <div className="flex flex-col gap-4">
              <div className="flex flex-col gap-4">
                <p className="title-xl-bold text-surface-900">
                  Congrats {user?.name}!
                </p>
                <div className="flex flex-col gap-2.5">
                  <p className="body-lg text-surface-900">
                    You've completed {activeRedeemableReward.mission?.name}
                  </p>
                  <p className="body-lg text-surface-500">
                    {activeRedeemableReward.successMessage}
                  </p>
                </div>
              </div>
              {(activeRedeemableReward.validityPeriodStart ||
                activeRedeemableReward.validityPeriodEnd) && (
                <div className="flex justify-between">
                  <p className="body-lg text-justify">Validity Period</p>
                  <p className="body-md text-surface-500">
                    {activeRedeemableReward.validityPeriodStart
                      ? formatToLocalDate(
                          activeRedeemableReward.validityPeriodStart,
                          user?.timezone?.value,
                          " MMM D, YYYY h:mm A",
                        )
                      : "N/A"}
                    -{" "}
                    {activeRedeemableReward.validityPeriodEnd
                      ? formatToLocalDate(
                          activeRedeemableReward.validityPeriodEnd,
                          user?.timezone?.value,
                          " MMM D, YYYY h:mm A",
                        )
                      : "N/A"}
                  </p>
                </div>
              )}
              <div className="bg-warning-50 rounded-xl p-4 text-[#FF9100]">
                <p className="body-md-bold">Redemption Instructions</p>
                <p>{activeRedeemableReward.redemptionInstructions}</p>
              </div>
              <RewardRedemptionRow
                label="Coupon Code"
                LabelIcon={CouponIcon}
                value={activeRedeemableReward.userRedeemedCouponCode || ""}
                ValueIcon={CopyIcon}
                action={() =>
                  navigator.clipboard.writeText(
                    activeRedeemableReward.userRedeemedCouponCode || "",
                  )
                }
              />
              <RewardRedemptionRow
                label="URL"
                LabelIcon={WebPageIcon}
                value={activeRedeemableReward.thirdPartyRedemptionUrl || ""}
                ValueIcon={OpenLinkIcon}
                action={() =>
                  window.open(
                    absoluteLink(
                      activeRedeemableReward.thirdPartyRedemptionUrl || "",
                    ),
                    "_blank",
                    "noopener,noreferrer",
                  )
                }
              />
              <div className="flex flex-col p-4 gap-1 rounded-xl bg-[#FBFBFB]">
                <p className="body-mg text-surface-900">
                  Redemption Disclaimer
                </p>
                <p className="body-sm text-surface-500">
                  {activeRedeemableReward.disclaimer}
                </p>
              </div>
            </div>
            <Button
              category={ButtonType.PRIMARY}
              className="py-3"
              action={() => {
                navigate("/user/management?tab=My Items");
                setActiveRedeemableReward(undefined);
              }}
            >
              See All Your Items
            </Button>
          </>
        ) : (
          <>
            <div className="flex flex-col gap-4">
              <div className="flex flex-col gap-4">
                <p className="title-xl-bold text-surface-900">
                  Congrats {user?.name}!
                </p>
                <div className="flex flex-col gap-2.5">
                  <p className="body-lg text-surface-900">
                    You've completed {activeRedeemableReward.mission?.name}
                  </p>
                  <p className="body-lg text-surface-500">
                    {activeRedeemableReward.successMessage}
                  </p>
                </div>
              </div>
              <div className="flex flex-col p-4 gap-1 rounded-xl bg-[#FBFBFB]">
                <p className="body-mg text-surface-900">
                  Redemption Disclaimer
                </p>
                <p className="body-sm text-surface-500">
                  {activeRedeemableReward.disclaimer}
                </p>
              </div>
            </div>
            {isPending ? (
              <div className="flex justify-center items-center">
                <Loader size="md" />
              </div>
            ) : (
              <Button
                category={ButtonType.PRIMARY}
                className="py-3"
                action={() =>
                  mutate({ rewardId: activeRedeemableReward._id, payload: {} })
                }
              >
                Redeem Your Reward
              </Button>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default ThirdPartyRewardRedemption;
