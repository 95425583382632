import type { Section } from "../../hooks/useCommunity/index.js";
import MissionGroup from "./MissionGroup";

const CommunityCardBody = ({
  communitySections,
}: {
  communitySections: Section[];
}) => {
  return (
    <div className="flex flex-col gap-y-[33px] flex-wrap">
      {communitySections?.map(
        (section: Section, index: number) =>
          section.missions.length > 0 && (
            <MissionGroup
              missionType={section.name}
              missions={section.missions as any}
              key={`${index}`}
            />
          ),
      )}
    </div>
  );
};

export default CommunityCardBody;
