const CheckBadgeIcon = (props) => (
  <svg
    width={34}
    height={34}
    viewBox="0 0 34 34"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M22.0761 14.9569L16.306 20.7298C16.1063 20.9296 15.8357 21.0415 15.5538 21.0415C15.2719 21.0415 15.0013 20.9296 14.803 20.7298L12.0023 17.9248C11.5886 17.5083 11.5886 16.8354 12.0037 16.4203C12.4202 16.0067 13.0917 16.0081 13.5068 16.4217L15.5552 18.4745L20.573 13.4538C20.9881 13.0387 21.661 13.0387 22.0761 13.4538C22.4912 13.8689 22.4912 14.5418 22.0761 14.9569ZM29.3295 14.2882L28.3392 13.2979C27.8873 12.8446 27.6394 12.2411 27.6394 11.6022V10.1855C27.6394 8.07611 25.9224 6.36053 23.8144 6.36053H22.3949C21.7531 6.36053 21.151 6.11261 20.7005 5.66211L19.6933 4.65628C18.1959 3.17161 15.7705 3.17869 14.2845 4.67186L13.2971 5.66211C12.8424 6.11403 12.2403 6.36194 11.5999 6.36194H10.1819C8.09795 6.36336 6.39795 8.04069 6.3597 10.1175C6.35827 10.1402 6.35687 10.1629 6.35687 10.1869V11.5994C6.35687 12.2397 6.10895 12.8418 5.65703 13.2937L4.65403 14.2982C4.65262 14.3024 4.64837 14.3038 4.64553 14.3067C3.16512 15.8055 3.17787 18.2308 4.66962 19.7084L5.65987 20.7015C6.11037 21.1534 6.3597 21.7541 6.3597 22.3944V23.8182C6.3597 25.9262 8.07387 27.6417 10.1819 27.6417H11.5971C12.2389 27.6432 12.8409 27.8911 13.2914 28.3402L14.3015 29.3474C15.0211 30.0628 15.976 30.4566 16.9917 30.4566H17.0087C18.0301 30.4524 18.9878 30.0501 19.7046 29.329L20.6977 28.3373C21.144 27.8925 21.7616 27.6375 22.392 27.6375H23.8172C25.921 27.6375 27.6365 25.9247 27.6408 23.8182V22.3972C27.6408 21.7583 27.8887 21.1562 28.3378 20.7043L29.345 19.6971C30.8325 18.2011 30.824 15.7743 29.3295 14.2882Z"
      fill="#9382FF"
    />
  </svg>
);
export default CheckBadgeIcon;
