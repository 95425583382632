import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useCommunity, Section } from "../../hooks/useCommunity";
import Button, { ButtonType } from "../Button";
import PlusIcon from "../../assets/icons/plus";
import EditButton from "../Button/EditButton";
import SectionModal from "./EditCommunity/SectionModal";
import { ReactSortable } from "react-sortablejs";
import useMissionOrder from "../../hooks/useMissions/useMissionOrder";
import EmptyCommunityMissionManager from "./EditCommunity/EmptyCommunityMissionManager";
import CommunityManagerSingleMission from "./EditCommunity/CommunityManagerSingleMission";
import CreateMissionModal from "../CreateMissionModal";
import { useCommunityManagementContext } from "../../hooks";
import { Loader } from "../Loader";

const CommunityMissionManager = () => {
  const navigate = useNavigate();
  const { _id } = useParams();
  const { data: community, isLoading } = useCommunity({
    _id,
    includeInactive: true,
    includeUnlaunched: true,
    includeOversubscribed: true,
  });

  const { setActiveCommunity, activeCommunity, setActiveSection } =
    useCommunityManagementContext();
  const [modalType, setModalType] = useState<string | null>(null);
  const { mutate: order } = useMissionOrder();

  const handleAddMission = (section: Section | undefined) => {
    setActiveSection(section);
    setModalType("create_mission");
  };

  const handleEditSection = (section: Section | undefined) => {
    setActiveSection(section);
    setModalType("updateSection");
  };

  //TODO deps incorrect
  useEffect(() => {
    if (community) {
      setActiveCommunity(community);
      setActiveSection();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [community]);

  const updateSectionMissions = (
    sectionId: string | undefined,
    updatedMissions: any[],
  ) => {
    const updatedSections = activeCommunity?.sections.map(
      (section: Section) => {
        if (section._id === sectionId) {
          return { ...section, missions: updatedMissions };
        }
        return section;
      },
    );
    setActiveCommunity({ ...activeCommunity, sections: updatedSections });
  };

  const handleDragEnd = (
    sectionId: string | undefined,
    oldIndex: number | undefined,
    newIndex: number | undefined,
  ) => {
    if (
      sectionId !== undefined &&
      oldIndex !== undefined &&
      newIndex !== undefined
    ) {
      const section = activeCommunity?.sections.find(
        (s: Section) => s._id === sectionId,
      );
      if (!section || !section.missions) return;
      const reorderedMissions = [...section.missions];
      const [movedMission] = reorderedMissions.splice(oldIndex, 1);
      reorderedMissions.splice(newIndex, 0, movedMission);
      updateSectionMissions(sectionId, reorderedMissions);
      order(reorderedMissions);
    }
  };

  if (isLoading) return <Loader size="lg" fullScreen />;

  return (
    <div className="w-full h-full mmd:bg-surface-100">
      <div className="flex flex-col rounded-xl p-6 ml-6 mr-10 gap-6 mmd:p-4 mmd:bg-white mmd:border mmd:border-solid mmd:border-surface-250 mmd:m-4">
        {!modalType ? null : modalType === "create_mission" ? (
          <CreateMissionModal
            onClose={() => {
              setModalType(null);
            }}
          />
        ) : (
          <SectionModal
            modalType={modalType}
            onClose={() => setModalType(null)}
            communityId={_id}
            sections={community.sections}
          />
        )}
        <div className="flex justify-between">
          <div className="flex flex-col gap-1">
            <p className="title-lg-bold text-surface-700">
              Welcome To {activeCommunity?.name}, {activeCommunity?.author.name}
            </p>
            {activeCommunity?.sections === undefined ||
            activeCommunity.sections.length === 0 ? (
              <p className="text-body-lg text-slate-500">
                What are you waiting for - get started & create your first
                mission here
              </p>
            ) : (
              ""
            )}
          </div>
          <Button
            category={ButtonType.SECONDARY}
            action={() => setModalType("createSection")}
          >
            Add Section
          </Button>
        </div>
        {activeCommunity?.sections !== undefined ? (
          <>
            {activeCommunity?.sections?.map((section: Section) => (
              <div
                key={section._id}
                className="flex flex-col rounded-xl border border-solid border-surface-250 pt-6 gap-6  bg-surface-50"
              >
                {section.name !== "Default" && (
                  <div className="flex justify-between px-6 items-center">
                    <p className="body-xl-medium text-surface-700">
                      {section.name}
                    </p>
                    <EditButton
                      text="Edit"
                      onClick={() => handleEditSection(section)}
                    />
                  </div>
                )}
                <div className="flex flex-col px-6 gap-2">
                  <ReactSortable
                    list={
                      section.missions.map((mission) => ({
                        ...mission,
                        id: mission._id,
                      })) || []
                    }
                    setList={(e) => updateSectionMissions(section._id, e)}
                    onEnd={({ oldIndex, newIndex }) =>
                      handleDragEnd(section._id, oldIndex, newIndex)
                    }
                    className="flex flex-col gap-2"
                    animation={300} // Animation duration in milliseconds
                    easing="cubic-bezier(0.25, 0.8, 0.25, 1)"
                  >
                    {section.missions.map((mission) => (
                      <CommunityManagerSingleMission
                        mission={mission}
                        setMissionId={() =>
                          navigate(
                            `/community/management/${_id}/mission-manager/${mission._id}`,
                          )
                        }
                        key={mission._id}
                      />
                    ))}
                  </ReactSortable>
                </div>
                <div className="flex border-t border-solid border-surface-250 px-6 py-4">
                  <Button
                    action={() => handleAddMission(section)}
                    category={ButtonType.SECONDARY}
                    className="border-none flex items-center gap-2.5 px-4 py-2 cursor-pointer"
                  >
                    <PlusIcon color="#9382FF" />
                    <p className="body-lg-medium text-primary-500">
                      Add New Mission
                    </p>
                  </Button>
                </div>
              </div>
            ))}
          </>
        ) : (
          <EmptyCommunityMissionManager handleAddMission={handleAddMission} />
        )}
      </div>
    </div>
  );
};

export default CommunityMissionManager;
