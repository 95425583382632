import { useContext } from "react";
import { CommunityManagementContext } from "../context";

export const useCommunityManagementContext = () => {
  const context = useContext(CommunityManagementContext);
  if (!context) {
    throw new Error(
      "useCommunityManagementContext must be used within a CommunityManagementContext",
    );
  }
  return context;
};
