export const CopyIcon = (props) => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.66083 4.73291H8.52083C8.99334 4.73291 9.45084 4.92791 9.78084 5.27291L11.9858 7.56787C12.3008 7.89037 12.4733 8.32537 12.4733 8.78287V12.1429V13.2679V13.5229C12.4808 15.0229 11.3108 16.2529 9.81084 16.3129H5.16083C4.44083 16.2979 3.75833 15.9979 3.24833 15.4654C2.74583 14.9404 2.47583 14.2429 2.49083 13.5079V7.41791C2.52833 5.93291 3.75083 4.73291 5.22833 4.73291H5.53583H6.66083ZM9.78834 15.1879C10.6583 15.1504 11.3558 14.4229 11.3483 13.5229V9.51037H10.0583C8.81334 9.50287 7.80083 8.49037 7.80083 7.25291V5.85791H5.22833C4.35833 5.85791 3.63083 6.56291 3.61583 7.43291V13.5229C3.60083 13.9654 3.76583 14.3779 4.06583 14.6854C4.35833 15.0004 4.76333 15.1804 5.19833 15.1879H9.78834Z"
      fill={props.color || "#202327"}
    />
    <path
      opacity="0.4"
      d="M12.8327 12.1425C13.6952 12.105 14.4002 11.3625 14.3852 10.485V6.465H13.1027C11.8577 6.4575 10.8452 5.445 10.8452 4.2V2.8125H8.26516C7.38766 2.8125 6.68266 3.5025 6.66016 4.3875V4.7325H5.53516V4.3725C5.57266 2.88 6.79516 1.6875 8.26516 1.6875H11.5652C12.0302 1.6875 12.4952 1.8825 12.8252 2.2275L15.0302 4.5225C15.3377 4.83 15.5102 5.265 15.5102 5.7375V10.4775C15.5327 11.955 14.3402 13.2075 12.8477 13.2675H12.4727V12.1425H12.8327Z"
      fill={props.color || "#202327"}
    />
  </svg>
);
