import { RewardCard } from "./RewardCards";
import { ControlArrows } from "../ControlArrows";
import { useHorizontalScrollListener } from "../../hooks";
import { Reward } from "../../hooks/useCommunity";
import classNames from "classnames";
import RewardRedemptionModal from "../RewardRedemption";
import { useContext } from "react";
import { RewardContext } from "../../context/RewardContext";

export const Rewards = ({
  data,
  direction = "row",
  hideButton = false,
}: {
  data?: { redeemableRewards: Reward[]; redeemedRewards: Reward[] };
  direction?: "row" | "col";
  hideButton?: boolean;
}) => {
  const { registerRef, canScrollLeft, canScrollRight, scroll } =
    useHorizontalScrollListener(400);

  const { activeRedeemableReward, setActiveRedeemableReward } =
    useContext(RewardContext);

  const rewardsCardParentStyle = classNames(
    "flex mb-6 px-6 gap-4 mmd:flex-col mmd:px-0 mmd:mx-4",
    {
      "flex-row overflow-x-scroll": direction === "row",
      "flex-col overflow-y-scroll h-80 mmd:h-auto": direction === "col",
    },
  );

  if (!(data?.redeemableRewards?.length || data?.redeemedRewards?.length))
    return null;

  return (
    <div className="flex flex-col w-full bg-surface-900 border-1 border-surface-900 rounded-2xl relative overflow-hidden">
      <div className="absolute top-[160px] left-1/4 bg-surface-800 opacity-50  w-[1000px] h-[900px] rounded-[100px] border-2 border-white rotate-[59deg] mmd:w-[400px] mmd:h-[400px] mmd:top-[100px] mmd:left-1/2 mmd:-translate-x-1/2" />
      <div className="z-10">
        {activeRedeemableReward && <RewardRedemptionModal />}
        <div className="flex flex-row justify-between items-center p-6">
          <h2 className="text-2xl font-semibold text-surface-50">Rewards</h2>
          <ControlArrows
            mode="light"
            onLeftClick={() => {
              scroll("left");
            }}
            onRightClick={() => {
              scroll("right");
            }}
            leftDisabled={!canScrollLeft}
            rightDisabled={!canScrollRight}
            className="mmd:hidden"
          />
        </div>

        <div className={rewardsCardParentStyle} ref={(ref) => registerRef(ref)}>
          {data.redeemableRewards.map((reward, index) => (
            <RewardCard
              direction={direction}
              key={index}
              reward={reward}
              hideButton={hideButton}
            />
          ))}
          {data.redeemedRewards.map((reward, index) => (
            <RewardCard
              direction={direction}
              key={index}
              reward={reward}
              status="redeemed"
            />
          ))}
        </div>
      </div>
    </div>
  );
};
