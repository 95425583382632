import classNames from "classnames";

export enum ButtonType {
  PRIMARY = "primary",
  SECONDARY = "secondary",
  CUSTOM = "custom",
}

const Button = ({
  category = ButtonType.PRIMARY,
  action,
  className,
  children,
  progress = false,
  disabled = false,
  ...props
}: {
  category?: ButtonType;
  action?: (e: any) => void;
  className?: string;
  progress?: boolean;
  disabled?: boolean;
  [key: string]: any;
}) => {
  return (
    <>
      <button
        onClick={(e: any) => action?.(e)}
        disabled={disabled}
        className={`rounded-[12px] outline-none  opacity-${
          progress ? "50" : "100"
        }
    ${classNames(
      className,
      "body-lg font-medium border-solid first-letter:uppercase py-1 px-4 rounded-3 transition-all duration-300",
      {
        "border-2 border-transparent bg-primary-400 text-white hover:bg-primary-500 active:bg-primary-600":
          category === ButtonType.PRIMARY,
        "border-2 bg-white border-surface-250 outline-none hover:bg-slate-100 active:bg-slate-200":
          category === ButtonType.SECONDARY,
        className: category === ButtonType.CUSTOM,
      },
    )}`}
        {...props}
      >
        {children}
      </button>
    </>
  );
};

export default Button;
