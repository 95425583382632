import { ReactNode } from "react";
import InfoIcon from "../../assets/icons/info";

const Toast = ({ content }: { content: string }) => (
  <div className="flex gap-4 items-center px-5 py-3 w-full border border-solid border-warning-400 bg-warning-50 rounded-xl">
    <InfoIcon width="50" height="50" />
    <p className="body-sm text-surface-600">{content}</p>
  </div>
);

export default Toast;
