const MembersIcon = () => (
  <svg
    width="38"
    height="38"
    viewBox="0 0 38 38"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="19" cy="19" r="19" fill="#DFFAFE" />
    <g clip-path="url(#clip0_11884_2552)">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M25.7252 19.3086C24.5852 19.3086 23.5152 19.5386 22.6152 19.9286C25.7652 20.8386 27.9952 23.0186 28.0552 25.5686C29.6952 25.3586 31.4652 24.7386 31.4652 23.0486C31.4652 21.0186 28.8352 19.3086 25.7252 19.3086Z"
        fill="#4088B3"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M25.6943 17.8687H25.7143C27.8443 17.8687 29.5843 16.1287 29.5843 14.0087C29.5843 11.8787 27.8443 10.1387 25.7143 10.1387C25.2943 10.1387 24.8943 10.2087 24.5143 10.3287C25.2043 11.3487 25.6043 12.5787 25.6043 13.9087C25.6143 15.2587 25.1943 16.5587 24.4043 17.6387C24.8143 17.7887 25.2443 17.8687 25.6943 17.8687Z"
        fill="#4088B3"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M15.8752 19.9186C14.9752 19.5386 13.9052 19.3086 12.7752 19.3086C9.66515 19.3086 7.03516 21.0186 7.03516 23.0486C7.03516 24.7386 8.79515 25.3486 10.4352 25.5686C10.4852 23.0186 12.7152 20.8386 15.8752 19.9186Z"
        fill="#4088B3"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M12.7741 17.8687H12.8041C13.2541 17.8687 13.6841 17.7887 14.0941 17.6387C13.3341 16.5987 12.8841 15.3087 12.8841 13.9087C12.8841 12.5787 13.2841 11.3487 13.9741 10.3287C13.5941 10.2087 13.1941 10.1387 12.7741 10.1387C10.6441 10.1387 8.91406 11.8787 8.91406 14.0087C8.91406 16.1287 10.6441 17.8687 12.7741 17.8687Z"
        fill="#4088B3"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M19.2435 20.9453C15.2825 20.9453 11.9355 23.1013 11.9355 25.6533C11.9355 28.9533 17.4355 28.9533 19.2435 28.9533C22.3815 28.9533 26.5535 28.6133 26.5535 25.6743C26.5535 23.1103 23.2055 20.9453 19.2435 20.9453Z"
        fill="#4088B3"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M19.2458 18.7743H19.2788C20.5738 18.7703 21.7898 18.2613 22.7018 17.3423C23.6148 16.4234 24.1148 15.2044 24.1098 13.9114C24.1098 11.2284 21.9288 9.04639 19.2458 9.04639C16.5638 9.04639 14.3828 11.2284 14.3828 13.9114C14.3828 16.5924 16.5638 18.7743 19.2458 18.7743Z"
        fill="#4088B3"
      />
    </g>
    <defs>
      <clipPath id="clip0_11884_2552">
        <rect width="24" height="24" fill="white" transform="translate(7 7)" />
      </clipPath>
    </defs>
  </svg>
);

export default MembersIcon;
