const ProfileIcon = (props) => (
  <svg
    width={20}
    height={20}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.96788 9.91006H9.99371C12.3537 9.91006 14.2737 7.99008 14.2737 5.63008C14.2737 3.27008 12.3537 1.34924 9.99371 1.34924C7.63292 1.34924 5.71292 3.27008 5.71292 5.62758C5.70875 6.76841 6.14959 7.84174 6.95292 8.65089C7.75542 9.45923 8.82621 9.90673 9.96788 9.91006ZM6.96292 5.63008C6.96292 3.95924 8.32292 2.59924 9.99371 2.59924C11.6645 2.59924 13.0237 3.95924 13.0237 5.63008C13.0237 7.30091 11.6645 8.66006 9.99371 8.66006H9.97038C9.16371 8.65839 8.40788 8.34173 7.83959 7.76924C7.27125 7.19758 6.96042 6.43841 6.96292 5.63008Z"
      fill={props.color || "#202327"}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.6709 15.6299C3.6709 18.6507 8.43422 18.6507 9.99922 18.6507C11.5642 18.6507 16.3284 18.6507 16.3284 15.6115C16.3284 13.284 13.43 11.3174 9.99922 11.3174C6.56923 11.3174 3.6709 13.2924 3.6709 15.6299ZM4.9209 15.6299C4.9209 14.184 7.09257 12.5674 9.99922 12.5674C12.9067 12.5674 15.0784 14.1749 15.0784 15.6115C15.0784 16.7982 13.3692 17.4007 9.99922 17.4007C6.63007 17.4007 4.9209 16.8049 4.9209 15.6299Z"
      fill={props.color || "#202327"}
    />
  </svg>
);
export default ProfileIcon;
