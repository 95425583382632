import Button from "../Button";
import cn from "classnames";
export const TabBar = ({
  tabs,
  activeTab,
  setActiveTab,
  tabClassName,
  color,
}: {
  tabs: {
    label: string;
    value: string;
  }[];
  activeTab: string;
  setActiveTab: (tab: string) => void;
  tabClassName?: string;
  color?: string;
}) => {
  return (
    <div
      className={cn(
        `flex flex-row justify-evenly p-1 border border-${color ?? "primary-500"} rounded-lg gap-2`,
      )}
    >
      {tabs.map((tab) => (
        <Button
          key={tab.value}
          onClick={() => setActiveTab(tab.value)}
          className={cn(
            "px-4 py-2",
            activeTab === tab.value
              ? `!bg-${color ?? "primary-500"} text-white`
              : `bg-transparent !text-${color ?? "primary-500"} hover:!bg-${color ?? "primary-500"} hover:!text-white `,
            tabClassName,
          )}
        >
          {tab.label}
        </Button>
      ))}
    </div>
  );
};
