import React from "react";
import Button, { ButtonType } from "../Button";

type AuthMode = "login" | "register";

interface AuthActionsProps {
  selected?: AuthMode;
  onLogin?: () => void;
  onSignup?: () => void;
  className?: string;
}

export const AuthActions: React.FC<AuthActionsProps> = ({
  selected,
  onLogin,
  onSignup,
  className = "",
}) => {
  return (
    <div className="flex items-center justify-center">
      <div
        className={`inline-flex gap-1 items-center justify-center p-2 border border-solid border-surface-250 rounded-lg ${className}`}
      >
        <Button
          category={
            selected === "login" ? ButtonType.PRIMARY : ButtonType.SECONDARY
          }
          onClick={onLogin}
          className={`${selected === "login" ? "bg-gray-700 hover:bg-gray-700 " : "text-gray-700 border-none hover:bg-transparent"}`}
        >
          Log In
        </Button>
        <Button
          category={
            selected === "register" ? ButtonType.PRIMARY : ButtonType.SECONDARY
          }
          onClick={onSignup}
          className={`${selected === "register" ? "bg-gray-700 hover:bg-gray-700 " : "text-gray-700 border-none hover:bg-transparent"}`}
        >
          Sign up
        </Button>
      </div>
    </div>
  );
};
