import * as React from "react";

const SmileIcon = (props) => (
  <svg
    width={18}
    height={18}
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M9.1875 16.3125C7.7412 16.3125 6.32743 15.8837 5.12489 15.0801C3.92236 14.2766 2.9851 13.1346 2.43164 11.7984C1.87817 10.4622 1.73335 8.9919 2.01551 7.57343C2.29766 6.15492 2.99411 4.85196 4.01678 3.82929C5.03945 2.80661 6.34241 2.11017 7.76092 1.82801C9.1794 1.54586 10.6497 1.69067 11.9859 2.24413C13.322 2.7976 14.4641 3.73486 15.2676 4.93739C16.0711 6.13993 16.5 7.5537 16.5 9C16.4978 10.9388 15.7267 12.7974 14.3558 14.1683C12.9849 15.5392 11.1262 16.3103 9.1875 16.3125ZM9.1875 2.8125C7.96372 2.8125 6.76743 3.1754 5.7499 3.85529C4.73237 4.53518 3.93931 5.50154 3.47099 6.63215C3.00268 7.7628 2.88013 9.0069 3.11888 10.2071C3.35762 11.4074 3.94695 12.5099 4.81228 13.3752C5.67762 14.2406 6.78012 14.8298 7.98037 15.0686C9.1806 15.3074 10.4247 15.1848 11.5553 14.7165C12.6859 14.2482 13.6523 13.4552 14.3322 12.4376C15.0121 11.42 15.375 10.2238 15.375 9C15.3732 7.35953 14.7208 5.78675 13.5607 4.62676C12.4007 3.46676 10.828 2.81429 9.1875 2.8125Z"
      fill="black"
    />
    <path
      d="M6.76789 7.49916C6.62286 7.4993 6.48106 7.45637 6.36048 7.37579C6.23989 7.29521 6.14594 7.18063 6.09058 7.04658C6.03522 6.91253 6.02093 6.76506 6.04952 6.62287C6.07811 6.48068 6.1483 6.3502 6.25117 6.24797C6.35404 6.14573 6.48495 6.07635 6.62731 6.04864C6.76967 6.02093 6.91704 6.03614 7.05074 6.09233C7.18444 6.14851 7.29847 6.24315 7.3783 6.36424C7.45813 6.48532 7.50019 6.62738 7.49914 6.77241C7.49776 6.96551 7.42014 7.15023 7.28317 7.28636C7.14621 7.42247 6.96099 7.49897 6.76789 7.49916Z"
      fill="black"
    />
    <path
      d="M11.6429 7.49916C11.4978 7.4993 11.3561 7.45637 11.2355 7.37579C11.1149 7.29521 11.0209 7.18063 10.9655 7.04658C10.9102 6.91253 10.8959 6.76506 10.9245 6.62287C10.9531 6.48068 11.0233 6.3502 11.1261 6.24797C11.229 6.14573 11.36 6.07635 11.5023 6.04864C11.6447 6.02093 11.792 6.03614 11.9257 6.09233C12.0594 6.14851 12.1734 6.24315 12.2533 6.36424C12.3331 6.48532 12.3752 6.62738 12.3741 6.77241C12.3728 6.96551 12.2951 7.15023 12.1581 7.28636C12.0212 7.42247 11.836 7.49897 11.6429 7.49916Z"
      fill="black"
    />
    <path
      d="M9.18641 12.1883C8.90936 12.1769 8.63734 12.1098 8.38676 11.991C8.13619 11.8722 7.91209 11.704 7.72789 11.4967C7.54369 11.2893 7.40322 11.047 7.31479 10.7841C7.22635 10.5213 7.19178 10.2432 7.21318 9.9668C7.21318 9.81762 7.27246 9.67452 7.37795 9.569C7.48345 9.46355 7.62649 9.4043 7.77566 9.4043C7.92484 9.4043 8.06794 9.46355 8.17346 9.569C8.27891 9.67452 8.33816 9.81762 8.33816 9.9668C8.33816 9.97805 8.34791 11.0633 9.18641 11.0633C10.0249 11.0633 10.0332 9.97805 10.0332 9.9668C10.0332 9.81762 10.0925 9.67452 10.1979 9.569C10.3035 9.46355 10.4465 9.4043 10.5957 9.4043C10.7449 9.4043 10.8879 9.46355 10.9935 9.569C11.0989 9.67452 11.1582 9.81762 11.1582 9.9668C11.1796 10.2432 11.1451 10.521 11.0568 10.7838C10.9684 11.0466 10.828 11.2888 10.644 11.4962C10.46 11.7035 10.2361 11.8716 9.98569 11.9905C9.73526 12.1095 9.46338 12.1767 9.18641 12.1883Z"
      fill="black"
    />
  </svg>
);
export default SmileIcon;
