import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useAuthContext } from "../../hooks";

export interface RestrictedByPrivacyProps {
  children: React.ReactNode;
  pageName: string;
}

const RestrictedByUpdatedPrivacyPolicy: React.FC<RestrictedByPrivacyProps> = ({
  children,
  pageName,
}) => {
  const { user } = useAuthContext();
  const { _id } = useParams();

  const navigate = useNavigate();

  if (user && !user?.isAgreedToV2Terms) {
    navigate("/discovery", {
      state: { showUpdatedPrivacy: true, next: `/${pageName}/${_id}` },
    });
  }

  return <>{children}</>;
};

export default RestrictedByUpdatedPrivacyPolicy;
