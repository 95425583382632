import { useForm } from "react-hook-form";
import Button, { ButtonType } from "../Button";
import { TextInput } from "../TextInput";
import { useUser } from "../../hooks";
import CloseIcon from "../../assets/icons/close";
import AvatarPlaceholder from "../../assets/images/avatar_placeholder.png";
import ShareIcon from "../../assets/icons/share";
import TrashIcon from "../../assets/icons/trash";
import { Loader } from "../Loader";
import { useEffect, useRef, useState } from "react";
import { useFileUpload } from "../../hooks/useFileUpload";

const EditProfile = ({ closeModal }: { closeModal: () => void }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    reset,
  } = useForm();

  const {
    user,
    updateUser,
    isUserError,
    userError,
    isUserPending,
    isUserSuccess,
  } = useUser();

  useEffect(() => {
    if (user) {
      reset({
        firstName: user?.name,
        lastName: user?.surname,
        email: user?.email,
        profilePicture: user?.profilePicture,
      });
    }
  }, [user]);

  const onSubmit = (data: Record<string, any>) => {
    updateUser(data);
  };

  const [preview, setPreview] = useState<string>("");
  const [uploadAvatar, setUploadAvatar] = useState<boolean>(false);
  const fileInputRef = useRef<HTMLInputElement>(null);

  const { uploadFile, isUploading } = useFileUpload();

  const handleUploadClick = () => {
    fileInputRef.current?.click();
  };

  const handleUpload = async (acceptedFile: File) => {
    try {
      const { success, fileUrl } = await uploadFile(acceptedFile);

      if (success) {
        setValue("profilePicture", fileUrl);
        setUploadAvatar(true);
      }
    } catch (error) {
      console.error("File upload failed:", error);
    }
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFile = event.target.files?.[0];
    if (selectedFile) {
      handleUpload(selectedFile);

      const reader = new FileReader();
      reader.onload = () => {
        setPreview(reader.result as string);
      };
      reader.readAsDataURL(selectedFile);
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="w-full">
      <div className="flex flex-col p-1 gap-4 w-full relative">
        {/* Close Icon */}
        <CloseIcon
          className="absolute top-2 right-2 cursor-pointer"
          onClick={closeModal}
        />

        {/* Title */}
        <p className="title-lg-medium text-surface-900">Edit Profile</p>

        <div className="flex flex-col w-full gap-8">
          <div className="flex flex-col w-full gap-3">
            <div className="flex flex-col w-full gap-2">
              {/* Profile Picture Section */}
              <div className="flex flex-col gap-2">
                <p className="body-lg text-surface-900">Profile Picture</p>
                <div className="flex gap-6 items-center">
                  <img
                    src={preview || user?.profilePicture || AvatarPlaceholder}
                    alt="avatar"
                    className="rounded-full border-2 border-solid border-surface-50 w-[84px] h-[84px]"
                  />
                  <div className="flex gap-2">
                    {/* File Upload Input */}
                    <label className="cursor-pointer">
                      <input
                        type="file"
                        className="hidden"
                        ref={fileInputRef}
                        accept="image/*"
                        onChange={handleFileChange}
                      />
                      <Button
                        category={ButtonType.SECONDARY}
                        type="button"
                        onClick={handleUploadClick}
                        className="border-primary-500"
                      >
                        <div className="flex items-center py-2 px-4 gap-2.5">
                          <ShareIcon color="#9382FF" />
                          <p className="body-sm text-primary-500">
                            {isUploading ? (
                              <Loader size="sm" />
                            ) : uploadAvatar ? (
                              "uploaded"
                            ) : (
                              "upload"
                            )}
                          </p>
                        </div>
                      </Button>
                    </label>
                    {/* Delete Button */}
                    <Button
                      category={ButtonType.SECONDARY}
                      type="button"
                      onClick={() => {
                        setPreview("");
                        setValue("profilePicture", "");
                      }}
                    >
                      <div className="flex items-center py-2 px-4 gap-2.5">
                        <TrashIcon />
                        <p className="body-sm text-surface-900">Delete</p>
                      </div>
                    </Button>
                  </div>
                </div>
              </div>

              {/* Name Input */}
              <TextInput
                name={"firstName"}
                errorMessage={errors?.firstName?.message as string}
                registerInForm={register}
                label="Name"
                placeholder="Name"
                validation={{ required: "Name is required" }}
                labelClassName="body-lg text-surface-900"
                defaultValue={user?.name || ""}
              />

              {/* Surname Input */}
              <TextInput
                name={"lastName"}
                errorMessage={errors?.lastName?.message as string}
                registerInForm={register}
                label="Surname"
                placeholder="Surname"
                validation={{ required: "Surname is required" }}
                labelClassName="body-lg text-surface-900"
                defaultValue={user?.surname || ""}
              />

              {/* Email Input */}
              <TextInput
                name={"email"}
                errorMessage={errors?.email?.message as string}
                registerInForm={register}
                label="E-mail"
                placeholder="E-mail"
                validation={{
                  required: "Email is required",
                  pattern: {
                    value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                    message: "Please enter a valid email address",
                  },
                }}
                labelClassName="body-lg text-surface-900"
                defaultValue={user?.email || ""}
              />
            </div>

            {/* Submit Button or Loader */}
            {isUserPending ? (
              <div className="flex justify-center items-center my-4">
                <Loader size="md" />
              </div>
            ) : (
              <Button
                category={
                  isUploading ? ButtonType.SECONDARY : ButtonType.PRIMARY
                }
                disabled={isUploading}
                type="submit"
                className="rounded-[10px] py-3 px-5"
              >
                Submit
              </Button>
            )}

            {/* Error or Success Messages */}
            {isUserError && (
              <p className="text-red-500 text-sm">{userError?.message}</p>
            )}
            {isUserSuccess && (
              <p className="text-green-500 text-sm">
                User updated successfully
              </p>
            )}
          </div>
        </div>
      </div>
    </form>
  );
};

export default EditProfile;
