const WebPageIcon = (props) => (
  <svg
    width={18}
    height={18}
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.94266 3.05077C3.64207 2.30063 4.64207 1.875 5.83716 1.875H12.1628C13.361 1.875 14.3611 2.30048 15.0597 3.05099C15.7555 3.79847 16.1243 4.8378 16.1243 6.01958V6.55664H1.875V6.01958C1.875 4.83728 2.24596 3.79801 2.94266 3.05077ZM4.15429 4.91308C4.15429 4.60243 4.40614 4.35058 4.71679 4.35058H4.75839C5.06905 4.35058 5.32089 4.60243 5.32089 4.91308C5.32089 5.22374 5.06905 5.47558 4.75839 5.47558H4.71679C4.40614 5.47558 4.15429 5.22374 4.15429 4.91308ZM6.01843 4.91308C6.01843 4.60243 6.27027 4.35058 6.58093 4.35058H6.62252C6.93318 4.35058 7.18502 4.60243 7.18502 4.91308C7.18502 5.22374 6.93318 5.47558 6.62252 5.47558H6.58093C6.27027 5.47558 6.01843 5.22374 6.01843 4.91308ZM7.88325 4.91308C7.88325 4.60243 8.1351 4.35058 8.44575 4.35058H8.48737C8.79802 4.35058 9.04987 4.60243 9.04987 4.91308C9.04987 5.22374 8.79802 5.47558 8.48737 5.47558H8.44575C8.1351 5.47558 7.88325 5.22374 7.88325 4.91308Z"
      fill="white"
    />
    <path
      d="M1.875 7.68164V11.9797C1.875 13.1615 2.24403 14.2009 2.94002 14.9483C3.63883 15.6988 4.63903 16.1243 5.83716 16.1243H12.1621C13.3606 16.1243 14.3608 15.6988 15.0595 14.9483C15.7555 14.2008 16.1243 13.1614 16.1243 11.9797V7.68164H1.875Z"
      fill="white"
    />
  </svg>
);
export default WebPageIcon;
