import classNames from "classnames";
import QuestionMarkIcon from "../../assets/icons/questionMark";
import BoonTooltip from "../Tooltip";
import { useEffect } from "react";

export interface TextInputProps {
  type?: string;
  id?: string;
  label?: string;
  className?: string;
  labelClassName?: string;
  containerClassName?: string;
  errorMessage?: string;
  disabled?: boolean;
  registerInForm?: (name: any, validation: any) => object;
  name: string;
  validation?: any;
  [key: string]: any;
  tooltipContent?: string;
}

export const TextInput = ({
  id,
  label,
  className,
  errorMessage,
  type,
  registerInForm = () => ({}),
  name,
  validation,
  disabled = false,
  labelClassName = "",
  flex = "flex-col",
  containerClassName = "",
  tooltipContent,
  ...inputProps
}: TextInputProps) => {
  const inputClasses = classNames(
    `${className} text-[16px] w-full outline-none p-4 rounded-[8px] transition-all duration-300 ease-in-out focus:shadow-md hover:scale-101 bg-white placeholder:body-md placeholder:capitalize hover:bg-surface-150`,
    {
      "border-2 border-red-500 opacity-100": errorMessage,
      "border-2 border-surface-250 opacity-80": !errorMessage,
    },
  );

  const errorClasses = classNames(
    "text-red-500 text-sm font-medium mb-3 transition-opacity duration-300 ease-in-out",
    {
      "opacity-100": errorMessage,
      "opacity-0": !errorMessage,
    },
    "pl-2",
  );

  return (
    <div
      className={classNames(
        `flex ${flex} gap-2 w-full`,
        {
          "items-center": flex !== "flex-col", // Add vertical centering for non-column flex layouts
        },
        containerClassName,
      )}
    >
      <div className="flex gap-2 items-center">
        {label && (
          <p
            className={`body-lg font-medium text-surface-700 ${labelClassName}`}
          >
            {label} {validation ? "*" : ""}
          </p>
        )}
        {tooltipContent && (
          <>
            <a id={id}>
              <QuestionMarkIcon />
            </a>
            <BoonTooltip id={id} content={tooltipContent} />
          </>
        )}
      </div>
      <input
        type={type || "text"}
        className={inputClasses}
        disabled={disabled}
        {...registerInForm(name, validation)}
        {...inputProps}
        id={id}
      />
      {errorMessage ? (
        <p className={errorClasses}>{errorMessage || "\u00A0"}</p>
      ) : (
        ""
      )}
    </div>
  );
};
