import EmptyIcon from "../../assets/icons/empty";
import Button, { ButtonType } from "../Button";
import { useState } from "react";

import CreateCommunityModal from "../CreateCommunityModal";
import { useNavigate } from "react-router-dom";

const EmptyPage = () => {
  const [showCreateCommunityModal, setShowCreateCommunityModal] =
    useState(false);
  const navigate = useNavigate();

  return (
    <div className="flex flex-col gap-8 w-full items-center justify-center">
      {showCreateCommunityModal && (
        <CreateCommunityModal
          onClose={() => setShowCreateCommunityModal(false)}
        />
      )}
      <div className="flex flex-col w-[572px] items-center mmd:w-full">
        <EmptyIcon />
        <div className="flex flex-col gap-[50px]">
          <div className="flex flex-col gap-4">
            <p className="heading-md-bold text-surface-900 text-center">
              So much empty!
            </p>
            <p className="body-lg text-surface-700 text-center">
              Looks like you haven't joined any communities yet. Check out
              the elore page to find communities you can join.
            </p>
          </div>
          <div className="flex gap-4 justify-center mmd:flex-col">
            <Button
              action={() => navigate("/discovery")}
              category={ButtonType.SECONDARY}
            >
              Discover missions
            </Button>
            <Button
              action={() => setShowCreateCommunityModal(true)}
              progress={false}
            >
              Create A Community
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EmptyPage;
