import WebIcon from "../../assets/icons/web";
import TwitterIcon from "../../assets/icons/twitter";
import DiscordIcon from "../../assets/icons/discord";
import InstagramIcon from "../../assets/icons/instagram";
import React from "react";
import { TikTokIcon, YoutubeIcon } from "../../assets/icons";

const SOCIALS_TO_ICONS: Record<string, any> = {
  other: <WebIcon />,
  twitter: <TwitterIcon />,
  youtube: <YoutubeIcon />,
  "tik Tok": <TikTokIcon />,
  instagram: <InstagramIcon />,
  discord: <DiscordIcon />,
};

const SingleSocialMediaIcon = ({
  children,
  url,
}: {
  children: React.ReactNode;
  url?: string;
}) => (
  <div
    className="m-1 bg-surface-250 p-1 rounded-md cursor-pointer border-solid border-surface-50 hover:bg-surface-300 hover:text-white ease-in-out duration-300"
    onClick={() => url && window.open(url, "_blank")}
  >
    {children}
  </div>
);

const SocialMediaIcons = ({
  socials,
}: {
  socials?: Array<{ url: string; type: string }>;
}) => (
  <div className="flex justify-between items-center">
    {socials?.map((social) => {
      return (
        <SingleSocialMediaIcon url={social.url}>
          {SOCIALS_TO_ICONS[social?.type?.toLowerCase()]
            ? SOCIALS_TO_ICONS[social.type.toLowerCase()]
            : SOCIALS_TO_ICONS["other"]}
        </SingleSocialMediaIcon>
      );
    })}
  </div>
);

export default SocialMediaIcons;
