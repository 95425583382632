import { TextInput } from "../TextInput";

export interface CriteriaTypes {
  label?: string;
  criteriaLabel?: string;
  validation?: any;
  errorMessage?: string;
  name: string;
  registerInForm?: (name: string, validation: any) => object;
  setValueInForm?: (name: string, value: any) => void;
}

const Criteria = ({
  label,
  criteriaLabel,
  validation,
  ...props
}: CriteriaTypes) => {
  return (
    <div className="flex flex-col gap-2">
      <p className="body-lg text-surface-900">
        {label} {validation ? "*" : ""}
      </p>

      <div className="flex items-center justify-between gap-2">
        <h3 className="text-sm w-full text-surface-500">{criteriaLabel}</h3>
        <TextInput {...props} />
      </div>
    </div>
  );
};

export default Criteria;
