import classNames from "classnames";

export interface LeftNavbarItemProps {
  Icon?: any;
  label?: string;
  isActive?: boolean;
  onClick?: () => void;
}

const LeftNavbarItem = ({
  Icon,
  label,
  isActive,
  onClick,
}: LeftNavbarItemProps) => {
  return (
    <div
      className={classNames(
        "flex items-center gap-2 py-[9px] pl-3 cursor-pointer",
        { "bg-primary-500 rounded-lg": isActive },
      )}
      onClick={onClick}
    >
      {Icon && <Icon color={isActive ? "#FFFFFF" : "#202327"} />}
      <p
        className={classNames("body-lg-medium", {
          "text-surface-50": isActive,
          "text-surface-900": !isActive,
        })}
      >
        {label}
      </p>
    </div>
  );
};

export default LeftNavbarItem;
