import { useRedeemReward, useUser } from "../../hooks";
import { Reward } from "../../hooks/useCommunity";
import Button, { ButtonType } from "../Button";
import { useNavigate } from "react-router-dom";
import { Loader } from "../Loader";
import { useContext } from "react";
import { RewardContext } from "../../context/RewardContext";

const DigitalCollectibleRewardRedemption = () => {
  const { mutate, isPending } = useRedeemReward();
  const { user } = useUser();
  const navigate = useNavigate();
  const { activeRedeemableReward, setActiveRedeemableReward } =
    useContext(RewardContext);
  return (
    <div className="flex flex-col p-1.5 pb-6 gap-6">
      <img
        src={activeRedeemableReward.rewardImage}
        className="rounded-2xl w-full aspect-video rounded-t-[10px] object-contain"
      />
      <div className="flex flex-col px-2.5 gap-16">
        <div className="flex flex-col gap-4">
          <div className="flex flex-col gap-4">
            <p className="title-xl-bold text-surface-900">
              Congrats {user?.name}!
            </p>
            <div className="flex flex-col gap-2.5">
              <p className="body-lg text-surface-900">
                You've completed {activeRedeemableReward.mission?.name}
              </p>
              <p className="body-lg text-surface-500">
                {activeRedeemableReward.successMessage}
              </p>
              {activeRedeemableReward.status === "redeemed" && (
                <p className="body-lg text-surface-900">
                  Here's your {activeRedeemableReward.name}
                </p>
              )}
            </div>
          </div>
          <div className="flex justify-center">
            <img className="w-80" src={activeRedeemableReward.fileLink} />
          </div>
        </div>
        {isPending ? (
          <div className="flex justify-center items-center">
            <Loader size="md" />
          </div>
        ) : activeRedeemableReward.status === "redeemed" ? (
          <Button
            category={ButtonType.PRIMARY}
            className="py-3"
            action={() => {
              navigate("/user/management?tab=My Items");
              setActiveRedeemableReward(undefined);
            }}
          >
            See All Your Items
          </Button>
        ) : (
          <Button
            category={ButtonType.PRIMARY}
            className="py-3"
            action={() =>
              mutate({ rewardId: activeRedeemableReward._id, payload: {} })
            }
          >
            Redeem Your Reward
          </Button>
        )}
      </div>
    </div>
  );
};

export default DigitalCollectibleRewardRedemption;
