import ScoreCard from "../CommunityCards/ScoreCard";
import { Reward, Task } from "../../hooks/useCommunity";
import { useNavigate } from "react-router-dom";

export interface MissionCardProps {
  _id?: string;
  rewards?: Reward[];
  title?: string;
  name?: string;
  description?: string;
  tags?: string[];
  tasks?: Task[];
  imageUrl?: string;
  open?: boolean;
  rewardImages?: string[];
  [key: string]: any;
}

const FALLBACK_COVER =
  "https://s3-alpha-sig.figma.com/img/7662/625e/f776050657be84d4d3e8260d4e02636f?Expires=1733097600&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=j~1vRCiYsyNv0JBSYlGdOHoo0YE316v2fDQKxBaN2fDdbnnAX3HY5zYVzKJNQtiR~Y45wcuhahcSV2LkMhgIncCkON-zYIyZ-t49Aoseqqiwmd5wUy6JwLNIFvL74dpeeaMG1SYWizCSWO~JjVkqqI55EibETWb1EPVNCheowYvEYRhLxNQzmrjlJBejPGMcqFnw8ymh0HX9pxz0SkPcWNBr51qGGFN4eO8BXDrrauGXpKe3ty11uoXxHgP6yaVpNWJfUx-Z3oabTORKuf4WsbLFr7OIc0txWyt1R8d4Knq4Nwstd-FVZm0u4EUHILKJDKEREnh8uMZyIhiKqTj-2A__";

const MissionCard = ({
  rewards,
  tasks,
  title,
  description,
  imageUrl,
  tags,
  onClick,
  _id,
}: MissionCardProps) => {
  const navigate = useNavigate();

  return (
    <>
      <div
        onClick={() => {
          if (onClick) {
            onClick();
            return;
          }

          if (_id) navigate(`/mission/${_id}`);
        }}
        className="relative flex flex-col border rounded-[10px] border-surface-250 bg-surface-50 pb-6 gap-4 cursor-pointer hover:shadow-md transition-all duration-200"
      >
        <img
          src={imageUrl || FALLBACK_COVER}
          alt="mission avatar"
          className="w-full aspect-video rounded-t-[10px] object-contain"
        />
        <div className="flex flex-col gap-4 w-full px-4">
          <div className="flex flex-col gap-4">
            <div className="title-lg-bold text-surface-900">{title}</div>
            <div className="body-lg text-surface-700">{description}</div>
          </div>

          <div className="flex gap-2">
            {tags?.map((tag, index) => (
              <div
                key={`${index}`}
                className="rounded-sm bg-surface-250 p-[6px] body-sm-medium text-dark-50 cursor-pointer"
              >
                {tag}
              </div>
            ))}
          </div>
          <div className="w-fit flex flex-wrap gap-4">
            {rewards?.map((reward) => (
              <ScoreCard key={reward._id} score={reward.name} />
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default MissionCard;
