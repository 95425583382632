import { Reward } from "../../hooks/useCommunity";
import { Rewards } from "../Dashboard";

export const RewardsWithDisclaimer = ({
  redeemableRewards,
  redeemedRewards,
  className,
  hideButton,
}: {
  redeemableRewards: Reward[];
  redeemedRewards: Reward[];
  className?: string;
  hideButton?: boolean;
}) => {
  if (!redeemableRewards && !redeemedRewards) return null;

  return (
    <div className={className}>
      <Rewards
        direction="col"
        data={{
          redeemableRewards: redeemableRewards,
          redeemedRewards: redeemedRewards,
        }}
        hideButton={hideButton}
      />

      <div className="flex flex-col gap-4 mt-4">
        <h3 className="text-body-md">Rewards Disclaimer</h3>
        <ul className="flex flex-col gap-1 text-[#8E979F]">
          {[...redeemableRewards, ...redeemedRewards].map(
            (reward: any, index: number) => (
              <li key={index} className="flex items-start text-body-sm gap-1">
                <span className="first-letter:uppercase">
                  reward {index + 1}:
                </span>
                <p className="first-letter:uppercase">
                  {reward.disclaimer ||
                    `No disclaimer available for reward ${index + 1}`}
                </p>
              </li>
            ),
          )}
        </ul>
      </div>
    </div>
  );
};
