import { useContext } from "react";
import { UserManagementContext } from "../context";

export const useUserManagementContext = () => {
  const context = useContext(UserManagementContext);

  if (!context) {
    throw new Error(
      "useUserManagementContext must be used within an AuthProvider",
    );
  }

  return context;
};
