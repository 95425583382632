import { useState } from "react";
import { WalletIcon } from "../../assets/icons";
import Button, { ButtonType } from "../Button";
import UserDropdown from "./UserDropdown";
import CreateCommunityModal from "../CreateCommunityModal";
import { useScreenSize } from "../../hooks";
import { useNavigate } from "react-router-dom";

const UserLoggedIn = () => {
  const navigate = useNavigate();
  const { isMobile } = useScreenSize();

  return (
    <div className="flex items-center gap-4 mmd:gap-0">
      <Button
        action={() => {
          navigate("/user/management?tab=My Items");
        }}
        category={ButtonType.SECONDARY}
        className="mr-0 mmd:mr-4 border-none focus:bg-transparent"
      >
        <WalletIcon className="w-6 h-6" />
      </Button>

      <UserDropdown />

      {/* <button
        className="hidden mmd:block"
        onClick={() => {
          //TODO
        }}
      >
        <HamburgerIcon />
      </button> */}
    </div>
  );
};

export default UserLoggedIn;
