import EditPassword from "./EditPassword";
import EditProfile from "./EditProfile";
import EditSocialMedia from "./EditSocialMedia";

const EditUser = ({
  editModal = "signin",
  closeModal,
}: {
  editModal?: string;
  closeModal: () => void;
}) => {
  if (editModal === "password") {
    return (
      <div className="w-full h-full">
        <EditPassword
          closeModal={() => {
            closeModal();
          }}
        />
      </div>
    );
  }

  if (editModal === "profile") {
    return (
      <div className="w-full h-full">
        <EditProfile
          closeModal={() => {
            closeModal();
          }}
        />
      </div>
    );
  }

  if (editModal === "socialMedia") {
    return (
      <div className="w-full h-full">
        <EditSocialMedia
          closeModal={() => {
            closeModal();
          }}
        />
      </div>
    );
  }

  return null;
};

export default EditUser;
