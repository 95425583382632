import * as React from "react";
const MedalIcon = (props) => (
  <svg
    width={38}
    height={38}
    viewBox="0 0 38 38"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle cx={19} cy={19} r={19} fill="#FBEAEA" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M28.7216 12.4416C28.6796 12.0626 28.3586 11.7746 27.9766 11.7746H25.5136C25.5476 11.2156 25.5506 10.8426 25.5506 10.7656C25.5506 10.4886 25.3266 10.2656 25.0506 10.2656H12.9496C12.8166 10.2656 12.6896 10.3176 12.5966 10.4116C12.5026 10.5056 12.4496 10.6326 12.4496 10.7656C12.4496 10.8426 12.4526 11.2156 12.4876 11.7746H10.0236C9.64164 11.7746 9.32064 12.0626 9.27864 12.4416C9.25864 12.6156 8.83764 16.7216 11.9906 18.7976C12.1176 18.8806 12.2606 18.9206 12.4026 18.9206C12.6456 18.9206 12.8856 18.8026 13.0296 18.5836C13.2566 18.2376 13.1606 17.7726 12.8156 17.5446C11.0896 16.4086 10.7886 14.3376 10.7556 13.2746H12.6276C13.0166 16.4036 14.2646 21.3896 18.2466 21.9526V23.7086H15.9046C15.6866 23.7086 15.4946 23.8486 15.4286 24.0556L14.7316 26.2316H13.3996C12.9856 26.2316 12.6496 26.5666 12.6496 26.9816C12.6496 27.3956 12.9856 27.7316 13.3996 27.7316H24.7446C25.1586 27.7316 25.4946 27.3956 25.4946 26.9816C25.4946 26.5666 25.1586 26.2316 24.7446 26.2316H23.4126L22.7166 24.0556C22.6496 23.8486 22.4576 23.7086 22.2406 23.7086H19.7466V21.9546C23.7396 21.3996 24.9866 16.4076 25.3736 13.2746H27.2446C27.2106 14.3376 26.9106 16.4086 25.1846 17.5446C24.8396 17.7726 24.7436 18.2376 24.9716 18.5836C25.1156 18.8026 25.3546 18.9206 25.5976 18.9206C25.7396 18.9206 25.8826 18.8806 26.0106 18.7976C29.1626 16.7216 28.7416 12.6156 28.7216 12.4416Z"
      fill="#C92929"
    />
  </svg>
);
export default MedalIcon;
