import { useForm } from "react-hook-form";
import CloseIcon from "../../../assets/icons/close";
import Button from "../../Button";
import BoonModal from "../../Modal";
import { TextInput } from "../../TextInput";
import { SingleDropDown } from "../../Dropdown";
import { useState } from "react";
import { QUERY_KEYS, taskTypes } from "../../../constants";
import { Task } from "../../../hooks/useCommunity";
import { useCreateTask, useUpdateTask } from "../../../hooks/useTask";
import { useQueryClient } from "@tanstack/react-query";
import { useParams } from "react-router-dom";
import { Loader } from "../../Loader";

interface ITaskModal {
  onClose: () => void;
  task?: Task;
  missionId?: string;
  modalType?: string;
}

const TaskModal = ({
  onClose,

  task,
  missionId,
  modalType,
}: ITaskModal) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    clearErrors,
  } = useForm();
  const { _id } = useParams();
  const queryClient = useQueryClient();
  const {
    mutate: createTask,
    isPending: isCreateTaskPending,
    isError: isCreateTaskError,
    error: createTaskError,
    isSuccess: isCreateTaskSuccess,
  } = useCreateTask();

  const {
    mutate: updateTask,
    isPending: isUpdateTaskPending,
    isError: isUpdateTaskError,
    error: updateTaskError,
    isSuccess: isUpdateTaskSuccess,
  } = useUpdateTask();

  const [type, setType] = useState<string | undefined>();
  const [typePattern, setTypePattern] = useState();

  const onSubmit = (data: any) => {
    if (!type) {
      return;
    }
    const payload = { ...data, type, missionId };
    if (modalType === "updateTask") {
      updateTask(
        { _id: task?._id, payload },
        {
          onSuccess: () => {
            queryClient.refetchQueries({
              queryKey: [QUERY_KEYS.COMMUNITY, _id, true],
            });
            onClose();
          },
        },
      );
    } else {
      createTask(payload, {
        onSuccess: () => {
          queryClient.refetchQueries({
            queryKey: [QUERY_KEYS.COMMUNITY, _id, true],
          });
          onClose();
        },
      });
    }
  };

  const detectTaskType = (input: string, selectedType: string | undefined) => {
    if (!selectedType) return false;

    const selectedTask = taskTypes.find(
      (task) => task.display === selectedType,
    );

    if (!selectedTask) return false;

    return selectedTask.pattern.test(input);
  };

  return (
    <BoonModal onClose={onClose} style={{ padding: "0px" }}>
      <div className="flex flex-col p-6 gap-4 relative">
        <CloseIcon
          className="absolute top-6 right-6 cursor-pointer"
          onClick={onClose}
        />
        <p className="title-lg-medium text-surface-900">Task Manager</p>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="flex flex-col gap-8">
            <div className="flex flex-col gap-4">
              <SingleDropDown
                placeholder="Task type"
                data={taskTypes}
                name={`type`}
                setStateValue={(e: string) => {
                  setType(e);
                  setTypePattern(
                    taskTypes.find((x) => x.display === e)?.pattern as any,
                  );
                  clearErrors("type");
                }}
                label="Choose Task Type"
                labelClassName="body-lg text-surface-900"
                value={modalType === "updateTask" ? task?.type : undefined}
              />
              <TextInput
                name="link"
                errorMessage={errors?.link?.message as string}
                registerInForm={register}
                placeholder={type === "X tweet" ? "Tweet" : "Link"}
                validation={{
                  required:
                    type === "X tweet"
                      ? "Tweet Content is required"
                      : "Link is required",
                  validate: (value: any) => {
                    if (!detectTaskType(value, type)) {
                      return `invalid format for the selected task type "${type}".`;
                    }
                  },
                }}
                label={type === "X tweet" ? "Tweet Content" : "Link to social"}
                labelClassName="body-lg text-surface-900"
                defaultValue={
                  modalType === "updateTask" ? task?.link : undefined
                }
              />
            </div>
            {isCreateTaskPending || isUpdateTaskPending ? (
              <div className="flex justify-center items-center">
                <Loader size="md" />
              </div>
            ) : (
              <Button className="px-5 py-3">Save Details</Button>
            )}
            {(isCreateTaskError || isUpdateTaskError) && (
              <p>{createTaskError?.message || updateTaskError?.message}</p>
            )}
            {(isCreateTaskSuccess || isUpdateTaskSuccess) && (
              <p>Task saved successfully</p>
            )}
          </div>
        </form>
      </div>
    </BoonModal>
  );
};

export default TaskModal;
