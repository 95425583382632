import { useEffect } from "react";
import useDeactivateMission from "../../../hooks/useMissions/useActiveToggleMission";
import BoonModal from "../../Modal";
import Button, { ButtonType } from "../../Button";
import ModalHeader from "../../ModalHeader.tsx";
import { Loader } from "../../Loader";

const DeactivateMissionModal = ({
  onClose,
  missionId,
  missionActive,
}: {
  onClose: () => void;
  missionId: string;
  missionActive: boolean;
}) => {
  const {
    mutate: toggleActive,
    isPending: isDeactivateMissionPending,
    isError: isDeactivateMissionError,
    error: deactivateMissionError,
    isSuccess: isDeactivateMissionSuccess,
  } = useDeactivateMission({ missionId });

  useEffect(() => {
    if (isDeactivateMissionSuccess) {
      onClose();
    }
  }, [isDeactivateMissionSuccess, onClose]);

  return (
    <BoonModal onClose={onClose}>
      <ModalHeader onClose={onClose} />
      <div className="p-14 pt-0 flex flex-col gap-8">
        <div className="flex flex-col gap-3">
          <p className="title-xl-bold text-surface-900 text-center">
            {missionActive ? "Deactivate Mission" : "Activate Mission"}
          </p>
          <p className="body-lg text-surface-700 text-center">
            {missionActive
              ? `This will deactivate the mission meaning it won't show any more on
            your community page and it will show an error if anyone tries to
            access the mission link directly, however members who have already
            started the mission will still be able to complete it and get the
            reward.`
              : `This will activate the mission, meaning it will be visible on your 
            community page and accessible through the mission link. Members will be able to start the mission,
            complete it, and earn the reward.`}
          </p>
        </div>
        {isDeactivateMissionPending ? (
          <div className="flex justify-center items-center">
            <Loader size="md" />
          </div>
        ) : (
          <div className="flex flex-col gap-4">
            <Button
              className="bg-danger-200 hover:bg-danger-200 py-3"
              action={() => toggleActive(!missionActive)}
            >
              {missionActive ? "Confirm deactivation" : "Confirm activation"}
            </Button>
            <Button
              category={ButtonType.SECONDARY}
              className="py-3"
              action={onClose}
            >
              Cancel
            </Button>
            {isDeactivateMissionError && (
              <p>{deactivateMissionError?.message}</p>
            )}
          </div>
        )}
      </div>
    </BoonModal>
  );
};

export default DeactivateMissionModal;
