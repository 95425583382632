import { forwardRef } from "react";
import { ActionSection } from "./ActionSection";
import { InfoSection } from "./InfoSection";
import { TabBar } from "./TabBar";
import { useScreenSize } from "../../hooks/useScreenSize";

export const AuthSection = forwardRef<
  HTMLDivElement,
  { activeSection: string; setActiveSection: (section: string) => void }
>(({ activeSection, setActiveSection }, ref) => {
  const { isMobile } = useScreenSize();
  const color = activeSection === "followers" ? "primary-500" : "danger-250";

  return (
    <div
      className="flex flex-col items-center justify-center pt-12 mt-28 w-full bg-white mmd:mt-10"
      ref={ref}
    >
      <div className="flex flex-col items-center justify-center w-full">
        <div className="flex flex-col items-center gap-16 md:w-[80%] mmd:w-[90%] rounded-lg px-[80px] py-[100px] flex-1 mmd:flex-col mmd:gap-12 mmd:px-4 mmd:py-8 relative overflow-hidden">
          <div
            className="absolute inset-0 transition-opacity duration-300"
            style={{
              background: isMobile
                ? "linear-gradient(180deg, rgba(111, 111, 255, 0.5) -37.96%, #FFFFFF 92.36%)"
                : "linear-gradient(180deg, rgba(111, 111, 255, 0.8) -37.96%, #FFFFFF 52.36%)",
              opacity: activeSection === "followers" ? 1 : 0,
            }}
          />
          <div
            className="absolute inset-0 transition-opacity duration-300"
            style={{
              background:
                "linear-gradient(180deg, rgba(255, 153, 165, 0.5) -17.48%, #FFFFFF 68.82%)",
              opacity: activeSection === "creators" ? 1 : 0,
            }}
          />
          <div className="relative z-10 flex flex-col items-center gap-16 w-full mmd:gap-8">
            <TabBar
              tabs={[
                { label: "For Followers", value: "followers" },
                { label: "For Creators", value: "creators" },
              ]}
              activeTab={activeSection}
              setActiveTab={setActiveSection}
              color={color}
            />
            <div className="flex flex-row gap-32 items-start mmd:flex-col mmd:gap-0">
              <InfoSection activeSection={activeSection} />
              <ActionSection color={color} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});

AuthSection.displayName = "AuthSection";
