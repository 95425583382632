import CupIcon from "../../assets/icons/cup";

export interface ScoreCardTypes {
  className?: string;
  score?: string | number;
}

const ScoreCard = ({ score, className }: ScoreCardTypes) => {
  return (
    <div
      className={`flex justify-between items-center gap-2 p-2 rounded-md bg-[#FF91001A] ${className ?? ""}`}
    >
      <CupIcon />
      <span className="text-[#FF9100] text-sm font-bold">{score}</span>
    </div>
  );
};

export default ScoreCard;
