import * as React from "react";
const TagIcon = (props) => (
  <svg
    width={18}
    height={18}
    viewBox="0 0 18 18"
    fill="#202327"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M9.07144 17.2491C8.12996 17.2491 7.18898 17.2469 6.24848 17.2424C5.86426 17.2434 5.48366 17.168 5.12883 17.0207C4.77401 16.8732 4.45201 16.6568 4.18158 16.3838C3.91115 16.1109 3.69767 15.7869 3.55358 15.4307C3.40948 15.0746 3.33765 14.6933 3.34223 14.3091C3.34223 12.0591 3.34223 9.80685 3.34223 7.55235C3.334 6.97172 3.4439 6.39544 3.66527 5.85858C3.88664 5.32172 4.21485 4.83547 4.62996 4.42938C6.83271 2.22138 7.339 1.73611 7.48 1.63786C8.03471 1.2539 8.70589 1.07523 9.37811 1.13258C10.0503 1.18993 10.6815 1.47972 11.1632 1.95212L11.5382 2.32712C12.5207 3.29312 13.5977 4.35961 13.9517 4.78636C14.5894 5.54358 14.9352 6.50394 14.9267 7.49387C14.9327 9.51435 14.9327 11.5343 14.9267 13.5539V14.3114C14.9312 14.6956 14.8593 15.0768 14.715 15.433C14.5709 15.7891 14.3573 16.113 14.0868 16.3859C13.8163 16.6588 13.4943 16.8751 13.1394 17.0224C12.7845 17.1697 12.404 17.245 12.0198 17.2439C11.0367 17.2474 10.0539 17.2491 9.07144 17.2491ZM9.12169 2.24911C8.76686 2.24647 8.41984 2.35332 8.12794 2.5551C7.91644 2.72685 6.05874 4.58912 5.44899 5.20112C5.13162 5.50386 4.88046 5.86908 4.71135 6.27377C4.54225 6.67846 4.45888 7.11384 4.46649 7.55235C4.46649 9.80235 4.46649 12.0542 4.46649 14.3076C4.46052 14.5449 4.50248 14.7809 4.58986 15.0017C4.67724 15.2224 4.80824 15.4232 4.97502 15.5921C5.14181 15.761 5.34097 15.8945 5.56055 15.9845C5.78013 16.0747 6.01562 16.1196 6.25296 16.1166C8.17196 16.1251 10.0923 16.1251 12.0138 16.1166C12.2511 16.1196 12.4866 16.0747 12.7062 15.9845C12.9258 15.8945 13.1249 15.761 13.2917 15.5921C13.4585 15.4232 13.5894 15.2224 13.6768 15.0017C13.7642 14.7809 13.8062 14.5449 13.8002 14.3076V13.5494C13.8032 11.5319 13.8032 9.5136 13.8002 7.4946C13.8076 6.76692 13.555 6.06053 13.0878 5.50263C12.7405 5.08263 11.3964 3.76188 10.7507 3.12663L10.3675 2.74787C10.0335 2.42504 9.58616 2.24621 9.12169 2.24987V2.24911Z" />
    <path d="M9.13035 8.361C8.6571 8.3622 8.20268 8.1759 7.8666 7.84275C7.69478 7.6737 7.55835 7.47203 7.46523 7.24965C7.37213 7.02727 7.32425 6.78859 7.32433 6.54751C7.3217 6.31381 7.3657 6.08193 7.45379 5.86545C7.54185 5.64896 7.67228 5.45223 7.83735 5.28676C8.00438 5.11457 8.20395 4.97731 8.42453 4.88299C8.6451 4.78867 8.88225 4.73916 9.1221 4.73736C9.36203 4.73554 9.59985 4.78146 9.82185 4.87244C10.0439 4.96341 10.2455 5.09762 10.415 5.26726C10.7529 5.60698 10.943 6.06638 10.9438 6.54547C10.9446 7.02457 10.7562 7.48461 10.4196 7.8255C10.2506 7.99537 10.0497 8.13015 9.82845 8.22202C9.6072 8.31397 9.36998 8.36122 9.13035 8.361ZM9.13785 5.86276C9.0438 5.86377 8.95088 5.88344 8.86448 5.92065C8.77808 5.95785 8.69993 6.01186 8.6346 6.07952C8.57445 6.14073 8.52713 6.21331 8.49533 6.29301C8.46345 6.37272 8.44785 6.45795 8.44935 6.54375C8.44928 6.6366 8.46758 6.72855 8.50328 6.81427C8.5389 6.9 8.59118 6.97784 8.6571 7.04327C8.78753 7.16735 8.96123 7.23559 9.14123 7.23349C9.32123 7.23139 9.49335 7.1591 9.62085 7.032C9.68453 6.96795 9.7347 6.89185 9.7686 6.80814C9.8025 6.72443 9.8193 6.6348 9.8181 6.54451C9.819 6.45614 9.8022 6.36849 9.76868 6.2867C9.73523 6.20491 9.68565 6.13063 9.6231 6.06825C9.55935 6.00336 9.48345 5.95179 9.3996 5.91651C9.31575 5.88123 9.22575 5.86296 9.13485 5.86276H9.13785Z" />
  </svg>
);
export default TagIcon;
