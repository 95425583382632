import { useMutation, useQueryClient } from "@tanstack/react-query";
import { QUERY_KEYS } from "../../constants";
import { useAPI } from "../useApi";
import { useErrorHandler } from "../useErrorHandler";

const useDeactivateMission = ({ missionId }: { missionId: string }) => {
  const { patch } = useAPI();
  const queryClient = useQueryClient();
  const { handleApiError } = useErrorHandler();

  return useMutation({
    mutationFn: async (active: boolean) =>
      patch(`/api/mission/${missionId}`, { active: active }),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.COMMUNITY] });
    },
    onError: (error) => {
      handleApiError(error);
    },
  });
};

export default useDeactivateMission;
