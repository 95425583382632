import { Navigate, useNavigate, useParams } from "react-router-dom";
import CommunityCardBody from "../../components/CommunityPreviewCard/CommunityCardBody";
import CommunityCardHeader from "../../components/CommunityPreviewCard/CommunityHeader";
import {
  useCommunity,
  useCommunityWithUsername,
} from "../../hooks/useCommunity";
import { useAuthContext } from "../../hooks";
import { useUserMissions } from "../../hooks/useUserMissions";
import OngoingMissionCard from "../../components/CommunityCards/CommunityMissionCard/OngoingMissionCard";
import Button, { ButtonType } from "../../components/Button";
import EditIcon from "../../assets/icons/edit";
import AddDocumentsIcon from "../../assets/icons/addDocuments";
import { useMemo } from "react";
import { useScreenSize } from "../../hooks";
import { Loader } from "../../components";
import { AnalyticsIcon } from "../../assets/icons";
import toast from "react-hot-toast";

const Community = ({
  user,
  missions,
  community,
}: {
  user?: any;
  missions?: any;
  community?: any;
}) => {
  const _id = community._id;

  const ongoingMissions = missions?.missions.filter(
    (mission: any) => !mission.completedAt,
  );
  const completedMissions = missions?.missions.filter(
    (mission: any) => mission.completedAt,
  );

  const currentCommunityOngoingMissions = ongoingMissions?.filter(
    (elem: any) => elem.community._id === _id,
  );
  const currentCommunityCompletedMissions = useMemo(
    () => completedMissions?.filter((elem: any) => elem.community._id === _id),
    [_id, completedMissions],
  );

  const { isMobile } = useScreenSize();

  const navigate = useNavigate();

  const isLoggedInUserAuthor = community?.author._id === user?._id;

  return (
    <div className="w-full flex flex-col h-screen bg-surface-100  overflow-x-hidden pb-10">
      {isLoggedInUserAuthor && (
        <div className="w-full bg-[#55A8D9] h-[72px] flex justify-start items-center pr-14 pl-14 py-2 mmd:pr-4 mmd:pl-4">
          <Button
            onClick={() => navigate(`/community/management/${_id}`)}
            category={ButtonType.SECONDARY}
            className="py-[6px] px-[6px]"
          >
            <div className="flex items-center gap-2">
              <EditIcon />
              Edit community
            </div>
          </Button>
          <p className="text-body-lg text-surface-100 ml-4">
            This is preview mode. Click here to edit your community and missions
          </p>
        </div>
      )}
      <div className="bg-surface-100 w-full bg-blue-100 px-14 mmd:px-4 md:px-12 2xl:px-60">
        <CommunityCardHeader
          title={community.name}
          description={community.description}
          numberOfMissions={community?.sections?.reduce(
            (acc: 0, section: any) => acc + (section.missions?.length || 0),
            0,
          )}
          numberOfTasks={community.sections?.reduce(
            (acc: number, section: any) =>
              acc +
              section.missions?.reduce(
                (missionAcc: number, mission: any) =>
                  missionAcc + (mission.tasks?.length || 0),
                0,
              ),
            0,
          )}
          numberOfMissionsDone={currentCommunityCompletedMissions?.length}
          numberOfOngoingMissions={currentCommunityOngoingMissions?.length}
          numberOfMembers={community.members?.length}
          _id={community._id}
          isUserJoined={
            user &&
            user?.joinedCommunities?.some((comm: any) => comm._id === _id)
          }
          logoUrl={community.logoUrl}
          socials={community.socials}
        />
        <div className="flex flex-col gap-[42px]">
          <div className="mt-[130px] mmd:mt-4 flex flex-col gap-[33px]">
            {isLoggedInUserAuthor && !isMobile && (
              <div className="flex justify-center items-center w-full">
                <div className="flex justify-center gap-4">
                  <Button
                    category={ButtonType.SECONDARY}
                    onClick={() => navigate(`/community/management/${_id}`)}
                    className="py-[8px] px-[8px]"
                  >
                    <div className="flex items-center gap-2">
                      <EditIcon />
                      <p className="text-body-lg font-light">Edit Missions</p>
                    </div>
                  </Button>

                  <Button
                    onClick={() =>
                      navigate(`/community/management/${_id}`, {
                        state: {
                          showCreateMissionModal: true,
                        },
                      })
                    }
                  >
                    <div className="flex items-center gap-2">
                      <AddDocumentsIcon />
                      <p className="text-body-lg font-light">Add Mission</p>
                    </div>
                  </Button>

                  <Button
                    onClick={() =>
                      navigate(`/community/management/${_id}/analytics`)
                    }
                  >
                    <div className="flex items-center gap-2">
                      <AnalyticsIcon color="white" />
                      <p className="text-body-lg font-light">Analytics</p>
                    </div>
                  </Button>
                </div>
              </div>
            )}
            {currentCommunityOngoingMissions &&
            currentCommunityOngoingMissions.length > 0 ? (
              <>
                <p className="heading-md text-surface-900">Ongoing missions</p>
                <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-6">
                  {currentCommunityOngoingMissions?.map((mission: any) => (
                    <OngoingMissionCard
                      key={mission._id}
                      {...mission}
                      _id={mission._id}
                      communityId={mission.community._id}
                      nextTaskName={mission.nextTask?.type || ""}
                      communityName={mission.community?.name}
                      rewards={mission?.rewards}
                      missionName={mission.name}
                      tasksFinished={mission.completedTasks?.length || 0}
                      tasksTotal={mission.tasks?.length || 0}
                      missionImage={mission.imageUrl}
                    />
                  ))}
                </div>
              </>
            ) : null}
          </div>
          <CommunityCardBody communitySections={community.sections} />
        </div>
      </div>
    </div>
  );
};

const CommunityForUser = ({ community }: { community: any }) => {
  const { user, isValidating } = useAuthContext();
  const { data: missions } = useUserMissions();

  if (isValidating) return <Loader fullScreen />;

  if (!community) {
    return (
      <div className="w-full h-full flex justify-center items-center">
        <p className="text-body-lg font-medium">Community not found</p>
      </div>
    );
  }

  return <Community user={user} missions={missions} community={community} />;
};

const CommunityForGuest = ({ community }: { community: any }) => {
  if (!community) {
    return (
      <div className="w-full h-full flex justify-center items-center">
        <p className="text-body-lg font-medium">Community not found</p>
      </div>
    );
  }

  return <Community community={community} />;
};

const CommunityWrapper = () => {
  const { user, isValidating } = useAuthContext();
  const { _id } = useParams();
  const { data: community, isLoading } = useCommunity({ _id });

  if (isLoading || isValidating) {
    return <Loader size="lg" fullScreen />;
  }

  return user ? (
    <CommunityForUser community={community} />
  ) : (
    <CommunityForGuest community={community} />
  );
};

export const CommunityWithUsername = () => {
  const { user, isValidating } = useAuthContext();
  const { username } = useParams();
  const {
    data: community,
    isLoading,
    error,
  } = useCommunityWithUsername({
    username,
  });

  if (isLoading || isValidating) {
    return <Loader size="lg" fullScreen />;
  }

  if (error) {
    toast.error("Community not found");
    return <Navigate to="/" />;
  }

  return user ? (
    <CommunityForUser community={community} />
  ) : (
    <CommunityForGuest community={community} />
  );
};

export default CommunityWrapper;
