import { useAuthContext } from "../../hooks";
import Logo from "../../assets/images/logo.svg";
import { LeftNavbarItemProps } from "./LeftNavbarItem";
import LeftNavbarItem from "./LeftNavbarItem";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

interface LeftNavbarProps {
  cardImage?: string;
  cardName?: string;
  link?: string;
  activeTab?: string;
  setActiveTab?: (data: string | undefined) => void;
  navbarItems?: LeftNavbarItemProps[];
}

const LeftNavbar = ({
  link,
  cardName,
  cardImage,
  activeTab,
  setActiveTab,
  navbarItems,
}: LeftNavbarProps) => {
  const navigate = useNavigate();
  const { user } = useAuthContext();
  const [copied, setCopied] = useState(false);

  return (
    <div className="px-6 flex flex-col h-full bg-surface-50 gap-2.5 rounded-r-2xl">
      <div className="flex flex-col justify-between w-full h-full my-11">
        <div className="flex flex-col gap-11 w-full cursor-pointer">
          <div className="gap-2.5" onClick={() => navigate("/dashboard")}>
            <img src={Logo} alt="logo" />
          </div>
          <div className="flex flex-col w-full text-surface-700">
            <p className="body-sm text-slate-700 mb-2">Content</p>
            <div className="flex flex-col w-full gap-2">
              {navbarItems?.map((elem) => (
                <LeftNavbarItem
                  key={elem.label}
                  label={elem.label}
                  Icon={elem.Icon}
                  isActive={elem.label === activeTab}
                  onClick={() => setActiveTab && setActiveTab(elem.label)}
                />
              ))}
            </div>
          </div>
          {link && (
            <div className="flex flex-col gap-2">
              <p className="body-sm text-surface-700">Copy Link</p>
              <div className="flex overflow-hidden">
                <p className="border border-solid border-surface-250 rounded-l-lg px-3 py-1.5 body-md text-surface-500 w-2/3 hover:bg-surface-100 transition-all duration-300">
                  {link}
                </p>
                <p
                  className="flex items-center justify-start border border-solid border-surface-250 rounded-r-lg px-3 py-1.5 body-md bg-primary-50 text-primary-500 w-1/3 cursor-pointer hover:bg-primary-100 transition-all duration-300 active:bg-primary-200 hover:text-white"
                  onClick={() => {
                    navigator.clipboard.writeText(
                      window.location.origin + "/" + link,
                    );
                    setCopied(true);
                    setTimeout(() => setCopied(false), 1000);
                  }}
                >
                  {copied
                    ? "Copied\u00A0✅"
                    : "Copy\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0"}
                </p>
              </div>
            </div>
          )}
        </div>
        {cardImage && cardName && (
          <div className="flex w-full border border-solid border-surface-150 rounded-lg p-3 gap-2">
            <div>
              <img
                src={cardImage}
                alt="avatar"
                width={"42px"}
                height={"42px"}
                className="rounded-md"
              />
            </div>
            <div className="flex flex-col gap-px">
              <p className="body-md-medium text-surface-900">{cardName}</p>
              <p className="body-sm text-surface-700">
                {user?.name || "Admin"}
              </p>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default LeftNavbar;
