import { createContext, ReactNode, useEffect, useState } from "react";
import { useAuthContext } from "../hooks";
import { useLocation, useNavigate } from "react-router-dom";
import PrivacyModal from "../components/PrivacyModal";

export interface PrivacyProviderTypes {
  children: ReactNode;
}

export interface PrivacyContextTypes {
  handleClickWithOptionalPrivacyCheck: (redirectUrl: string) => void;
  isPrivacyModalOpen: {
    isOpen: boolean;
    next: (() => void) | undefined;
  };
}

export const PrivacyContext = createContext<PrivacyContextTypes>({
  handleClickWithOptionalPrivacyCheck: () => {},
  isPrivacyModalOpen: {
    isOpen: false,
    next: () => {},
  },
});

const PrivacyProvider = ({ children }: PrivacyProviderTypes) => {
  const { user } = useAuthContext();
  const navigate = useNavigate();
  const location = useLocation();

  const [isPrivacyModalOpen, setIsPrivacyModalOpen] = useState<{
    isOpen: boolean;
    next: (() => void) | undefined;
  }>({
    isOpen: location.state?.showUpdatedPrivacy,
    next: location.state?.next
      ? () => navigate(location.state.next)
      : undefined,
  });

  useEffect(() => {
    setIsPrivacyModalOpen({
      isOpen: location.state?.showUpdatedPrivacy,
      next: location.state?.next
        ? () => navigate(location.state.next)
        : undefined,
    });
  }, [location.state]);

  const handleClickWithOptionalPrivacyCheck = (redirectUrl: string) => {
    if (!user || user?.isAgreedToV2Terms) {
      navigate(redirectUrl);
    } else {
      setIsPrivacyModalOpen({
        isOpen: true,
        next: () => navigate(redirectUrl),
      });
    }
  };

  return (
    <PrivacyContext.Provider
      value={{ handleClickWithOptionalPrivacyCheck, isPrivacyModalOpen }}
    >
      {children}
      {isPrivacyModalOpen.isOpen && (
        <PrivacyModal
          onSubmit={() => {
            isPrivacyModalOpen?.next?.();
          }}
          onClose={() =>
            setIsPrivacyModalOpen({ isOpen: false, next: undefined })
          }
        />
      )}
    </PrivacyContext.Provider>
  );
};

export default PrivacyProvider;
