import classNames from "classnames";

export interface CheckboxProps {
  label?: string;
  className?: string;
  errorMessage?: string;
  registerInForm?: (name: string, validation: any) => object;
  name: string;
  labelClassName?: string;
  onLabelClick?: () => void;
  validation?: any;
  [key: string]: any;
}

export const Checkbox = ({
  label,
  errorMessage,
  registerInForm = () => ({}),
  name,
  validation,
  onLabelClick,
  labelClassName,
  ...inputProps
}: CheckboxProps) => {
  const checkboxClasses = classNames(
    "w-[18px] h-[18px] rounded-[4px] border-2 transition-all duration-300 ease-in-out cursor-pointer",
    {
      "border-red-500": errorMessage,
      "border-surface-250 hover:bg-surface-150 focus:border-primary-300":
        !errorMessage,
    },
  );

  const errorClasses = classNames(
    "text-red-500 text-sm font-medium mb-3 transition-opacity duration-300 ease-in-out",
    {
      "opacity-100 flex": errorMessage,
      "opacity-0 hidden": !errorMessage,
    },
    "pl-2",
  );

  return (
    <div className="flex flex-col items-start">
      <div className="flex items-center">
        <input
          type="checkbox"
          className={checkboxClasses}
          {...registerInForm(name, validation)}
          {...inputProps}
        />
        {label && (
          <label
            className={`body-lg font-medium text-surface-700 ml-2 cursor-pointer ${labelClassName}`}
            htmlFor={name}
            onClick={onLabelClick}
          >
            {label}
          </label>
        )}
      </div>
      <p className={errorClasses}>{errorMessage || "\u00A0"}</p>
    </div>
  );
};

export default Checkbox;
