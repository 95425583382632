import { useContext } from "react";
import { useRedeemReward, useUser } from "../../hooks";
import { Reward } from "../../hooks/useCommunity";
import Button, { ButtonType } from "../Button";
import { useNavigate } from "react-router-dom";
import FlagIcon from "../../assets/icons/flag";
import { CopyIcon } from "../../assets/icons";
import WebPageIcon from "../../assets/icons/webPage";
import { absoluteLink } from "../../hooks/useTask/useUserTask";
import OpenLinkIcon from "../../assets/icons/openLink";
import { RewardContext } from "../../context/RewardContext";
import { Loader } from "../Loader";

export interface RewardRedemptionRowProps {
  label: string;
  LabelIcon: any;
  value: string;
  ValueIcon: any;
  action: () => void;
}

export const RewardRedemptionRow = ({
  label,
  LabelIcon,
  value,
  ValueIcon,
  action,
}: RewardRedemptionRowProps) => {
  return (
    <div className="flex border border-solid border-surface-250 bg-[#FBFBFB] rounded-lg p-4">
      <div className="flex gap-2 w-full items-center">
        <div className="bg-[#FF9100] rounded-full p-3">
          <LabelIcon />
        </div>
        <div className="flex justify-between w-full ">
          <p className="body-md text-surface-900">{label}</p>
          <p className="pr-3">{value}</p>
        </div>
      </div>
      <Button className="py-3 w-fit" action={action}>
        <ValueIcon color="#FFFFFF" />
      </Button>
    </div>
  );
};

const InVenueRewardRedemption = () => {
  const { user } = useUser();
  const navigate = useNavigate();
  const { activeRedeemableReward, setActiveRedeemableReward } =
    useContext(RewardContext);
  const { mutate, isPending } = useRedeemReward();

  if (activeRedeemableReward.status === "warning") {
    return (
      <div className="flex flex-col p-16 gap-8">
        <div className="flex flex-col gap-3 text-center">
          <p className="title-xl-bold text-surface-900">Warning</p>
          <p className="body-lg text-surface-700">
            You should redeem this reward in the presence of staff at the
            venue.Are you sure you want to redeem the reward?
          </p>
        </div>
        <div className="flex flex-col gap-4">
          {isPending ? (
            <div className="flex justify-center items-center">
              <Loader size="md" />
            </div>
          ) : (
            <Button
              category={ButtonType.CUSTOM}
              className="bg-danger-200 hover:bg-danger-300 py-3 text-surface-50"
              action={() =>
                mutate({ rewardId: activeRedeemableReward._id, payload: {} })
              }
            >
              Yes, redeem
            </Button>
          )}
          <Button
            category={ButtonType.SECONDARY}
            className="py-3"
            action={() =>
              setActiveRedeemableReward({
                ...activeRedeemableReward,
                status: "redeemable",
              })
            }
          >
            Cancel
          </Button>
        </div>
      </div>
    );
  }

  return (
    <div className="flex flex-col p-1.5 pb-6 gap-6">
      <img
        src={activeRedeemableReward.rewardImage}
        className="rounded-2xl w-full aspect-video rounded-t-[10px] object-contain"
      />
      <div className="flex flex-col px-2.5 gap-16">
        {activeRedeemableReward.status === "redeemable" ? (
          <>
            <div className="flex flex-col gap-4">
              <div className="flex flex-col gap-4">
                <p className="title-xl-bold text-surface-900">
                  Congrats {user?.name}!
                </p>
                <p className="title-xl-bold text-surface-900">
                  You've earned a {activeRedeemableReward.name}
                </p>
                <div className="flex flex-col gap-2.5">
                  <p className="body-lg text-surface-900">
                    You've completed {activeRedeemableReward.mission?.name}
                  </p>
                  <p className="body-lg text-surface-500">
                    {activeRedeemableReward.successMessage}
                  </p>
                </div>
              </div>
              {(activeRedeemableReward.validityPeriodStart ||
                activeRedeemableReward.validityPeriodEnd) && (
                <div className="flex justify-between">
                  <p className="body-lg text-justify">Validity Period</p>
                  <p className="body-md text-surface-500">
                    {activeRedeemableReward.validityPeriodStart?.toLocaleString() ||
                      "N/A"}{" "}
                    -{" "}
                    {activeRedeemableReward.validityPeriodEnd?.toLocaleString() ||
                      "N/A"}
                  </p>
                </div>
              )}
              <div className="bg-warning-50 rounded-xl p-4 text-[#FF9100]">
                <p className="body-md-bold">Redemption Instructions</p>
                <p>{activeRedeemableReward.redemptionInstructions}</p>
              </div>
              <RewardRedemptionRow
                label="Address"
                LabelIcon={FlagIcon}
                value={activeRedeemableReward.venueAddress || ""}
                ValueIcon={CopyIcon}
                action={() =>
                  navigator.clipboard.writeText(
                    activeRedeemableReward.venueAddress || "",
                  )
                }
              />
              <RewardRedemptionRow
                label="Address link"
                LabelIcon={WebPageIcon}
                value={activeRedeemableReward.venueLocationLink || ""}
                ValueIcon={OpenLinkIcon}
                action={() =>
                  window.open(
                    absoluteLink(
                      activeRedeemableReward.venueLocationLink || "",
                    ),
                    "_blank",
                    "noopener,noreferrer",
                  )
                }
              />
              <div className="flex flex-col p-4 gap-1 rounded-xl bg-[#FBFBFB]">
                <p className="body-mg text-surface-900">
                  Redemption Disclaimer
                </p>
                <p className="body-sm text-surface-500">
                  {activeRedeemableReward.disclaimer}
                </p>
              </div>
            </div>
            <Button
              category={ButtonType.PRIMARY}
              className="py-3"
              action={() =>
                setActiveRedeemableReward({
                  ...activeRedeemableReward,
                  status: "warning",
                })
              }
            >
              Redeem Your Reward
            </Button>
          </>
        ) : (
          <>
            <div className="flex flex-col gap-4">
              <p className="title-xl-bold text-surface-900">
                Your {activeRedeemableReward.name} has been redeemed!
              </p>
              <div className="flex flex-col gap-2.5">
                <p className="body-lg text-surface-900">
                  You've now used up the reward.
                </p>
                <p className="body-lg text-surface-900">
                  If you have any questions, please contact the help@boonhq.io
                </p>
              </div>
            </div>
            <Button
              category={ButtonType.PRIMARY}
              className="py-3"
              action={() => {
                navigate("/user/management?tab=My Items");
                setActiveRedeemableReward(undefined);
              }}
            >
              See All Your Items
            </Button>
          </>
        )}
      </div>
    </div>
  );
};

export default InVenueRewardRedemption;
