import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useAPI } from "../useApi";
import { QUERY_KEYS } from "../../constants";
import { Community } from "../useCommunity";

const useUpdateCommunity = () => {
  const { patch } = useAPI();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (data: Community) => {
      return patch(`/api/community/${data._id}`, data);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.COMMUNITY] });
    },
  });
};

export default useUpdateCommunity;
