const BoxesIcon = (props) => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.5 4.41667C1.5 2.22899 1.52342 1.5 4.41667 1.5C7.30991 1.5 7.33333 2.22899 7.33333 4.41667C7.33333 6.60434 7.34256 7.33333 4.41667 7.33333C1.49077 7.33333 1.5 6.60434 1.5 4.41667Z"
      stroke={props.color || "#202327"}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.667 4.41667C10.667 2.22899 10.6904 1.5 13.5837 1.5C16.4769 1.5 16.5003 2.22899 16.5003 4.41667C16.5003 6.60434 16.5096 7.33333 13.5837 7.33333C10.6578 7.33333 10.667 6.60434 10.667 4.41667Z"
      stroke={props.color || "#202327"}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.5 13.5832C1.5 11.3955 1.52342 10.6665 4.41667 10.6665C7.30991 10.6665 7.33333 11.3955 7.33333 13.5832C7.33333 15.7708 7.34256 16.4998 4.41667 16.4998C1.49077 16.4998 1.5 15.7708 1.5 13.5832Z"
      stroke={props.color || "#202327"}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.667 13.5832C10.667 11.3955 10.6904 10.6665 13.5837 10.6665C16.4769 10.6665 16.5003 11.3955 16.5003 13.5832C16.5003 15.7708 16.5096 16.4998 13.5837 16.4998C10.6578 16.4998 10.667 15.7708 10.667 13.5832Z"
      stroke={props.color || "#202327"}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default BoxesIcon;
