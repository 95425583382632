import { cloneElement, ReactElement, useState, MouseEvent } from "react";
import { useAuthContext } from "../../hooks";
import { useUserManagementContext } from "../../hooks/useUserManagementContext";

export interface RestrictedComponentProps {
  children: ReactElement;
}

const RestrictedComponent = ({ children }: RestrictedComponentProps) => {
  const { setActiveAuth } = useUserManagementContext();
  const { user } = useAuthContext();
  const [pendingAction, setPendingAction] = useState<(() => void) | null>(null);

  const showSignIn = (e: MouseEvent) => {
    if (!user) {
      e.preventDefault();
      setPendingAction(() => () => children.props.onClick(e));
      return setActiveAuth("signin");
    }
    children.props.onClick(e);
  };

  const executePendingAction = () => {
    if (pendingAction) {
      pendingAction();
      setPendingAction(null);
    }
  };

  if (user && pendingAction) {
    executePendingAction();
  }

  return cloneElement(children, { onClick: showSignIn });
};

export default RestrictedComponent;
