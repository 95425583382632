const TikTokIcon = (props) => (
  <svg
    width={17}
    height={17}
    viewBox="0 0 17 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_3908_3925)">
      <path
        d="M12.9726 3.81637C12.8713 3.76403 12.7727 3.70665 12.6772 3.64445C12.3994 3.46081 12.1447 3.24441 11.9186 2.99994C11.3529 2.35261 11.1416 1.69591 11.0637 1.23612H11.0669C11.0019 0.854473 11.0287 0.607544 11.0328 0.607544H8.45598V10.5716C8.45598 10.7054 8.45599 10.8376 8.45036 10.9682C8.45036 10.9845 8.4488 10.9995 8.44786 11.017C8.44786 11.0242 8.44786 11.0317 8.4463 11.0392V11.0448C8.41913 11.4023 8.30453 11.7477 8.11257 12.0505C7.92061 12.3533 7.65717 12.6043 7.34543 12.7814C7.02053 12.9663 6.65306 13.0632 6.27926 13.0628C5.07869 13.0628 4.10566 12.0838 4.10566 10.8748C4.10566 9.66576 5.07869 8.6868 6.27926 8.6868C6.50652 8.68659 6.73239 8.72235 6.94847 8.79276L6.95159 6.16907C6.29563 6.08433 5.62922 6.13647 4.99441 6.32217C4.3596 6.50788 3.77016 6.82314 3.26329 7.24805C2.81915 7.63395 2.44576 8.09439 2.15993 8.60866C2.05115 8.7962 1.64075 9.5498 1.59105 10.7729C1.55979 11.4671 1.76828 12.1863 1.86767 12.4836V12.4898C1.93019 12.6649 2.17243 13.2622 2.5672 13.7657C2.88553 14.1696 3.26163 14.5245 3.68338 14.8188V14.8125L3.68963 14.8188C4.93709 15.6664 6.32021 15.6108 6.32021 15.6108C6.55963 15.6011 7.36168 15.6108 8.27251 15.1792C9.28273 14.7006 9.85785 13.9876 9.85785 13.9876C10.2253 13.5616 10.5174 13.0761 10.7218 12.552C10.955 11.9391 11.0328 11.2039 11.0328 10.9101V5.62395C11.0641 5.6427 11.4804 5.91807 11.4804 5.91807C11.4804 5.91807 12.0802 6.30253 13.016 6.5529C13.6874 6.73106 14.592 6.76857 14.592 6.76857V4.21052C14.2751 4.2449 13.6315 4.14488 12.9726 3.81637Z"
        fill="#202327"
      />
    </g>
    <defs>
      <clipPath id="clip0_3908_3925">
        <rect
          width={16.0035}
          height={16.0035}
          fill="white"
          transform="translate(0.0898438 0.108154)"
        />
      </clipPath>
    </defs>
  </svg>
);
export default TikTokIcon;
