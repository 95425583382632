import React, { useEffect, useState } from "react";
import ReactConfetti from "react-confetti";
import { useScreenSize } from "../hooks";

interface ConfettiProps {
  run?: boolean;
  duration?: number;
}

export const Confetti: React.FC<ConfettiProps> = ({
  run = true,
  duration = 2000,
}) => {
  const [isActive, setIsActive] = useState(run);

  const { width, height } = useScreenSize();

  useEffect(() => {
    if (run && duration) {
      setIsActive(true);
      const timer = setTimeout(() => {
        setIsActive(false);
      }, duration);
      return () => clearTimeout(timer);
    }
  }, [run, duration]);

  return (
    <ReactConfetti
      width={width}
      height={height}
      numberOfPieces={isActive ? 2000 : 0}
      recycle={true}
      colors={[
        "#ff0000",
        "#00ff00",
        "#0000ff",
        "#ffff00",
        "#ff00ff",
        "#00ffff",
        "#ff8c00",
        "#ff1493",
      ]}
      gravity={0.1}
      initialVelocityY={16}
      initialVelocityX={3}
    />
  );
};
