const CupIcon = (props: { fill?: string }) => {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.30675 1.96027C3.38881 1.87817 3.50013 1.83203 3.61621 1.83203H10.6748C10.9164 1.83203 11.1123 2.02791 11.1123 2.26953H10.6748C11.1123 2.26953 11.1123 2.26937 11.1123 2.26953V2.27164L11.1123 2.27603L11.1122 2.29149C11.1121 2.30471 11.1119 2.32372 11.1114 2.34814C11.1105 2.39695 11.1086 2.46742 11.1051 2.55646C11.0979 2.73442 11.0837 2.98714 11.0553 3.28985C10.9987 3.89318 10.885 4.7048 10.655 5.52229C10.4264 6.33454 10.074 7.1855 9.52093 7.84082C9.03887 8.4119 8.40122 8.83552 7.58176 8.94676V9.674H9.0356C9.22588 9.674 9.39429 9.79697 9.45227 9.97815L9.87257 11.2916H10.4966C10.7382 11.2916 10.9341 11.4875 10.9341 11.7291C10.9341 11.9707 10.7382 12.1666 10.4966 12.1666H9.56637C9.55751 12.1668 9.5487 12.1668 9.53995 12.1666H4.83511C4.82634 12.1668 4.81752 12.1668 4.80869 12.1666H3.87839C3.63677 12.1666 3.44089 11.9707 3.44089 11.7291C3.44089 11.4875 3.63677 11.2916 3.87839 11.2916H4.50246L4.92275 9.97815C4.98074 9.79697 5.14918 9.674 5.33944 9.674H6.70676V8.94594C5.89021 8.83348 5.25442 8.4105 4.77329 7.84088C4.21987 7.18568 3.86702 6.33477 3.63799 5.52252C3.4075 4.70505 3.29334 3.89344 3.23638 3.29011C3.2078 2.98741 3.1934 2.73469 3.18613 2.55673C3.1825 2.4677 3.18064 2.39723 3.1797 2.34842C3.17922 2.324 3.17898 2.30499 3.17885 2.29176L3.17873 2.27631L3.17872 2.27192L3.17871 2.27058C3.17871 2.27041 3.17871 2.26981 3.61621 2.26953L3.17871 2.26981C3.17864 2.15373 3.22469 2.04238 3.30675 1.96027ZM4.06951 2.70703C4.07749 2.84761 4.08948 3.01698 4.10751 3.20787C4.16151 3.77988 4.26878 4.5354 4.48016 5.28506C4.693 6.03995 5.00246 6.75617 5.44176 7.27627C5.87008 7.78336 6.41597 8.10052 7.14723 8.10052C7.87862 8.10052 8.42432 7.78342 8.85232 7.27639C9.29127 6.75634 9.60032 6.04013 9.81272 5.28529C10.0236 4.53565 10.1305 3.78014 10.1841 3.20813C10.202 3.01713 10.2139 2.84766 10.2218 2.70703H4.06951ZM7.1281 10.549H5.65879L5.42117 11.2916H8.95387L8.71628 10.549H7.16042C7.15505 10.5492 7.14968 10.5493 7.14426 10.5493C7.13889 10.5493 7.13347 10.5492 7.1281 10.549Z"
        fill={props.fill ?? "#FF9100"}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.29536 4.15506C5.53135 4.10321 5.7647 4.25248 5.81655 4.48848C5.93383 5.02228 6.11151 5.56361 6.37349 6.00061C6.49774 6.20781 6.43048 6.47655 6.22322 6.6008C6.01602 6.72505 5.7473 6.65774 5.62305 6.45054C5.2936 5.90104 5.08985 5.25835 4.96195 4.67626C4.91009 4.44027 5.05936 4.20692 5.29536 4.15506Z"
        fill={props.fill ?? "#FF9100"}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.47473 3.24834C1.49956 3.02698 1.68675 2.85962 1.9095 2.85962H3.67756C3.91918 2.85962 4.11506 3.0555 4.11506 3.29712C4.11506 3.53875 3.91918 3.73462 3.67756 3.73462H2.33525C2.34096 3.92932 2.36195 4.17918 2.41818 4.45113C2.54738 5.07596 2.85192 5.77332 3.53784 6.2249C3.73965 6.35773 3.79555 6.62904 3.66269 6.83087C3.52982 7.0327 3.25851 7.08859 3.0567 6.9557C2.10624 6.33002 1.7169 5.38076 1.5613 4.62832C1.48306 4.24991 1.46122 3.91009 1.45874 3.66459C1.45751 3.5414 1.46113 3.44074 1.46523 3.36935C1.46728 3.33362 1.46946 3.30513 1.47122 3.28471C1.4721 3.27449 1.47288 3.26628 1.47348 3.26019L1.47426 3.25265L1.47453 3.25009L1.47464 3.24911L1.47473 3.24834C1.47475 3.24815 1.47473 3.24834 1.9095 3.29712L1.47473 3.24834Z"
        fill={props.fill ?? "#FF9100"}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.1763 3.29712C10.1763 3.0555 10.3722 2.85962 10.6138 2.85962H12.3819C12.6046 2.85962 12.7918 3.02698 12.8166 3.24834L12.3819 3.29712C12.8166 3.24834 12.8166 3.24816 12.8166 3.24834L12.8168 3.25009L12.8171 3.25265L12.8178 3.26019C12.8185 3.26628 12.8192 3.27449 12.8201 3.28471C12.8219 3.30513 12.824 3.33362 12.8261 3.36935C12.8302 3.44074 12.8338 3.5414 12.8326 3.66459C12.8301 3.91009 12.8083 4.24991 12.73 4.62832C12.5744 5.38076 12.1851 6.33002 11.2346 6.9557C11.0328 7.08859 10.7615 7.0327 10.6286 6.83087C10.4958 6.62904 10.5517 6.35773 10.7535 6.2249C11.4394 5.77332 11.744 5.07596 11.8731 4.45113C11.9294 4.17918 11.9504 3.92932 11.9561 3.73462H10.6138C10.3722 3.73462 10.1763 3.53875 10.1763 3.29712Z"
        fill={props.fill ?? "#FF9100"}
      />
    </svg>
  );
};

export default CupIcon;
