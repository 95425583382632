import { useMutation } from "@tanstack/react-query";
import { useAPI } from "../useApi";
import { useErrorHandler } from "../useErrorHandler";

const useLaunchMission = () => {
  const { patch } = useAPI();
  const { handleApiError } = useErrorHandler();

  return useMutation({
    mutationFn: async (_id: string) => {
      return patch(`/api/mission/${_id}?isLaunch=true`, { launched: true });
    },

    onError: (error) => {
      handleApiError(error);
    },
  });
};

export default useLaunchMission;
