import CloseIcon from "../../assets/icons/close";

export interface ModalHeaderTypes {
  title?: string;
  onClose?: () => void;
}

const ModalHeader = ({ title = "", onClose }: ModalHeaderTypes) => {
  return (
    <div className="flex justify-between items-center pb-4">
      <h3 className="title-lg-medium">{title}</h3>
      {onClose ? (
        <CloseIcon className="cursor-pointer" onClick={onClose} />
      ) : (
        ""
      )}
    </div>
  );
};

export default ModalHeader;
