import React from "react";

interface LoaderProps {
  size?: "sm" | "md" | "lg";
  color?: string;
  fullScreen?: boolean;
  className?: string;
}

export const Loader: React.FC<LoaderProps> = ({
  size = "lg",
  color = "border-primary-500",
  fullScreen = false,
  className = "",
}) => {
  const sizeClasses = {
    sm: "w-4 h-4 border-2",
    md: "w-8 h-8 border-3",
    lg: "w-12 h-12 border-4",
  };

  if (fullScreen) {
    return (
      <div className={`${className} flex justify-center items-center h-screen`}>
        <div
          className={`
        ${sizeClasses[size]}
        border-t-transparent
        ${color}
        rounded-full
        animate-spin
      `}
        />
      </div>
    );
  }

  return (
    <div
      className={`
    ${sizeClasses[size]}
    border-t-transparent
    ${color}
    rounded-full
    animate-spin
  `}
    />
  );
};
