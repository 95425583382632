import { toast } from "react-hot-toast";
import { useScreenSize } from "./useScreenSize";
import { useCallback, useMemo } from "react";

export const showErrorToast = (
  error: string,
  position:
    | "top-right"
    | "bottom-right"
    | "top-left"
    | "top-center"
    | "bottom-left" = "bottom-right",
  duration = 1500,
) => {
  toast.error(error, {
    style: {
      background: "#FF4444",
      color: "#FFFFFF",
      padding: "16px",
      borderRadius: "8px",
      width: "300px",
    },
    icon: "⚠️",
    duration: duration,
    position: position,
  });
};

export const useErrorHandler = () => {
  const { isMobile } = useScreenSize();

  const showErrorMessage = useCallback(
    (error: string, duration = 1500) => {
      showErrorToast(error, isMobile ? "top-center" : "bottom-left", duration);
    },
    [isMobile],
  );

  const handleApiError = useCallback(
    (error: any, duration = 1500) => {
      if (error.response?.data?.message) {
        showErrorMessage(error.response.data.message, duration);
      } else {
        showErrorMessage(error.message, duration);
      }
    },
    [showErrorMessage],
  );

  return useMemo(
    () => ({ handleApiError, showErrorMessage }),
    [handleApiError, showErrorMessage],
  );
};
