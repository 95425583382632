import EditIcon from "../../assets/icons/edit";

const EditButton = ({ text, classNames, onClick, id }: any) => (
  <div
    id={id}
    className={`flex flex-row h-full cursor-pointer ${classNames}`}
    onClick={onClick}
  >
    <div className="flex border items-center border-solid border-surface-250 px-4 py-2 gap-2.5 rounded-xl bg-surface-50">
      <EditIcon />
      <p className="body-lg-medium text-surface-900">{text}</p>
    </div>
  </div>
);

export default EditButton;
