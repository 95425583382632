import { useRef, useState, useCallback, useEffect } from "react";

export const useHorizontalScrollListener = (scrollAmount?: number) => {
  const containerRef = useRef<HTMLElement>(null);
  const [refInitialised, setRefInitialised] = useState(false);

  const [canScrollLeft, setCanScrollLeft] = useState(false);
  const [canScrollRight, setCanScrollRight] = useState(false);

  const handleScroll = useCallback(() => {
    if (!containerRef.current) return;

    const { scrollLeft, scrollWidth, clientWidth } = containerRef.current;
    setCanScrollLeft(scrollLeft > 0);
    setCanScrollRight(scrollLeft < scrollWidth - clientWidth);
  }, []);

  useEffect(() => {
    if (!refInitialised) return;

    const container = containerRef.current;
    if (!container) return;

    container.addEventListener("scroll", handleScroll);
    return () => container.removeEventListener("scroll", handleScroll);
  }, [refInitialised, handleScroll]);

  const registerRef = useCallback((ref: HTMLElement | null) => {
    if (!ref) return;

    (containerRef as React.MutableRefObject<HTMLElement>).current = ref;
    setRefInitialised(true);
    setCanScrollLeft(ref.scrollLeft > 0);
    setCanScrollRight(ref.scrollLeft < ref.scrollWidth - ref.clientWidth);
  }, []);

  const scroll = useCallback(
    (direction: "left" | "right") => {
      if (!containerRef.current) return;
      const value = scrollAmount ?? 100;
      const left = direction === "left" ? -value : value;
      containerRef.current.scrollBy({
        left,
        behavior: "smooth",
      });
    },
    [scrollAmount],
  );

  return { registerRef, canScrollLeft, canScrollRight, scroll };
};
