interface MissionsAndMembersInfoProps {
  numberOfTasks?: number;
  numberOfMissions?: number;
  numberOfMembers?: number;
}

const MissionsAndMembersInfo = ({
  numberOfTasks,
  numberOfMissions,
  numberOfMembers,
}: MissionsAndMembersInfoProps) => {
  return (
    <div className="flex items-center border border-solid border-surface-250 rounded-[10px] bg-surface-150 w-full">
      <div className="w-1/2 flex flex-col justify-center h-[89px] border-r border-solid border-surface-250">
        <div className="flex justify-center body-lg text-surface-700">
          Tasks
        </div>
        <div className="flex justify-center body-lg-bold text-surface-900">
          {numberOfTasks ? numberOfTasks : 0}
        </div>
      </div>
      <div className="w-1/2 flex flex-col justify-center h-[89px] border-r border-solid border-surface-250">
        <div className="flex justify-center body-lg text-surface-700">
          Missions
        </div>
        <div className="flex justify-center body-lg-bold text-surface-900">
          {numberOfMissions ? numberOfMissions : 0}
        </div>
      </div>
      <div className="w-1/2 flex flex-col justify-center h-[89px]">
        <div className="flex justify-center body-lg text-surface-700">
          Members
        </div>
        <div className="flex justify-center body-lg-bold text-surface-900">
          {numberOfMembers ? numberOfMembers : 0}
        </div>
      </div>
    </div>
  );
};

export default MissionsAndMembersInfo;
