import {
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { User } from "../types";
import { useLocation } from "react-router-dom";
import { API_URL, LOCAL_STORAGE_TOKEN_KEY } from "../constants";
import axios from "axios";
import Clarity from "@microsoft/clarity";

export const AuthContext = createContext<{
  user: User | null;
  signIn: (user: User) => void;
  signOut: () => void;
  updateUserContext: (updatedUser: Partial<User>) => void;
  unverifiedUserId: string | null;
  setUnverifiedUserId: (unverifiedUserId: string | null) => void;
  isAdminMode: boolean;
  providedToken: string | null;
  isValidating: boolean;
} | null>(null);

type AuthState = {
  user: User | null;
  unverifiedUserId: string | null;
  isValidating: boolean;
};

export const AuthProvider = ({ children }: { children: React.ReactNode }) => {
  const loc = useLocation();
  const [providedToken] = useState(() => {
    const searchParams = new URLSearchParams(loc.search);
    const token = searchParams.get("token");
    return token;
  });
  const [{ user, unverifiedUserId, isValidating }, setState] =
    useState<AuthState>(() => ({
      user: null,
      unverifiedUserId: null,
      isValidating: !!(
        providedToken ?? localStorage.getItem(LOCAL_STORAGE_TOKEN_KEY)
      ),
    }));

  const updateUserContext = (updatedUser: any) => {
    setState((prev) => ({ ...prev, user: { ...user, ...updatedUser } }));
  };

  useEffect(() => {
    const checkIfTokenIsValid = async () => {
      const tokenToUse =
        providedToken ?? localStorage.getItem(LOCAL_STORAGE_TOKEN_KEY);
      if (!tokenToUse) {
        return;
      }

      setState((prev) => ({ ...prev, isValidating: true }));

      try {
        const user = await axios
          .get<User>(API_URL + "/api/user/current", {
            headers: {
              Authorization: `Bearer ${tokenToUse}`,
            },
          })
          .then((e) => e.data);
        setState((prev) => ({ ...prev, user: { ...user, token: tokenToUse } }));
      } catch (error: any) {
        localStorage.removeItem(LOCAL_STORAGE_TOKEN_KEY);
        console.error("Error validating token:", error);
      } finally {
        setState((prev) => ({ ...prev, isValidating: false }));
      }
    };

    checkIfTokenIsValid();
  }, [providedToken]);

  const signIn = useCallback(
    (user: User) => {
      Clarity.identify(user._id);
      setState((prev) => ({ ...prev, user, unverifiedUserId: null }));
      if (!providedToken)
        localStorage.setItem(LOCAL_STORAGE_TOKEN_KEY, user.token);
    },
    [providedToken],
  );

  const signOut = useCallback(() => {
    setState((prev) => ({ ...prev, user: null }));
    if (!providedToken) localStorage.removeItem(LOCAL_STORAGE_TOKEN_KEY);
  }, [providedToken]);

  const value = useMemo(
    () => ({
      user,
      signIn,
      signOut,
      unverifiedUserId,
      updateUserContext,
      setUnverifiedUserId: (unverifiedUserId: string | null) =>
        setState((prev) => ({ ...prev, unverifiedUserId })),
      isAdminMode: !!providedToken,
      providedToken,
      isValidating,
    }),
    [isValidating, providedToken, signIn, signOut, unverifiedUserId, user],
  );

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
