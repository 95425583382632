const TransactionsIcon = (props) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M17.1998 13.374V6.62631C17.1998 4.26783 15.5348 2.7998 13.1787 2.7998H6.82091C4.46476 2.7998 2.7998 4.26783 2.7998 6.62709V13.374C2.7998 15.7325 4.46476 17.1998 6.82091 17.1998H13.1787C15.5348 17.1998 17.1998 15.7256 17.1998 13.374Z"
      stroke={props.color || "#202327"}
      strokeWidth="1.44"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.64648 8.9248H8.82513"
      stroke={props.color || "#202327"}
      strokeWidth="1.44"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.64648 6.1748H12.4853"
      stroke={props.color || "#202327"}
      strokeWidth="1.44"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11.6279 9.68663L13.7698 9.68311L13.773 11.8251"
      stroke={props.color || "#202327"}
      strokeWidth="1.44"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13.7721 9.67871L9.96664 13.6479L8.1792 11.8659L6.22656 13.8249"
      stroke={props.color || "#202327"}
      strokeWidth="1.44"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default TransactionsIcon;
