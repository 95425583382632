const CouponIcon = (props) => (
  <svg
    width={18}
    height={18}
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.0744 10.9472L9.8922 11.1294C9.5532 11.4684 9.36645 11.6552 9.11745 11.7782C8.88645 11.8914 8.6337 11.9477 8.38095 11.9477C8.12895 11.9477 7.87695 11.8914 7.6482 11.7789C7.39622 11.6552 7.20947 11.4684 6.87197 11.1294C6.53222 10.7912 6.34472 10.6044 6.22172 10.3532C5.99672 9.89344 5.99672 9.34444 6.22097 8.88469C6.34547 8.63269 6.53222 8.44669 6.87272 8.10769L7.09172 7.88794L5.03072 5.82617L3.11672 7.74019C2.60522 8.25169 2.34797 8.50969 2.18147 8.81344C1.77272 9.55444 1.77272 10.4387 2.18222 11.1812C2.35097 11.4857 2.60897 11.7444 3.14747 12.2807C3.25697 12.3902 3.31172 12.4449 3.37697 12.4869C3.58322 12.6242 3.68822 12.6489 3.92597 12.6227C3.97322 12.6182 4.11647 12.5807 4.38872 12.4967L4.47122 12.4712C4.77497 12.3759 5.09297 12.4554 5.33522 12.6969C5.53922 12.9167 5.61122 13.2189 5.52797 13.5062C5.40647 13.9194 5.38247 14.0072 5.37422 14.0837C5.35097 14.3319 5.37422 14.4234 5.51297 14.6274C5.54297 14.6724 5.60147 14.7377 5.74922 14.8869C6.25997 15.3969 6.51647 15.6519 6.81947 15.8207C7.18997 16.0254 7.59645 16.1274 8.00295 16.1274C8.40945 16.1274 8.8167 16.0254 9.1872 15.8207C9.49095 15.6527 9.74745 15.3969 10.2627 14.8817L12.1362 13.0089L10.0744 10.9472Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.3369 7.67104C10.1044 7.43701 9.94093 7.27276 9.85468 7.23076C9.78193 7.19401 9.69943 7.17676 9.61843 7.17676C9.53593 7.17676 9.45268 7.19476 9.37768 7.23151C9.29518 7.27201 9.13093 7.43701 8.90293 7.66579L7.66543 8.90404C7.42769 9.14104 7.27094 9.29779 7.22969 9.38104C7.15769 9.52954 7.15769 9.70804 7.23044 9.85729C7.27094 9.94054 7.42769 10.0965 7.66468 10.3335L7.66618 10.335C7.90243 10.5713 8.05843 10.728 8.14243 10.7693C8.29168 10.8428 8.47168 10.842 8.61943 10.7685C8.70118 10.728 8.85793 10.5713 9.09568 10.3343L10.3324 9.09604C10.5657 8.86354 10.7277 8.70154 10.7667 8.62129C10.8402 8.47054 10.8402 8.29204 10.7667 8.14279C10.7277 8.06029 10.5717 7.90504 10.3369 7.67104Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.8195 6.82029C15.65 6.51429 15.392 6.25554 14.852 5.71554C14.7357 5.60004 14.6712 5.54229 14.6232 5.51079C14.4177 5.37279 14.3127 5.34879 14.0757 5.37429C13.9955 5.38254 13.9017 5.41104 13.5245 5.52654C13.2207 5.62404 12.9027 5.54379 12.6777 5.31804C12.452 5.09229 12.3747 4.77654 12.4707 4.47054L12.5052 4.35954C12.59 4.08354 12.6162 4.00029 12.623 3.92904C12.65 3.68454 12.6252 3.58104 12.4887 3.37554C12.458 3.32829 12.3987 3.26379 12.2517 3.11529C11.7387 2.60229 11.4807 2.34504 11.177 2.17779C10.4367 1.77054 9.55395 1.76979 8.8152 2.17779C8.5122 2.34354 8.25795 2.59704 7.7337 3.11979L5.82422 5.02929L7.88595 7.09104L8.10645 6.86979C8.44395 6.53154 8.62995 6.34554 8.88045 6.22104C9.3447 5.99379 9.89295 5.99529 10.3512 6.21954C10.6055 6.34554 10.8027 6.54354 11.1282 6.87054C11.4687 7.21104 11.6547 7.39704 11.7777 7.64827C12.002 8.10577 12.002 8.65402 11.777 9.11452C11.6555 9.36352 11.4702 9.54802 11.1357 9.88252L10.868 10.1503L12.9297 12.212L14.8827 10.259C15.3957 9.74527 15.6522 9.48802 15.8202 9.18352C16.2267 8.44477 16.2267 7.56127 15.8195 6.82029Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.0744 10.9472L9.8922 11.1294C9.5532 11.4684 9.36645 11.6552 9.11745 11.7782C8.88645 11.8914 8.6337 11.9477 8.38095 11.9477C8.12895 11.9477 7.87695 11.8914 7.6482 11.7789C7.39622 11.6552 7.20947 11.4684 6.87197 11.1294C6.53222 10.7912 6.34472 10.6044 6.22172 10.3532C5.99672 9.89344 5.99672 9.34444 6.22097 8.88469C6.34547 8.63269 6.53222 8.44669 6.87272 8.10769L7.09172 7.88794L5.03072 5.82617L3.11672 7.74019C2.60522 8.25169 2.34797 8.50969 2.18147 8.81344C1.77272 9.55444 1.77272 10.4387 2.18222 11.1812C2.35097 11.4857 2.60897 11.7444 3.14747 12.2807C3.25697 12.3902 3.31172 12.4449 3.37697 12.4869C3.58322 12.6242 3.68822 12.6489 3.92597 12.6227C3.97322 12.6182 4.11647 12.5807 4.38872 12.4967L4.47122 12.4712C4.77497 12.3759 5.09297 12.4554 5.33522 12.6969C5.53922 12.9167 5.61122 13.2189 5.52797 13.5062C5.40647 13.9194 5.38247 14.0072 5.37422 14.0837C5.35097 14.3319 5.37422 14.4234 5.51297 14.6274C5.54297 14.6724 5.60147 14.7377 5.74922 14.8869C6.25997 15.3969 6.51647 15.6519 6.81947 15.8207C7.18997 16.0254 7.59645 16.1274 8.00295 16.1274C8.40945 16.1274 8.8167 16.0254 9.1872 15.8207C9.49095 15.6527 9.74745 15.3969 10.2627 14.8817L12.1362 13.0089L10.0744 10.9472Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.3369 7.67104C10.1044 7.43701 9.94093 7.27276 9.85468 7.23076C9.78193 7.19401 9.69943 7.17676 9.61843 7.17676C9.53593 7.17676 9.45268 7.19476 9.37768 7.23151C9.29518 7.27201 9.13093 7.43701 8.90293 7.66579L7.66543 8.90404C7.42769 9.14104 7.27094 9.29779 7.22969 9.38104C7.15769 9.52954 7.15769 9.70804 7.23044 9.85729C7.27094 9.94054 7.42769 10.0965 7.66468 10.3335L7.66618 10.335C7.90243 10.5713 8.05843 10.728 8.14243 10.7693C8.29168 10.8428 8.47168 10.842 8.61943 10.7685C8.70118 10.728 8.85793 10.5713 9.09568 10.3343L10.3324 9.09604C10.5657 8.86354 10.7277 8.70154 10.7667 8.62129C10.8402 8.47054 10.8402 8.29204 10.7667 8.14279C10.7277 8.06029 10.5717 7.90504 10.3369 7.67104Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.8195 6.82029C15.65 6.51429 15.392 6.25554 14.852 5.71554C14.7357 5.60004 14.6712 5.54229 14.6232 5.51079C14.4177 5.37279 14.3127 5.34879 14.0757 5.37429C13.9955 5.38254 13.9017 5.41104 13.5245 5.52654C13.2207 5.62404 12.9027 5.54379 12.6777 5.31804C12.452 5.09229 12.3747 4.77654 12.4707 4.47054L12.5052 4.35954C12.59 4.08354 12.6162 4.00029 12.623 3.92904C12.65 3.68454 12.6252 3.58104 12.4887 3.37554C12.458 3.32829 12.3987 3.26379 12.2517 3.11529C11.7387 2.60229 11.4807 2.34504 11.177 2.17779C10.4367 1.77054 9.55395 1.76979 8.8152 2.17779C8.5122 2.34354 8.25795 2.59704 7.7337 3.11979L5.82422 5.02929L7.88595 7.09104L8.10645 6.86979C8.44395 6.53154 8.62995 6.34554 8.88045 6.22104C9.3447 5.99379 9.89295 5.99529 10.3512 6.21954C10.6055 6.34554 10.8027 6.54354 11.1282 6.87054C11.4687 7.21104 11.6547 7.39704 11.7777 7.64827C12.002 8.10577 12.002 8.65402 11.777 9.11452C11.6555 9.36352 11.4702 9.54802 11.1357 9.88252L10.868 10.1503L12.9297 12.212L14.8827 10.259C15.3957 9.74527 15.6522 9.48802 15.8202 9.18352C16.2267 8.44477 16.2267 7.56127 15.8195 6.82029Z"
      fill="white"
    />
  </svg>
);
export default CouponIcon;
