import { useState } from "react";
import Dropzone from "react-dropzone";
import classNames from "classnames";
import { Controller } from "react-hook-form";
import { useFileUpload } from "../../hooks/useFileUpload";
import QuestionMarkIcon from "../../assets/icons/questionMark";
import BoonTooltip from "../Tooltip";
import Button, { ButtonType } from "../Button";
import EmojiPicker from "emoji-picker-react";
import SmileIcon from "../../assets/icons/smile";
import Popover from "../Popover";

export interface UploadFileProps {
  label?: string;
  name: string;
  validation?: any;
  errorMessage?: string;
  setValue?: (name: string, value: any) => void;
  control: any;
  onChange?: (input: any) => void;
  description?: string;
  defaultValue?: string;
  tooltipContent?: string;
  emojiable?: boolean;
  id?: string;
}

const UploadFile = ({
  label,
  name,
  validation,
  setValue = () => ({}),
  control,
  errorMessage,
  description,
  onChange,
  defaultValue,
  tooltipContent,
  emojiable = false,
  id,
}: UploadFileProps) => {
  const [file, setFile] = useState<File | null>(null);
  const [emoji, setEmoji] = useState<string | null>(null);
  const { uploadFile, isUploading } = useFileUpload();

  if (defaultValue) setValue(name, defaultValue);

  const inputClasses = classNames(
    "w-full p-4 border-2 rounded-2xl border-dotted cursor-pointer",
    {
      "border-red-500 opacity-100": errorMessage,
      "border-surface-250 opacity-80": !errorMessage,
    },
  );

  const errorClasses = classNames(
    "text-red-500 text-sm font-medium mb-3 transition-opacity duration-300 ease-in-out",
    {
      "opacity-100": errorMessage,
      "opacity-0": !errorMessage,
    },
    "pl-2",
  );

  const handleUpload = async (acceptedFiles: File[]) => {
    if (acceptedFiles.length > 0) {
      const file = acceptedFiles[0];
      setFile(file);
      onChange?.({ target: { name, files: acceptedFiles } });

      const { success, fileUrl } = await uploadFile(file);
      if (success) {
        console.log("File uploaded successfully.");
        setValue(name, fileUrl);
      } else {
        console.error("Failed to upload file.");
      }
    }
  };

  return (
    <div className="w-full flex flex-col gap-2">
      <div className="flex gap-2 w-full justify-between items-center">
        <div className="flex items-center w-full gap-2">
          {label && (
            <p className={`body-lg font-medium text-surface-700`}>
              {label} {validation ? "*" : ""}
            </p>
          )}
          {tooltipContent && (
            <>
              <a id={id}>
                <QuestionMarkIcon />
              </a>
              <BoonTooltip id={id} content={tooltipContent} />
            </>
          )}
        </div>
        {emojiable ? (
          <Popover
            className="flex items-center p-0 m-0 transition-all duration-500"
            closeOnClick={true}
            content={
              <EmojiPicker
                onEmojiClick={(e) => {
                  if (file) return;
                  if (emoji === e.imageUrl) return setEmoji(null);

                  setEmoji(e.imageUrl);
                  setValue("rewardEmoji", e.imageUrl);
                }}
              />
            }
          >
            <Button
              type="button"
              category={ButtonType.SECONDARY}
              className="text-nowrap gap-2 w-44 flex items-center"
            >
              {emoji ? (
                <img src={emoji as string} className="w-5 " alt="emoji" />
              ) : (
                <SmileIcon width={25} height={25} />
              )}
              <p>Choose Emoji</p>
            </Button>
          </Popover>
        ) : (
          ""
        )}
      </div>
      <Controller
        control={control}
        name={name}
        rules={{
          required: { value: validation, message: validation?.required },
        }}
        defaultValue={file}
        render={() => (
          <Dropzone onDrop={handleUpload}>
            {({ getRootProps, getInputProps }) => (
              <div {...getRootProps({ className: inputClasses })}>
                <input {...getInputProps()} style={{ display: "none" }} />
                <div className="text-center">
                  <h3 className="text-primary-250 cursor-pointer">
                    <span className="text-primary-500 ">Click to upload</span>{" "}
                    or drag and drop
                  </h3>
                  <p className="text-xs">{file ? file.name : description}</p>
                </div>
                {isUploading && (
                  <p className="text-center text-primary-500">Uploading...</p>
                )}
              </div>
            )}
          </Dropzone>
        )}
      />

      {errorMessage && <p className={errorClasses}>{errorMessage}</p>}
    </div>
  );
};

export default UploadFile;
