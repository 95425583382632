import PlusIcon from "../../../assets/icons/plus";
import { Section } from "../../../hooks/useCommunity";
import EmptyCommunityBackground from "../../../assets/images/empty-community-bg.svg";
import Button from "../../Button";

const EmptyCommunityMissionManager = ({
  handleAddMission,
}: {
  handleAddMission: (data: Section | undefined) => void;
}) => {
  return (
    <div className="flex flex-col gap-6 items-center">
      <div className="flex flex-col items-center relative w-full">
        <img
          src={EmptyCommunityBackground}
          alt="empty missions"
          className="absolute top-0"
        />
        <div className="absolute inset-0 bg-gradient-to-b from-white/0 to-white"></div>
        <div className="flex flex-col items-center mt-17">
          <p className="body-xl-bold text-surface-900 z-10">Need Copy</p>
          <p className="body-md-regular text-surface-700 max-w-[316px] text-center z-10">
            Join the Stabull Road-to-IDO Campaign on Magic Square!
          </p>
        </div>
      </div>
      <Button action={() => handleAddMission(undefined)}>
        <div className="flex items-center align-middle">
          <PlusIcon color="#FFFFFF" />
          <p>Add New Mission</p>
        </div>
      </Button>
    </div>
  );
};

export default EmptyCommunityMissionManager;
