const TrashIcon = (props) => (
  <svg
    width={18}
    height={18}
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.5385 6.54002C14.8482 6.56552 15.0792 6.83627 15.0544 7.14602C15.0499 7.19702 14.6435 12.2303 14.4095 14.3415C14.264 15.6518 13.3984 16.449 12.0919 16.473C11.0922 16.4903 10.1277 16.5 9.18495 16.5C8.1687 16.5 7.17795 16.4888 6.1977 16.4685C4.9437 16.4438 4.07595 15.6308 3.9342 14.3468C3.69795 12.2168 3.2937 7.19627 3.28995 7.14602C3.26445 6.83627 3.49545 6.56477 3.8052 6.54002C4.11045 6.53177 4.38645 6.74627 4.4112 7.05527C4.41359 7.08782 4.57886 9.13804 4.75895 11.1666L4.79512 11.5714C4.88582 12.5796 4.97777 13.5485 5.05245 14.223C5.1327 14.9528 5.52645 15.3293 6.22095 15.3435C8.09595 15.3833 10.0092 15.3855 12.0717 15.348C12.8097 15.3338 13.2087 14.9648 13.2912 14.2178C13.5237 12.1223 13.9287 7.10627 13.9332 7.05527C13.9579 6.74627 14.2317 6.53027 14.5385 6.54002ZM10.7591 1.50024C11.4475 1.50024 12.0528 1.96449 12.2305 2.62974L12.4211 3.57549C12.4826 3.88552 12.7547 4.11194 13.0697 4.11691L15.531 4.11699C15.8415 4.11699 16.0935 4.36899 16.0935 4.67949C16.0935 4.98999 15.8415 5.24199 15.531 5.24199L13.0917 5.24188C13.0879 5.24196 13.0841 5.24199 13.0803 5.24199L13.062 5.24124L5.28121 5.2419C5.27517 5.24196 5.26911 5.24199 5.26305 5.24199L5.2515 5.24124L2.8125 5.24199C2.502 5.24199 2.25 4.98999 2.25 4.67949C2.25 4.36899 2.502 4.11699 2.8125 4.11699L5.27325 4.11624L5.34902 4.11145C5.63123 4.07483 5.86578 3.86049 5.92305 3.57549L6.1053 2.66349C6.29055 1.96449 6.8958 1.50024 7.5843 1.50024H10.7591ZM10.7591 2.62524H7.5843C7.4043 2.62524 7.24605 2.74599 7.2003 2.91924L7.02555 3.79674C7.00334 3.90789 6.971 4.015 6.92961 4.1172H11.414C11.3725 4.015 11.3401 3.90789 11.3178 3.79674L11.1355 2.88474C11.0973 2.74599 10.939 2.62524 10.7591 2.62524Z"
      fill={props?.color || "#4F575E"}
    />
  </svg>
);
export default TrashIcon;
