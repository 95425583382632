import { useAPI } from "./useApi";
import { useMutation } from "@tanstack/react-query";
import { User } from "../types";
import { AxiosError } from "axios";
import { useAuthContext } from "./useAuthContext";
import { useErrorHandler } from "./useErrorHandler";

export interface SignupData {
  name: string;
  surname: string;
  email: string;
  password: string;
}

export interface LoginData {
  email: string;
  password: string;
}

export const useSignIn = () => {
  const { handleApiError } = useErrorHandler();
  const { signIn } = useAuthContext();
  const { post } = useAPI();
  return useMutation<User, AxiosError, LoginData>({
    mutationFn: async (loginData: LoginData) =>
      post("/api/user/login", loginData),
    onSuccess: (data) => {
      signIn(data);
    },
    onError: (error) => {
      handleApiError(error);
    },
  });
};

export const useGoogleSignIn = () => {
  const { showErrorMessage } = useErrorHandler();
  const { signIn } = useAuthContext();
  const { post } = useAPI();
  return useMutation<User, AxiosError, string>({
    mutationFn: async (token: string) =>
      post("/api/user/google-oauth", { token }),
    onSuccess: (data) => {
      signIn(data);
    },
    onError: (error: any) => {
      showErrorMessage(error.response?.data?.message || "Something went wrong");
    },
  });
};

export const useSignUp = () => {
  const { setUnverifiedUserId } = useAuthContext();
  const { post } = useAPI();
  return useMutation<User, AxiosError, SignupData>({
    mutationFn: async (registerData: SignupData) =>
      post("/api/user/register", registerData),
    onSuccess: (data) => {
      setUnverifiedUserId(data["_id"]);
    },
  });
};

export const useVerifyOtp = () => {
  const { signIn, unverifiedUserId } = useAuthContext();
  const { handleApiError } = useErrorHandler();
  const { post } = useAPI();
  return useMutation({
    mutationFn: async ({ otp }: { otp: string }) =>
      post(`/api/user/${unverifiedUserId}/verify`, { otp }),
    onSuccess: (data) => {
      signIn(data);
    },
    onError: (error) => {
      handleApiError(error);
    },
  });
};
