import Button from "../Button";
import cn from "classnames";

export const SegmentInfo = ({
  segment,
  className,
  onLearnMoreClick,
}: {
  segment: "followers" | "creators";
  className?: string;
  onLearnMoreClick: () => void;
}) => {
  return (
    <div
      className={cn(
        "flex flex-col items-center justify-between font-space-grotesk mmd:px-4 mmd:w-full",
        className,
      )}
    >
      <div
        className={cn(
          "flex flex-row items-center justify-center px-4 py-2 rounded-lg mb-8 mmd:mb-4",
        )}
      >
        <span
          className={cn("text-body-lg font-medium underline", {
            "text-secondary-100": segment === "followers",
            "text-danger-250": segment === "creators",
          })}
        >
          {segment === "followers" ? "For Followers" : "For Creators"}
        </span>
      </div>
      <span className="text-center">
        {segment === "followers" && (
          <>
            <span className="text-heading-md mmd:text-title-xl font-bold">{`Complete Missions →`}</span>
            <span className="flex flex-row items-center gap-2 mt-1 justify-center">
              <span
                className={cn("text-heading-md mmd:text-title-xl font-bold", {
                  "text-secondary-100": segment === "followers",
                })}
              >
                Collect
              </span>
              <span className="text-heading-md mmd:text-title-xl font-bold">
                Rewards
              </span>
            </span>
          </>
        )}

        {segment === "creators" && (
          <>
            <span className="text-heading-md mmd:text-title-xl font-bold">{`Create Missions →`}</span>
            <span className="flex flex-row items-center gap-2 mt-1">
              <span
                className={cn("text-heading-md mmd:text-title-xl font-bold", {
                  "text-danger-250": segment === "creators",
                })}
              >
                Reward
              </span>
              <span className="text-heading-md mmd:text-title-xl font-bold">
                your
              </span>
              <span
                className={cn("text-heading-md mmd:text-title-xl font-bold", {
                  "text-danger-250": segment === "creators",
                })}
              >
                Followers
              </span>
            </span>
          </>
        )}
      </span>

      <Button
        className={cn("mt-12 px-11 py-4 mmd:mt-6", {
          "!bg-danger-250": segment === "creators",
          "!bg-secondary-100": segment === "followers",
        })}
        onClick={onLearnMoreClick}
      >
        Learn More
      </Button>
    </div>
  );
};
