import LineProgress from "../../ProgressBar/LineProgress";
import Button from "../../Button";
import ScoreCard from "../ScoreCard";
import { CommunityMissionCardProps } from "./OngoingMissionCard";
import { useNavigate } from "react-router-dom";

export const DashboardMissionCard = ({
  nextTaskName,
  missionName,
  tasksFinished,
  tasksTotal,
  completed = false,
  rewards,
  tasks,
  title,
  description,
  imageUrl,
  _id,
  onClick,
}: CommunityMissionCardProps) => {
  const navigate = useNavigate();
  const completedPercentage = `${(tasksFinished / tasksTotal) * 100}`.slice(
    0,
    2,
  );

  return (
    <div
      className="border border-solid border-surface-250 bg-surface-50 rounded-lg p-2 flex flex-col gap-4 max-w-[500px] mmd:min-w-full cursor-pointer hover:shadow-md transition-all duration-300"
      onClick={() => {
        if (onClick) {
          onClick();
        } else {
          navigate(`/mission/${_id}`);
        }
      }}
    >
      <img
        src={imageUrl}
        alt={missionName}
        className="w-full h-[128px] object-cover rounded-lg"
      />

      <div className="flex flex-col px-2 pb-2">
        <div className="flex flex-col gap-2">
          <div className="w-full flex items-center justify-between">
            <p className="text-body-xl font-space-grotesk text-surface-700 font-semibold mr-2">
              {missionName}
            </p>

            <Button onClick={() => navigate(`/mission/${_id}`)}>
              {completed ? "Redeem" : "Continue Mission"}
            </Button>
          </div>
          <div className="w-fit flex flex-wrap gap-4">
            {rewards?.map((reward) => <ScoreCard score={reward.name} />)}
          </div>
          {!completed && (
            <div className="flex flex-col gap-1">
              <LineProgress percentage={completedPercentage} />
              <div className="w-full flex justify-between">
                <p className="text-body-sm font-space-grotesk text-surface-500 font-regular">
                  Next up: {nextTaskName}
                </p>
                <p className="text-body-sm font-space-grotesk text-surface-500 font-regular">
                  {`${tasksFinished} / ${tasksTotal}`} ({completedPercentage}%)
                </p>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
