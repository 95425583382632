import React from "react";
import { Navigate } from "react-router-dom";
import { useAuthContext } from "../../hooks";

export interface RestrictedPageProps {
  children: React.ReactNode;
  allowedRoles?: string[];
}

const RestrictedPage: React.FC<RestrictedPageProps> = ({
  children,
  allowedRoles,
}) => {
  const { user, isValidating } = useAuthContext();

  if (!user && !isValidating) {
    return <Navigate to="/" />;
  }

  if (allowedRoles && user && !isValidating) {
    const isRestrict = allowedRoles.some((role) => role === user?.role);

    if (!isRestrict) {
      return <div>You do not have permission to access this page.</div>;
    }
  }

  return <>{children}</>;
};

export default RestrictedPage;
