import Button from "../Button";
import TaskProgress from "./TaskProgress";
import MissionsAndMembersInfo from "./MissionsAndMembersInfo";
import SocialMediaIcons from "./SocialMediaIcons";
import { useJoinCommunity } from "../../hooks/useCommunity";
import cn from "classnames";
import { useScreenSize } from "../../hooks";
import classNames from "classnames";
import { ShareIcon } from "../../assets/icons";
import RestrictedComponent from "../../pages/utils/RestrictedComponent";
import { Loader } from "../Loader";

export interface CommunityHeaderProps {
  numberOfTasks?: number;
  numberOfMembers?: number;
  currency?: string;
  title?: string;
  description?: string;
  isUserJoined?: boolean;
  numberOfMissions?: number;
  numberOfOngoingMissions?: number;
  numberOfMissionsDone?: number;
  _id: string;
  logoUrl?: string;
  socials?: Array<{ url: string; type: string }>;
}

const CommunityCardHeader = ({
  logoUrl,
  numberOfMissions,
  numberOfTasks,
  numberOfMembers,
  title,
  description,
  isUserJoined,
  numberOfMissionsDone = 0,
  numberOfOngoingMissions = 0,
  _id,
  socials,
}: CommunityHeaderProps) => {
  const { isMobile } = useScreenSize();

  return (
    <div
      className={cn("flex justify-center items-start w-full  mt-10 ", {
        "bg-white border border-solid border-surface-50 rounded-[5px] h-[373px]":
          !isMobile,
      })}
    >
      <div
        className={cn("flex mmd:flex-1", {
          " bg-community-header bg-no-repeat bg-centers bg-cover h-[200px] w-full px-16":
            !isMobile,
        })}
      >
        <div
          className={cn(
            "flex w-full mmd:flex-col border-[2px] border-solid border-surface-100 relative bg-red mmd:items-center",
            {
              "top-20 bg-white h-[400px] p-8 ": !isMobile,
            },
          )}
        >
          <div className="w-[330px] h-[330px] mmd:w-full mmd:h-auto mmd:min-h-[330px] border-solid border mmd:rounded-lg">
            <img
              src={logoUrl}
              alt="header"
              className="object-cover w-[330px] h-[330px] mmd:rounded-lg  mmd:w-full mmd:h-auto mmd:min-h-[330px]"
            />
          </div>
          <div
            className={classNames(
              "flex flex-col items-center w-full justify-between md:max-w-[80%]",
              {
                "ml-8": !isMobile,
              },
            )}
          >
            <div className="mmd:hidden flex w-full justify-between">
              <p className="heading-md-bold text-surface-900">{title}</p>
              <SocialMediaIcons socials={socials} />
            </div>

            {isMobile ? (
              <p className="heading-md-bold text-surface-900 mt-4 mb-6">
                {title}
              </p>
            ) : (
              <p className="body-lg text-surface-700 font-regular text-wrap break-words w-full overflow-auto">
                {description}
              </p>
            )}

            <div className="flex items-center w-full mmd:flex-col mmd:items-stretch">
              <div className="flex-1">
                <MissionsAndMembersInfo
                  numberOfMissions={numberOfMissions}
                  numberOfTasks={numberOfTasks}
                  numberOfMembers={numberOfMembers}
                />
              </div>

              {isMobile && (
                <div className="flex flex-col gap-4 w-full">
                  <p className="body-lg text-surface-700 font-regular text-wrap break-words w-full overflow-auto mt-4 text-center max-h-[100px]">
                    {description}
                  </p>

                  {!isUserJoined && (
                    <div className="w-full flex flex-row justify-between">
                      <JoinButton
                        _id={_id}
                        className="!h-12 w-full !ml-0 mr-4"
                      />
                      <button className="p-2 h-12 w-12 bg-surface-250 rounded-lg flex justify-center items-center">
                        <ShareIcon />
                      </button>
                    </div>
                  )}
                </div>
              )}

              {isMobile ? null : isUserJoined ? (
                <TaskProgress
                  numberOfMissions={numberOfMissions}
                  numberOfMissionsDone={numberOfMissionsDone}
                  numberOfOngoingMissions={numberOfOngoingMissions}
                />
              ) : (
                <JoinButton _id={_id} />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const JoinButton = ({
  _id,
  className,
}: {
  _id: string;
  className?: string;
}) => {
  const {
    mutate: joinCommunity,
    isPending: isJoinCommunityPending,
    isError: isJoinCommunityError,
    error: JoinCommunityError,
  } = useJoinCommunity({ _id });

  return (
    <>
      {isJoinCommunityPending ? (
        <div className="flex flex-1 justify-center items-center">
          <Loader size="md" />
        </div>
      ) : (
        <RestrictedComponent>
          <Button
            className={cn(className, "flex-1 h-[89px] ml-3 cursor-pointer")}
            onClick={joinCommunity}
          >
            <p>Join</p>
          </Button>
        </RestrictedComponent>
      )}
    </>
  );
};

export default CommunityCardHeader;
