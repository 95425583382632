import WrenchIcon from "../../../assets/icons/wrenchIcon";
import DeactivatedIcon from "../../../assets/icons/deactivated";
import WarningIcon from "../../../assets/icons/warningIcon";
import type { Mission } from "../../../hooks/useCommunity";

const MissionStatusTag = ({ mission }: { mission: Mission }) => {
  const launched = mission.launched;
  const deactivated = !mission.active;
  const oversubscribed = mission.oversubscribed;

  return (
    <div>
      <div className="flex gap-2">
        {!launched && (
          <div className="flex items-center gap-1 bg-[#FFDD00]/10 rounded-md p-2">
            <WrenchIcon />
            <span className="text-[#ECC207] text-sm font-semibold">
              UNLAUNCHED
            </span>
          </div>
        )}
        {deactivated && (
          <div className="flex items-center gap-1 bg-[#C92929]/10 rounded-md p-2">
            <DeactivatedIcon />
            <span className="text-[#C92929] text-sm font-semibold">
              DEACTIVATED
            </span>
          </div>
        )}
        {oversubscribed && (
          <div className="flex items-center gap-1 bg-[#FFDD00]/10 rounded-md p-2">
            <WarningIcon />
            <span className="text-[#ECC207] text-sm font-semibold">
              OVERSUBSCRIBED
            </span>
          </div>
        )}
      </div>
    </div>
  );
};

export default MissionStatusTag;
