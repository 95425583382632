import { useMutation } from "@tanstack/react-query";
import { useAPI } from "./useApi";
import { useAuthContext } from "./useAuthContext";
import { useErrorHandler } from "./useErrorHandler";

export const useResendOtp = () => {
  const { unverifiedUserId } = useAuthContext();
  const { post } = useAPI();
  const { handleApiError } = useErrorHandler();

  return useMutation({
    mutationFn: () => post(`/api/user/${unverifiedUserId}/resend-otp`, {}),
    onError: (error) => {
      handleApiError(error);
    },
  });
};
