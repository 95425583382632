import CreateMission from "../CreateMission";
import BoonModal from "../Modal";

const CreateMissionModal = ({ onClose }: { onClose: () => void }) => {
  return (
    <BoonModal
      onClose={onClose}
      style={{
        background: "transparent",
        border: "none",
      }}
      width="980px"
    >
      <CreateMission onClose={onClose} />
    </BoonModal>
  );
};

export default CreateMissionModal;
