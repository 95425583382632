import { AxiosError } from "axios";
import { useAPI } from "./useApi";
import { useMutation } from "@tanstack/react-query";

export const useImpersonateUser = () => {
  const { post } = useAPI();
  return useMutation<{ token: string }, AxiosError, string>({
    mutationFn: async (targetUserId: string) =>
      post("/api/user/impersonate", { targetUserId }),
  });
};
