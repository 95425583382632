import BoonModal from "../Modal";
import Button, { ButtonType } from "../Button";
import useTwitterOauth from "../../hooks/useTwitterOauth";
import { TwitterIcon } from "../../assets/icons";

export const TwitterConnectModal = ({ onClose }: { onClose: () => void }) => {
  const { mutate: twitterOauth, isPending: isTwitterOauthLoading } =
    useTwitterOauth({
      onSuccess: (data) => {
        window.location.href = data.authUrl;
      },
    });

  return (
    <BoonModal onClose={onClose}>
      <div className="flex flex-col gap-10 justify-center items-center">
        <div className="flex flex-col gap-3 text-center">
          <h3 className="text-title-lg">Connect to twitter is required</h3>
          <p className="text-body-lg">
            Connecting your Twitter account is necessary to proceed
          </p>
        </div>
        <div className="flex flex-col gap-5">
          <Button
            category={ButtonType.SECONDARY}
            onClick={() => {
              twitterOauth();
            }}
          >
            <div className="flex items-center gap-2 p-2">
              <TwitterIcon /> Connect To Twitter(X)
            </div>
          </Button>
        </div>
      </div>
    </BoonModal>
  );
};
