import debounce from "lodash/debounce";
import DropDownFilter from "./DropdownFilter";
import TagIcon from "../../assets/icons/tagIcon";
import CupIcon from "../../assets/icons/cupIcon";
import SearchFilter from "./SearchFilter";
import { missionTags, rewardTypes } from "../../constants";
import SettingsIcon from "../../assets/icons/settings";
import { useScreenSize } from "../../hooks";

const FilterMissions = ({
  filters,
  setFilters,
}: {
  filters: {
    tags: string;
    rewards: string;
    search: string;
    by: string;
  };
  setFilters: (filters: {
    tags: string;
    rewards: string;
    search: string;
    by: string;
  }) => void;
}) => {
  const { isMobile } = useScreenSize();

  const debouncedSetSearch = debounce((value: string) => {
    setFilters({ ...filters, search: value });
  }, 300);

  const filterByRewards = [
    {
      display: "Filter By Reward",
      value: "",
      displayTextOverride: "Clear All",
    },
    ...rewardTypes,
  ];

  const filterBy = [
    {
      display: "🔥Trending (week)",
      value: "trending-week",
    },
    {
      display: "🔥Trending (day)",
      value: "trending-day",
    },
    {
      display: "Popularity ⬇",
      value: "popularity-down",
    },
    {
      display: "Popularity ⬆",
      value: "popularity-up",
    },
    {
      display: "Finish Date ⬆",
      value: "finish-date-up",
    },
    {
      display: "Finish Date ⬇",
      value: "finish-date-down",
    },
    {
      display: "Rating",
      value: "rating",
      comingSoon: true,
    },
    {
      display: "Location",
      value: "location",
      comingSoon: true,
    },
  ];

  return (
    <div className="w-full flex  justify-between mmd:flex-col mmd:gap-4 mmd:items-start">
      <div className="w-full flex gap-4 max-w-xl mmd:gap-4 flex-wrap">
        <DropDownFilter
          setStateValue={(value: string) =>
            setFilters({ ...filters, rewards: value })
          }
          data={filterByRewards}
          name="filter_by_rewards"
          placeholder="Filter By Reward"
          arrowIcon={false}
          defaultIcon={<CupIcon />}
        />
        <DropDownFilter
          setStateValue={(value: string) =>
            setFilters({ ...filters, tags: value })
          }
          data={missionTags}
          name="filter_by_tags"
          placeholder="Tags"
          arrowIcon={false}
          defaultIcon={<TagIcon />}
        />
        {!isMobile ? (
          <SearchFilter
            name="filter_by_text"
            onChange={(e: any) => debouncedSetSearch(e.target.value)}
            placeholder="Search"
          />
        ) : (
          <DropDownFilter
            setStateValue={(value: string) =>
              setFilters({ ...filters, by: value })
            }
            data={filterBy}
            name="filter_by"
            arrowIcon={false}
            defaultIcon={<SettingsIcon />}
          />
        )}
      </div>

      {isMobile ? (
        <SearchFilter
          name="filter_by_text"
          onChange={(e: any) => debouncedSetSearch(e.target.value)}
          placeholder="Search"
        />
      ) : (
        <DropDownFilter
          setStateValue={(value: string) =>
            setFilters({ ...filters, by: value })
          }
          data={filterBy}
          name="filter_by"
          arrowIcon={false}
          defaultIcon={<SettingsIcon />}
        />
      )}
    </div>
  );
};

export default FilterMissions;
