import { useNavigate } from "react-router-dom";
import { useUserManagementContext } from "../../hooks/useUserManagementContext";
import Button from "../Button";

export const InfoSection = ({ activeSection }: { activeSection: string }) => {
  return (
    <div className="flex-1 flex flex-col justify-center items-start max-w-[570px] mmd:w-full">
      {activeSection === "followers" ? <FollowerSection /> : <CreatorSection />}
    </div>
  );
};

const CreatorSection = () => {
  const { setActiveAuth } = useUserManagementContext();
  const navigate = useNavigate();
  return (
    <div className="mmd:flex mmd:flex-col mmd:w-full">
      <div className="flex flex-col">
        <span className="text-heading-md mmd:text-title-xl font-bold">
          {`Create Missions →`}
        </span>

        <span className="flex flex-row items-center gap-2 mt-1 mb-8">
          <span className="text-heading-md mmd:text-title-xl font-bold text-danger-250">
            Reward
          </span>
          <span className="text-heading-md mmd:text-title-xl font-bold">
            your
          </span>
          <span className="text-heading-md mmd:text-title-xl font-bold text-danger-250">
            Followers
          </span>
        </span>

        <span className="text-body-lg font-space-grotesk text-surface-700">
          Identify and activate your followers. Reward and incentivize them with
          exclusive gifts, initiatives, and offers.
        </span>

        <ul className="mt-4 mb-8 list-disc list-inside text-body-lg font-space-grotesk text-surface-700">
          <li>
            Transform your followers into your biggest advocates,
            micro-influencers, and ambassadors.
          </li>
          <li>
            Unlock the power of word-of-mouth marketing, boosting your reach and
            impressions.
          </li>
          <li>
            Gain direct access to your followers—no more reliance on traditional
            platforms.
          </li>
        </ul>

        <span className="text-body-lg font-space-grotesk text-surface-700">
          Ready to create..
        </span>
      </div>
      <div className="flex flex-row gap-4 mt-8">
        <Button
          className="!bg-danger-250 px-8 py-4 mmd:!px-0 mmd:!py-1"
          onClick={() => setActiveAuth("signup")}
        >
          Create a Community
        </Button>
        <Button
          className="!bg-transparent !border-danger-250 !border-2 !text-danger-250 px-8 py-4 mmd:!px-1 mmd:!py-1"
          onClick={() => navigate("/discovery")}
        >
          Explore Missions
        </Button>
      </div>
    </div>
  );
};

const FollowerSection = () => {
  const { setActiveAuth } = useUserManagementContext();
  const navigate = useNavigate();
  return (
    <div className="mmd:flex mmd:flex-col mmd:w-full">
      <div className="flex flex-col">
        <span className="text-heading-md mmd:text-title-xl font-bold">
          {`Complete Missions →`}
        </span>

        <span className="flex flex-row items-center gap-2 mt-1 mb-8">
          <span className="text-heading-md mmd:text-title-xl font-bold text-secondary-100">
            Collect Rewards
          </span>
        </span>

        <span className="text-body-lg font-space-grotesk text-surface-700">
          Earn rewards for engaging with the creators you love. Monetize your
          passion and become part of their story.
        </span>

        <ul className="mt-4 mb-8 list-disc list-inside text-body-lg font-space-grotesk text-surface-700">
          <li>Be rewarded for supporting your favorite brands and creators.</li>
          <li>Earn, spend, and enjoy exclusive benefits and rewards.</li>
        </ul>

        <span className="text-body-lg font-space-grotesk text-surface-700">
          Start your adventure!
        </span>
      </div>
      <div className="flex flex-row gap-4 mt-8">
        <Button
          className="bg-secondary-100 px-8 py-4 mmd:!px-3 mmd:!py-2"
          onClick={() => navigate("/discovery")}
        >
          Explore Missions
        </Button>
        <Button
          className="!bg-transparent !border-secondary-100 !border-2 !text-secondary-100 px-8 py-4 mmd:!px-3 mmd:!py-2"
          onClick={() => setActiveAuth("signup")}
        >
          Get Started
        </Button>
      </div>
    </div>
  );
};
