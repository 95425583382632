import TextTruncate from "react-text-truncate";

export interface CompanyInfoTypes {
  title: {
    text: string;
    className?: string;
  };
  subTitle?: {
    text: string;
    className?: string;
  };
}

const CompanyInfo = ({ title, subTitle }: CompanyInfoTypes) => {
  return (
    <div className="flex flex-col w-fit overflow-hidden text-wrap">
      <h3 className={`${title.className} title-lg-bold capitalize`}>
        {title.text}
      </h3>
      <p className={`${subTitle?.className} body-md text-surface-700`}>
        <TextTruncate
          line={3}
          element="span"
          truncateText="…"
          text={subTitle?.text}
        />
      </p>
    </div>
  );
};

export default CompanyInfo;
