import { Mission, Reward } from "../../hooks/useCommunity";
import Button from "../Button";
import ScoreCard from "./ScoreCard";
import { useNavigate } from "react-router-dom";
interface FeaturedMissionCardProps {
  avatar: string;
  name: string;
  rewards: Reward[];
  mission: Mission;
  [key: string]: any;
}

export const FeaturedMissionCard = ({
  avatar,
  name,
  rewards,
  mission,
  onClick,
}: FeaturedMissionCardProps) => {
  const navigate = useNavigate();

  return (
    <div
      className="rounded-lg border p-4 gap-4 bg-surface-50 border-solid border-[#EBEBEB] min-w[400px] mmd:min-w-[300px] cursor-pointer hover:shadow-md transition-all duration-300"
      onClick={() => {
        if (onClick) {
          onClick();
          return;
        }

        navigate(`/mission/${mission._id}`);
      }}
    >
      <img
        src={avatar}
        alt="avatar"
        className="w-full aspect-video rounded-t-[10px] object-contain"
      />
      <div className="flex gap-2 flex-col mt-2">
        <div className="flex flex-row justify-between items-center pt-2">
          <p className="body-xl-bold text-surface-900 text-[#4F575E]">{name}</p>
          <Button>See More</Button>
        </div>

        <div className="w-fit flex flex-wrap gap-4">
          {rewards?.map((reward) => <ScoreCard score={reward.name} />)}
        </div>
      </div>
    </div>
  );
};
