import { useForm } from "react-hook-form";
import CloseIcon from "../../../assets/icons/close";
import Button from "../../Button";
import BoonModal from "../../Modal";
import { TextInput } from "../../TextInput";
import { useEffect } from "react";
import { useCreateSection, useUpdateSection } from "../../../hooks/useSection";
import { useCommunityManagementContext } from "../../../hooks";
import { Loader } from "../../Loader";

interface ISectionModal {
  onClose: () => void;

  communityId?: string;
  modalType?: string;

  sections?: {
    name: string;
  }[];
}

const SectionModal = ({
  onClose,
  communityId,
  modalType,
  sections,
}: ISectionModal) => {
  const { activeSection: section } = useCommunityManagementContext();
  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
  } = useForm();
  const {
    mutate: updateSection,
    isPending: isUpdateSectionPending,
    isError: isUpdateSectionError,
    error: updateSectionError,
    isSuccess: isUpdateSectionSuccess,
  } = useUpdateSection();

  const {
    mutate: createSection,
    isPending: isCreateSectionPending,
    isError: isCreateSectionError,
    error: createSectionError,
    isSuccess: isCreateSectionSuccess,
  } = useCreateSection();

  const onSubmit = (data: any) => {
    const payload = { ...data };

    const isSameSectionName = sections?.some(
      (s) =>
        s.name ===
        (modalType === "updateSection" ? payload.newName : payload.name),
    );

    if (!isSameSectionName) {
      if (modalType === "updateSection") {
        updateSection({ communityId, _id: section?._id, payload });
      } else {
        createSection({ communityId, payload });
      }
    } else {
      setError(modalType === "updateSection" ? "newName" : "name", {
        message: "Section with such name arleady exists",
      });
    }
  };

  useEffect(() => {
    if (isCreateSectionSuccess || isUpdateSectionSuccess) {
      onClose();
    }
  }, [isCreateSectionSuccess, isUpdateSectionSuccess, onClose]);

  console.log(errors);

  return (
    <BoonModal onClose={onClose} style={{ padding: "0px" }}>
      <div className="flex flex-col p-6 gap-4 relative">
        <CloseIcon
          className="absolute top-6 right-6 cursor-pointer"
          onClick={onClose}
        />
        <p className="title-lg-medium text-surface-900">
          {modalType === "updateSection" ? "Edit Section" : "Create Section"}
        </p>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="flex flex-col gap-8">
            <div className="flex flex-col gap-4">
              <TextInput
                autoFocus={true}
                name={modalType === "updateSection" ? "newName" : "name"}
                errorMessage={
                  errors?.[modalType === "updateSection" ? "newName" : "name"]
                    ?.message as string
                }
                registerInForm={register}
                placeholder="Name"
                validation={{ required: "Section Name is required" }}
                label="Section Name"
                labelClassName="body-lg text-surface-900"
                defaultValue={
                  modalType === "updateSection" ? section?.name : undefined
                }
              />
            </div>
            {isCreateSectionPending || isUpdateSectionPending ? (
              <div className="flex justify-center items-center">
                <Loader size="md" />
              </div>
            ) : (
              <Button type="submit" className="px-5 py-3">
                Save Details
              </Button>
            )}
            {(isCreateSectionError || isUpdateSectionError) && (
              <p>
                {createSectionError?.message || updateSectionError?.message}
              </p>
            )}
            {(isCreateSectionSuccess || isUpdateSectionSuccess) && (
              <p>Section saved successfully</p>
            )}
          </div>
        </form>
      </div>
    </BoonModal>
  );
};

export default SectionModal;
