import { createContext, useState } from "react";

export const UserManagementContext = createContext<any>(null);

export const UserManagementProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [activeTab, setActiveTab] = useState<string>(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const tabParam = searchParams.get("tab");
    return tabParam || "Profile";
  });
  const [activeAuth, setActiveAuth] = useState<"signin" | "signup" | null>(
    null,
  );

  return (
    <UserManagementContext.Provider
      value={{ activeTab, setActiveTab, activeAuth, setActiveAuth }}
    >
      {children}
    </UserManagementContext.Provider>
  );
};
