import { useEffect, useMemo, useState } from "react";

export const useScreenSize = () => {
  const [width, setWidth] = useState(window.innerWidth);
  const [height, setHeight] = useState(window.innerHeight);

  useEffect(() => {
    const handleWindowResize = () => {
      setWidth(window.innerWidth);
      setHeight(window.innerHeight);
    };

    window.addEventListener("resize", handleWindowResize);
    return () => window.removeEventListener("resize", handleWindowResize);
  }, []);

  const { screenSize, isMobile } = useMemo(() => {
    const isM2xl = width <= 1535;
    const isMxl = width <= 1279;
    const isMlg = width <= 1023;
    const isMmd = width <= 767;
    const isMsm = width <= 639;

    let screenSize: "2xl" | "xl" | "lg" | "md" | "sm" = "2xl";

    if (isMsm) {
      screenSize = "sm";
    } else if (isMmd) {
      screenSize = "md";
    } else if (isMlg) {
      screenSize = "lg";
    } else if (isMxl) {
      screenSize = "xl";
    } else if (isM2xl) {
      screenSize = "2xl";
    }

    const isMobile = screenSize === "sm" || screenSize === "md";
    return { screenSize, isMobile };
  }, [width]);

  return {
    width,
    screenSize,
    isMobile,
    height,
  };
};
