const EmptyIcon = (props) => (
  <svg
    width={254}
    height={180}
    viewBox="0 0 254 180"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle cx={119.25} cy={75} r={75} fill="#EAECF0" />
    <circle cx={59.75} cy={45} r={8} fill="#F2F4F7" />
    <circle cx={231.75} cy={151} r={6} fill="#F2F4F7" />
    <circle cx={58.75} cy={163} r={10} fill="#F2F4F7" />
    <circle cx={243.75} cy={63} r={10} fill="#F2F4F7" />
    <circle cx={224.75} cy={36} r={7} fill="#F2F4F7" />
    <g filter="url(#filter0_dd_10574_2079)">
      <path
        d="M63.3879 139.214L115.839 114.755C118.473 113.527 119.612 110.397 118.384 107.764L87.4084 41.3362L66.433 33.7018L23.5183 53.7133C20.8848 54.9413 19.7455 58.0716 20.9735 60.7051L56.3961 136.669C57.6241 139.302 60.7544 140.442 63.3879 139.214Z"
        fill="url(#paint0_linear_10574_2079)"
      />
      <path
        d="M66.4336 33.7017L87.409 41.3361L73.1041 48.0066L66.4336 33.7017Z"
        fill="#D0D5DD"
      />
    </g>
    <g filter="url(#filter1_dd_10574_2079)">
      <path
        d="M88.4605 121.339H146.334C149.24 121.339 151.595 118.984 151.595 116.078V42.7837L135.812 27H88.4605C85.5548 27 83.1992 29.3555 83.1992 32.2612V116.078C83.1992 118.984 85.5548 121.339 88.4605 121.339Z"
        fill="url(#paint1_linear_10574_2079)"
      />
      <path d="M135.812 27L151.596 42.7837H135.812V27Z" fill="#D0D5DD" />
    </g>
    <g filter="url(#filter2_dd_10574_2079)">
      <path
        d="M118.221 114.724L170.672 139.182C173.306 140.41 176.436 139.271 177.664 136.638L208.64 70.2103L201.005 49.2349L158.091 29.2235C155.457 27.9955 152.327 29.1348 151.099 31.7683L115.676 107.732C114.448 110.366 115.588 113.496 118.221 114.724Z"
        fill="url(#paint2_linear_10574_2079)"
      />
      <path
        d="M201.006 49.2349L208.64 70.2102L194.335 63.5398L201.006 49.2349Z"
        fill="#D0D5DD"
      />
    </g>
    <g clipPath="url(#clip0_10574_2079)">
      <path
        d="M163.573 58.0488H73.4746C72.2591 58.0496 71.0936 58.5328 70.2341 59.3923C69.3746 60.2518 68.8914 61.4173 68.8906 62.6329V145.299C68.8914 146.514 69.3746 147.68 70.2341 148.539C71.0936 149.399 72.2591 149.882 73.4746 149.883H163.573C164.788 149.882 165.954 149.399 166.814 148.539C167.673 147.68 168.156 146.514 168.157 145.299V62.6329C168.156 61.4172 167.673 60.2516 166.814 59.3921C165.954 58.5326 164.788 58.0495 163.573 58.0488Z"
        fill="#C6C6C6"
      />
      <path
        d="M157.641 121.157H79.4063C76.8748 121.157 74.8223 119.104 74.8223 116.573V43.5332C74.8223 41.0017 76.8748 38.9492 79.4063 38.9492H157.641C160.173 38.9492 162.225 41.0017 162.225 43.5332V116.573C162.225 119.104 160.173 121.157 157.641 121.157Z"
        fill="#F9F9F9"
      />
      <path
        d="M76.2736 118.024V44.9846C76.2736 42.453 78.3261 40.4005 80.8576 40.4005H159.092C159.986 40.4005 160.818 40.6604 161.523 41.103C160.712 39.8107 159.279 38.9492 157.641 38.9492H79.4063C76.8748 38.9492 74.8223 41.0017 74.8223 43.5332V116.573C74.8223 118.211 75.6838 119.644 76.9761 120.455C76.5335 119.75 76.2736 118.918 76.2736 118.024V118.024Z"
        fill="url(#paint3_linear_10574_2079)"
      />
      <path
        d="M148.015 54.3813H89.0332V57.7427H148.015V54.3813ZM148.015 67.216H89.0332V70.5774H148.015V67.216ZM148.015 80.0536H89.0332V83.4149H148.015V80.0536ZM148.015 92.8883H89.0332V96.2501H148.015V92.8883Z"
        fill="white"
      />
      <path
        d="M96.0605 82.0391L110.882 96.8609H96.0605V82.0391Z"
        fill="url(#paint4_linear_10574_2079)"
      />
      <path
        d="M168.156 98.0833V63.981L162.225 58.0488V98.0828H168.156V98.0833Z"
        fill="#C6C6C6"
      />
      <path
        d="M175.104 101.168L168.714 146.408C168.394 148.67 166.459 150.351 164.175 150.351H72.8718C70.5878 150.351 68.6522 148.67 68.3327 146.408L59.6963 85.2779C59.3067 82.5182 61.4482 80.0525 64.2353 80.0525H92.4315C94.7155 80.0525 96.6511 81.7334 96.9706 83.9946L98.1024 92.0001C98.4219 94.2618 100.357 95.9427 102.641 95.9427H170.566C173.353 95.9427 175.494 98.4085 175.105 101.168H175.104Z"
        fill="url(#paint5_linear_10574_2079)"
      />
      <path
        d="M152.461 130.172H84.5863C84.2142 130.172 83.855 130.036 83.5759 129.79C83.2968 129.544 83.117 129.205 83.0701 128.835L82.2209 122.112C82.1939 121.897 82.213 121.678 82.2768 121.471C82.3407 121.264 82.4478 121.073 82.5912 120.91C82.7346 120.748 82.9109 120.617 83.1084 120.528C83.306 120.439 83.5203 120.393 83.737 120.393H153.31C153.527 120.393 153.741 120.439 153.939 120.528C154.136 120.617 154.313 120.748 154.456 120.91C154.599 121.073 154.707 121.264 154.77 121.471C154.834 121.678 154.853 121.897 154.826 122.112L153.977 128.835C153.93 129.205 153.75 129.544 153.471 129.79C153.192 130.036 152.833 130.172 152.461 130.172Z"
        fill="#D5D5D5"
      />
    </g>
    <defs>
      <filter
        id="filter0_dd_10574_2079"
        x={-1.25}
        y={27.0312}
        width={141.857}
        height={154.406}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feMorphology
          radius={4}
          operator="erode"
          in="SourceAlpha"
          result="effect1_dropShadow_10574_2079"
        />
        <feOffset dy={8} />
        <feGaussianBlur stdDeviation={4} />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.0627451 0 0 0 0 0.0941176 0 0 0 0 0.156863 0 0 0 0.03 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_10574_2079"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feMorphology
          radius={4}
          operator="erode"
          in="SourceAlpha"
          result="effect2_dropShadow_10574_2079"
        />
        <feOffset dy={20} />
        <feGaussianBlur stdDeviation={12} />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.0627451 0 0 0 0 0.0941176 0 0 0 0 0.156863 0 0 0 0.08 0"
        />
        <feBlend
          mode="normal"
          in2="effect1_dropShadow_10574_2079"
          result="effect2_dropShadow_10574_2079"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect2_dropShadow_10574_2079"
          result="shape"
        />
      </filter>
      <filter
        id="filter1_dd_10574_2079"
        x={63.1992}
        y={27}
        width={108.396}
        height={134.339}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feMorphology
          radius={4}
          operator="erode"
          in="SourceAlpha"
          result="effect1_dropShadow_10574_2079"
        />
        <feOffset dy={8} />
        <feGaussianBlur stdDeviation={4} />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.0627451 0 0 0 0 0.0941176 0 0 0 0 0.156863 0 0 0 0.03 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_10574_2079"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feMorphology
          radius={4}
          operator="erode"
          in="SourceAlpha"
          result="effect2_dropShadow_10574_2079"
        />
        <feOffset dy={20} />
        <feGaussianBlur stdDeviation={12} />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.0627451 0 0 0 0 0.0941176 0 0 0 0 0.156863 0 0 0 0.08 0"
        />
        <feBlend
          mode="normal"
          in2="effect1_dropShadow_10574_2079"
          result="effect2_dropShadow_10574_2079"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect2_dropShadow_10574_2079"
          result="shape"
        />
      </filter>
      <filter
        id="filter2_dd_10574_2079"
        x={93.4531}
        y={27}
        width={141.857}
        height={154.406}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feMorphology
          radius={4}
          operator="erode"
          in="SourceAlpha"
          result="effect1_dropShadow_10574_2079"
        />
        <feOffset dy={8} />
        <feGaussianBlur stdDeviation={4} />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.0627451 0 0 0 0 0.0941176 0 0 0 0 0.156863 0 0 0 0.03 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_10574_2079"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feMorphology
          radius={4}
          operator="erode"
          in="SourceAlpha"
          result="effect2_dropShadow_10574_2079"
        />
        <feOffset dy={20} />
        <feGaussianBlur stdDeviation={12} />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.0627451 0 0 0 0 0.0941176 0 0 0 0 0.156863 0 0 0 0.08 0"
        />
        <feBlend
          mode="normal"
          in2="effect1_dropShadow_10574_2079"
          result="effect2_dropShadow_10574_2079"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect2_dropShadow_10574_2079"
          result="shape"
        />
      </filter>
      <linearGradient
        id="paint0_linear_10574_2079"
        x1={60.3063}
        y1={139.419}
        x2={18.5068}
        y2={64.009}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#E4E7EC" />
        <stop offset={1} stopColor="#F9FAFB" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_10574_2079"
        x1={85.5811}
        y1={120.223}
        x2={79.5673}
        y2={34.2131}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#E4E7EC" />
        <stop offset={1} stopColor="#F9FAFB" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_10574_2079"
        x1={116.083}
        y1={112.495}
        x2={146.982}
        y2={32.0024}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#E4E7EC" />
        <stop offset={1} stopColor="#F9FAFB" />
      </linearGradient>
      <linearGradient
        id="paint3_linear_10574_2079"
        x1={121.212}
        y1={82.736}
        x2={63.2391}
        y2={24.7631}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
      </linearGradient>
      <linearGradient
        id="paint4_linear_10574_2079"
        x1={105.853}
        y1={99.2391}
        x2={87.9377}
        y2={81.3243}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#C2CECE" stopOpacity={0} />
        <stop offset={0.179} stopColor="#AFBCBC" stopOpacity={0.179} />
        <stop offset={1} stopColor="#5B6A6A" />
      </linearGradient>
      <linearGradient
        id="paint5_linear_10574_2079"
        x1={117.4}
        y1={80.0525}
        x2={117.4}
        y2={150.351}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#EEF0F4" />
        <stop offset={0.927} stopColor="#E4E4E4" />
      </linearGradient>
      <clipPath id="clip0_10574_2079">
        <rect
          width={115.5}
          height={115.5}
          fill="white"
          transform="translate(59.6504 36.8999)"
        />
      </clipPath>
    </defs>
  </svg>
);
export default EmptyIcon;
