import AvatarPlaceholder from "../../assets/images/avatar_placeholder.png";

interface UserCardProps {
  email: string;
  name: string;
  surname: string;
  profilePicture?: string;
  classNames?: string;
}

const UserCard = ({
  email,
  name,
  surname,
  profilePicture,
  classNames = "",
}: UserCardProps) => (
  <div className={`flex gap-4 items-center ${classNames}`}>
    <img
      src={profilePicture || AvatarPlaceholder}
      alt="avatar"
      className="rounded-full border-2 border-solid border-surface-50 w-[84px] h-[84px]"
    />
    <div className="flex flex-col gap-2.5">
      <p className="body-xl-medium text-surface-900 break-all">{`${name} ${surname}`}</p>
      <p className="body-lg text-surface-700 break-all">{email}</p>
    </div>
  </div>
);

export default UserCard;
