import classNames from "classnames";
import QuestionMarkIcon from "../../assets/icons/questionMark";
import BoonTooltip from "../Tooltip";

export interface TextAreaInputProps {
  type?: string;
  label?: string;
  id?: string;
  tooltipContent?: string;
  className?: string;
  labelClassName?: string;
  errorMessage?: string;
  registerInForm?: (name: string, validation: any) => object;
  name: string;
  validation?: any;
  [key: string]: any;
}

export const TextAreaInput = ({
  id,
  tooltipContent,
  label,
  className,
  errorMessage,
  registerInForm = () => ({}),
  name,
  validation,
  labelClassName = "",
  ...inputProps
}: TextAreaInputProps) => {
  const inputClasses = classNames(
    `${className} text-sm w-full outline-none p-4 rounded-[8px] transition-all duration-300 ease-in-out focus:shadow-md hover:scale-101 bg-white placeholder:body-md placeholder:capitalize hover:bg-surface-150`,
    {
      "border-2 border-red-500 opacity-100": errorMessage,
      "border-2 border-surface-250 opacity-80": !errorMessage,
    },
  );

  const errorClasses = classNames(
    "text-red-500 text-sm font-medium mb-3 transition-opacity duration-300 ease-in-out",
    {
      "opacity-100": errorMessage,
      "opacity-0": !errorMessage,
    },
    "pl-2",
  );

  return (
    <div className="flex flex-col gap-2 items-start w-full">
      <div className="flex gap-2 items-center">
        {label && (
          <p
            className={`body-lg font-medium text-surface-700 ${labelClassName}`}
          >
            {label} {validation ? "*" : ""}
          </p>
        )}
        {tooltipContent && (
          <>
            <a id={id}>
              <QuestionMarkIcon />
            </a>
            <BoonTooltip id={id} content={tooltipContent} />
          </>
        )}
      </div>
      <textarea
        className={inputClasses}
        {...registerInForm(name, validation)}
        {...inputProps}
      />
      {errorMessage ? (
        <p className={errorClasses}>{errorMessage || "\u00A0"}</p>
      ) : (
        ""
      )}
    </div>
  );
};
