import { useMutation, useQueryClient } from "@tanstack/react-query";
import { QUERY_KEYS } from "../../constants";
import { useAPI } from "../useApi";
import { useErrorHandler } from "../useErrorHandler";

const useMissionOrder = () => {
  const { put } = useAPI();
  const queryClient = useQueryClient();
  const { handleApiError } = useErrorHandler();

  return useMutation({
    mutationFn: async (data: any) => put(`/api/mission/order/`, data),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.COMMUNITY] });
    },
    onError: (error) => {
      handleApiError(error);
    },
  });
};

export default useMissionOrder;
