export interface LineProgressTypes {
  percentage: string | number;
}

const LineProgress = ({ percentage }: LineProgressTypes) => {
  return (
    <div className="w-full bg-[#F2F2F2] rounded-full">
      <div
        className=" bg-warning-400 rounded-full p-1"
        style={{ width: `${percentage}%` }}
      />
    </div>
  );
};

export default LineProgress;
