import Button from "../Button";
import BoonModal from "../Modal";

const LaunchedWarningModal = ({ onClose }: { onClose: () => void }) => {
  return (
    <BoonModal onClose={onClose}>
      <div className="max-w-sm m-auto flex flex-col gap-8">
        <div className="flex flex-col gap-3 text-center">
          <h3 className="text-title-xl font-bold">Warning</h3>
          <p className="text-body-lg text-[#4F575E]">
            This mission will now be vetted by the Boon team before going live,
            this is to keep all Boon users safe and secure. Thanks for your
            patience!
          </p>
        </div>
        <div className="flex flex-col w-full gap-4">
          <>
            <Button
              action={onClose}
              className="!bg-[#FFFFFF] py-2 !text-black border border-solid border-[#EBEBEB]"
            >
              Close
            </Button>
          </>
        </div>
      </div>
    </BoonModal>
  );
};

export default LaunchedWarningModal;
