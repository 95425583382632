import * as React from "react";
const SettingsIcon = (props) => (
  <svg
    width={18}
    height={18}
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.625 5.59465H4.18875V5.8579C4.18875 6.7264 4.896 7.43215 5.766 7.43215H11.5605C12.4297 7.43215 13.1377 6.7264 13.1377 5.8579V5.59465H16.1242C16.4347 5.59465 16.6867 5.34265 16.6867 5.03215C16.6867 4.72165 16.4347 4.46965 16.1242 4.46965H13.1377V4.21465C13.1377 3.34615 12.4297 2.63965 11.5605 2.63965H5.766C4.896 2.63965 4.18875 3.34615 4.18875 4.21465V4.46965H2.625C2.3145 4.46965 2.0625 4.72165 2.0625 5.03215C2.0625 5.34265 2.3145 5.59465 2.625 5.59465Z"
      fill="black"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.125 12.3974H14.2095V12.1416C14.2095 11.2731 13.5023 10.5674 12.6322 10.5674H8.4585C7.58925 10.5674 6.882 11.2731 6.882 12.1416V12.3974H2.625C2.3145 12.3974 2.0625 12.6494 2.0625 12.9599C2.0625 13.2704 2.3145 13.5224 2.625 13.5224H6.882V13.7849C6.882 14.6534 7.58925 15.3599 8.4585 15.3599H12.6322C13.5023 15.3599 14.2095 14.6534 14.2095 13.7849V13.5224H16.125C16.4355 13.5224 16.6875 13.2704 16.6875 12.9599C16.6875 12.6494 16.4355 12.3974 16.125 12.3974Z"
      fill="black"
    />
  </svg>
);
export default SettingsIcon;
