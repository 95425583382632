import MedalIcon from "../../../assets/icons/medal";
import AvatarImage from "../../../assets/images/community_avatar.png";
import Avatar from "../Avatar";
import CompanyInfo from "../CompanyInfo";
import { useNavigate } from "react-router-dom";
import InfoCard from "./InfoCard";
import { ManagedCommunity } from "../../../hooks/useManagedCommunities.ts";
import MembersIcon from "../../../assets/icons/Members";

const CommunityPrevCard = ({
  _id,
  name,
  description,
  logoUrl,
  stats,
  url,
  onClick,
}: ManagedCommunity) => {
  const Navigate = useNavigate();

  return (
    <div
      className="cursor-pointer border border-solid border-surface-200 bg-surface-100 rounded-lg p-4 flex flex-col gap-2.5  hover:shadow-md transition-all duration-300"
      onClick={() => {
        if (onClick) {
          onClick();
        } else {
          url ? Navigate(`/${url}`) : Navigate(`/community/${_id}`);
        }
      }}
    >
      <div className="flex items-start gap-2">
        <Avatar
          avatarSrc={logoUrl || AvatarImage}
          className="!w-16 !h-16 rounded-full"
          imageClassName="!w-16 !h-16 rounded-full"
        />
        <CompanyInfo
          title={{ text: name }}
          subTitle={{
            text: description,
          }}
        />
      </div>
      <div className="w-full flex gap-3">
        <InfoCard
          icon={<MembersIcon />}
          label="Members"
          value={stats.enrolledUsers.toString()}
        />
        <InfoCard
          icon={<MedalIcon />}
          label="Missions"
          value={stats.totalMissions.toString()}
        />
      </div>
    </div>
  );
};

export default CommunityPrevCard;
