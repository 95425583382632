import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useAPI } from "../useApi";
import { QUERY_KEYS } from "../../constants";
import { useSuccessHandler } from "../useSuccessHandler";
import toast from "react-hot-toast";

export interface Member {
  _id: string;
  email: string;
  name: string;
  role: string;
}

export interface SocialsTypes {
  networkName: string;
  url: string;
}

export interface CreateCommunityTypes {
  name: string;
  description: string;
  socialNetworkLinks: SocialsTypes[];
  logoUrl: string;
  coverImageUrl: string;
  url: string;
  tags: string[];
  emailInvites: string[];
}

const useCreateCommunity = () => {
  const { post } = useAPI();
  const queryClient = useQueryClient();
  const { showSuccessMessage } = useSuccessHandler();

  return useMutation({
    mutationFn: async (data: CreateCommunityTypes) => {
      return post("/api/community", data);
    },
    onSuccess: () => {
      showSuccessMessage("Community created successfully");
      queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.COMMUNITY] });
    },
    onError: (error: any) => {
      console.log(error);
      toast.error(error.response.data.message);
    },
  });
};

export default useCreateCommunity;
