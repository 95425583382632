import { useForm } from "react-hook-form";
import Button from "../Button";
import { TextInput } from "../TextInput";
import { useUser } from "../../hooks";
import CloseIcon from "../../assets/icons/close";
import { Loader } from "../Loader";

const EditPassword = ({ closeModal }: { closeModal: () => void }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const {
    updatePassword,
    isPasswordError,
    passwordError,
    isPasswordPending,
    isPasswordSuccess,
  } = useUser();
  const onSubmit = (data: any) => {
    updatePassword(data);
  };
  return (
    <form onSubmit={handleSubmit(onSubmit)} className="w-full">
      <div className="flex flex-col p-1 gap-4 w-full relative">
        <CloseIcon
          className="absolute top-2 right-2 cursor-pointer"
          onClick={closeModal}
        />
        <p className="title-lg-medium text-surface-900">Edit Password</p>
        <div className="flex flex-col w-full gap-8">
          <div className="flex flex-col w-full gap-3">
            <div className="flex flex-col w-full gap-2">
              <TextInput
                type="password"
                name={"currentPassword"}
                errorMessage={errors?.currentPassword?.message as string}
                registerInForm={register}
                label="Old Password"
                placeholder="Old Password"
                validation={{ required: "Old Password is required" }}
                labelClassName="body-lg text-surface-900"
              />
              <TextInput
                containerClassName="mt-4"
                type="password"
                name={"newPassword"}
                errorMessage={errors?.newPassword?.message as string}
                registerInForm={register}
                label="New Password"
                placeholder="New Password"
                validation={{
                  required: "New Password is required",
                  minLength: {
                    value: 8,
                    message: "New Password must be at least 8 characters long",
                  },
                }}
                labelClassName="body-lg text-surface-900"
              />
            </div>
            {isPasswordPending ? (
              <div className="flex justify-center items-center">
                <Loader size="md" />
              </div>
            ) : (
              <Button type="submit" className="rounded-[10px] py-3 px-5">
                Submit
              </Button>
            )}
            {isPasswordError && <p>{passwordError?.message}</p>}
            {isPasswordSuccess && <p>Password changed successfully</p>}
          </div>
        </div>
      </div>
    </form>
  );
};

export default EditPassword;
