import CreateCommunity from "../CreateCommunity";
import BoonModal from "../Modal";

const CreateCommunityModal = ({ onClose }: { onClose: () => void }) => {
  return (
    <BoonModal
      onClose={onClose}
      style={{
        background: "transparent",
        border: "none",
        padding: "0",
      }}
      width="980px"
      options={{
        mobileHeight: "90vh",
      }}
    >
      <CreateCommunity onClose={onClose} />
    </BoonModal>
  );
};

export default CreateCommunityModal;
