const OpenLinkIcon = (props) => (
  <svg
    width={16}
    height={16}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M6.74576 2.33301H5.34292C3.49244 2.33301 2.33203 3.64327 2.33203 5.49753V10.5018C2.33203 12.3561 3.48677 13.6663 5.34292 13.6663H10.6532C12.51 13.6663 13.6654 12.3561 13.6654 10.5018V9.40561"
      stroke="white"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13.6678 5.3823V2.33301M13.6678 2.33301H10.6185M13.6678 2.33301L8.91406 7.08674"
      stroke="white"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default OpenLinkIcon;
