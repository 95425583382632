const ArrowSide = () => {
  return (
    <svg
      width="8"
      height="14"
      viewBox="0 0 8 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.220001 7.42345L6.69231 0.951172L7.78003 2.03886L2.39541 7.42345L7.78003 12.8081L6.69231 13.8958L0.220001 7.42345Z"
        fill="#202327"
      />
    </svg>
  );
};

export default ArrowSide;
