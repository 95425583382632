import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { BrowserRouter } from "react-router-dom";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { Toaster } from "react-hot-toast";
import { MSClarityProjectId } from "./constants";
import Clarity from "@microsoft/clarity";
import * as Sentry from "@sentry/react";

const queryClient = new QueryClient();
if (MSClarityProjectId) {
  Clarity.init(MSClarityProjectId);
}

Sentry.init({
  dsn: "https://0337ad89f18fa14b63de773240c6d690@o4508733327147008.ingest.de.sentry.io/4508733497016400",
  integrations: [
    Sentry.feedbackIntegration({
      colorScheme: "white",

    }),
  ],
});

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement,
);

root.render(
  <React.StrictMode>
    <GoogleOAuthProvider
      clientId={
        "1099000928522-eqoonct0427kmlboen17fe7h8lkf3jg3.apps.googleusercontent.com"
      }
    >
      <BrowserRouter>
        <QueryClientProvider client={queryClient}>
          <App />
          <Toaster position="top-right"/>
        </QueryClientProvider>
      </BrowserRouter>
    </GoogleOAuthProvider>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
