import DefaultBackground from "../../assets/images/defaultBackground.png";
import { SocialNetworkLink } from "../../types";

export interface PreviewBoxTypes {
  values: Record<string, string>;
  socialNetworkLinks: SocialNetworkLink[];
}

const PreviewBox = ({ values }: PreviewBoxTypes) => {
  const coverImage =
    values &&
    values.coverImageUrl &&
    typeof values.coverImageUrl === "object" &&
    "name" in values.coverImageUrl
      ? URL.createObjectURL(values.coverImageUrl as File)
      : values.coverImageUrl
        ? values.coverImageUrl
        : DefaultBackground;

  const avatarImage =
    values &&
    values.logoUrl &&
    typeof values.logoUrl === "object" &&
    "name" in values.logoUrl
      ? URL.createObjectURL(values.logoUrl as File)
      : values.logoUrl
        ? values.logoUrl
        : DefaultBackground;

  return (
    <div className="max-w-sm w-full overflow-hidden text-wrap border-surface-200 border-solid border rounded-2xl bg-surface-50 h-full pb-12 min-w-[300px]">
      <div className="w-full relative flex justify-center">
        <div className="absolute w-full h-full bg-gradient-to-t from-black/10 from-0%" />
        <img
          src={coverImage}
          className="w-full h-36 object-cover rounded-t-2xl"
          alt="default_bg"
        />
        <img
          src={avatarImage}
          className="w-36 h-36 rounded-full object-cover border-4 shadow-md border-solid border-surface-50 absolute translate-y-15"
          alt="avatar"
        />
      </div>
      <div className="px-4 pt-20 w-full overflow-hidden">
        <div className="flex flex-col gap-3 text-center w-full">
          <h3 className="title-xl-bold break-words">{values.name}</h3>
          <p className="body-lg break-words text-wrap w-full overflow-hidden text-ellipsis">
            {values.description}
          </p>
        </div>
      </div>
    </div>
  );
};

export default PreviewBox;
