export interface AuthFormFooterTypes {
  actionText: string;
  linkText: string;
  setAuthStrategy: (strategy: string) => void;
}

const AuthFooter = ({
  actionText,
  linkText,
  setAuthStrategy,
}: AuthFormFooterTypes & { setAuthStrategy: (strategy: string) => void }) => (
  <p className="body-lg text-surface-700 text-center inline-block">
    {actionText}{" "}
    <p
      className="body-lg inline-block text-primary-400 first-letter:uppercase cursor-pointer hover:underline"
      onClick={() => setAuthStrategy(linkText)}
    >
      {linkText}
    </p>
  </p>
);

export default AuthFooter;
