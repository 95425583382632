import { useRef, useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import {
  autoUpdate,
  flip,
  offset,
  shift,
  useFloating,
} from "@floating-ui/react";

const Popover: React.FC<{
  children: React.ReactNode;
  content: React.ReactNode;
  className?: string;
  contentClassName?: string;
  closeOnClick?: boolean;
}> = ({ children, content, className, contentClassName, closeOnClick }) => {
  const [isOpen, setIsOpen] = useState(false);
  const popoverRef = useRef<HTMLDivElement>(null);

  const { x, y, refs, strategy } = useFloating({
    middleware: [offset(10), flip(), shift()],
    whileElementsMounted: autoUpdate,
  });

  const handleOutsideClick = (event: MouseEvent) => {
    if (
      popoverRef.current &&
      !popoverRef.current.contains(event.target as Node)
    ) {
      setIsOpen(false);
      document.removeEventListener("mousedown", handleOutsideClick); // Cleanup listener
    }
  };

  const togglePopover = () => {
    if (!isOpen) {
      document.addEventListener("mousedown", handleOutsideClick);
    } else {
      document.removeEventListener("mousedown", handleOutsideClick);
    }
    setIsOpen(!isOpen);
  };

  return (
    <div ref={popoverRef}>
      <button
        type="button"
        ref={refs.setReference}
        onClick={togglePopover}
        className={`popover-button ${className || ""}`}
      >
        {children}
      </button>

      <AnimatePresence>
        {isOpen && (
          <motion.div
            ref={refs.setFloating}
            className={`popover-content absolute text-nowrap bg-white border border-gray-300 rounded-lg z-10 ${contentClassName || ""}`}
            style={{
              position: strategy,
              top: y ?? 0,
              left: x ?? 0,
              background: "white",
              border: "1px solid #ccc",
              boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
              borderRadius: "12px",
              zIndex: 1000,
            }}
            onClick={() => closeOnClick && setIsOpen(false)}
            onMouseDown={(e) => e.preventDefault()}
            initial={{ opacity: 0, scale: 0.95 }}
            animate={{ opacity: 1, scale: 1 }}
            exit={{ opacity: 0, scale: 0.95 }}
            transition={{ duration: 0.3 }}
          >
            {content}
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

export default Popover;
