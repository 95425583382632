export const CheckCircleIcon = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      opacity="0.4"
      d="M14.334 8C14.334 4.68605 11.6479 2 8.33398 2C5.02004 2 2.33398 4.68605 2.33398 8C2.33398 11.3133 5.02004 14 8.33398 14C11.6479 14 14.334 11.3133 14.334 8Z"
      stroke="#202327"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.02344 8.0002L7.56333 9.5394L10.6418 6.46094"
      stroke="#202327"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
