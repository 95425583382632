import { Tooltip } from "react-tooltip";

// component which should have tooltip must be wrapped with anchor tag <a> </a>
const BoonTooltip = ({
  id = "",
  content = "",
  style,
}: {
  id?: string;
  content?: string;
  style?: any;
}) => (
  <Tooltip
    anchorSelect={`#${id}`}
    content={content}
    style={{
      backgroundColor: "#FFFFFF",
      color: "#6B767F",
      maxWidth: "300px",
      zIndex: 50,
      ...style,
    }}
    border="1px solid #EBEBEB"
    html={content}
  />
);

export default BoonTooltip;
