const CalendarIcon = (props) => (
  <svg
    width={16}
    height={16}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M2.99219 6.44287H13.6865"
      stroke="black"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.7617 2V3.97449"
      stroke="black"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5.91406 2V3.97449"
      stroke="black"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.8802 2.94775H5.79999C4.03761 2.94775 2.9375 3.92916 2.9375 5.73306V11.1636C2.9375 12.996 4.03761 14.0001 5.79999 14.0001H10.875C12.6426 14.0001 13.7376 13.0135 13.7376 11.209V5.73306C13.7427 3.92916 12.6478 2.94775 10.8802 2.94775Z"
      stroke="black"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default CalendarIcon;
