import { useScreenSize } from "../../hooks";
import MissionCard, { MissionCardProps } from "./MissionCard";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useState, useRef } from "react";
import { CustomDots } from "../CarouselCustomDots";

export interface MissionGroupProps {
  missionType: string;
  description?: string;
  missions?: MissionCardProps[];
}

const MissionGroup = ({
  missionType,
  description,
  missions,
}: MissionGroupProps) => {
  const { isMobile } = useScreenSize();

  if (isMobile) {
    return (
      <HorizontalMissionGroup
        missionType={missionType}
        description={description}
        missions={missions}
      />
    );
  }

  return (
    <div className="flex flex-col gap-[33px] w-full">
      <div className="heading-md-medium text-surface-900">
        {missionType !== "Default" ? missionType : ""}
      </div>
      {description && (
        <div className="body-lg text-surface-700 max-w-[572px] opacity-50">
          {description}
        </div>
      )}
      <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-4">
        {missions?.map((mission, index) => (
          <div key={index}>
            <MissionCard {...mission} title={mission.name} />
          </div>
        ))}
      </div>
    </div>
  );
};

const HorizontalMissionGroup = ({
  missionType,
  description,
  missions = [],
}: MissionGroupProps) => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const sliderRef = useRef<Slider>(null);

  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    beforeChange: (_: number, next: number) => setCurrentSlide(next),
    appendDots: () => (
      <CustomDots
        currentSlide={currentSlide}
        slideCount={missions.length}
        onDotClick={(index) => {
          sliderRef.current?.slickGoTo(index);
        }}
      />
    ),
  };

  return (
    <div className="flex flex-col gap-[33px] w-full">
      <div className="heading-md-medium text-surface-900 text-center">
        {missionType !== "Default" ? missionType : ""}
      </div>
      {description && (
        <div className="body-lg text-surface-700 max-w-[572px] opacity-50">
          {description}
        </div>
      )}
      <div className="mission-slider-container">
        <Slider ref={sliderRef} {...settings}>
          {missions.map((mission, index) => (
            <div key={index} className="px-2">
              <MissionCard {...mission} title={mission.name} />
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
};

export default MissionGroup;
