export interface CustomDotsProps {
  currentSlide: number;
  slideCount: number;
  onDotClick: (index: number) => void;
}

export const CustomDots = ({
  currentSlide,
  slideCount,
  onDotClick,
}: CustomDotsProps) => {
  if (slideCount === 1) {
    return null;
  }

  return (
    <div className="flex justify-center items-center gap-4 mt-6">
      <button
        onClick={() => onDotClick(Math.max(0, currentSlide - 1))}
        disabled={currentSlide === 0}
        className="w-13 h-13 flex items-center justify-center rounded-full border border-surface-300 disabled:opacity-30"
        aria-label="Previous slide"
      >
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
          <path
            d="M15 18L9 12L15 6"
            stroke="currentColor"
            strokeWidth="1.67"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </button>

      <div className="flex gap-2 items-center bg-">
        {Array.from({ length: slideCount }).map((_, index) => (
          <button
            key={index}
            onClick={() => onDotClick(index)}
            className={`h-2 rounded-full transition-all duration-300 ${
              currentSlide === index
                ? "w-6 bg-primary-500"
                : "w-2 bg-surface-200"
            }`}
            aria-label={`Go to slide ${index + 1}`}
          />
        ))}
      </div>

      <button
        onClick={() => onDotClick(Math.min(slideCount - 1, currentSlide + 1))}
        disabled={currentSlide === slideCount - 1}
        className="w-13 h-13 flex items-center justify-center rounded-full border border-surface-300 disabled:opacity-30"
        aria-label="Next slide"
      >
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
          <path
            d="M9 18L15 12L9 6"
            stroke="currentColor"
            strokeWidth="1.67"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </button>
    </div>
  );
};
