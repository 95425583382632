import Button from "../../Button";
import AvatarImage from "../../../assets/images/community_avatar.png";
import ScoreCard from "../ScoreCard";
import Avatar from "../Avatar";
import { useNavigate } from "react-router-dom";
import { CheckCircleIcon } from "../../../assets/icons";
import { Reward } from "../../../hooks/useCommunity";

export const CompletedMissionCard = ({
  community,
  communityId,
  name,
  redeemed = false,
  rewards,
  missionId,
}: {
  communityId: string;
  community: string;
  name: string;
  redeemed: boolean;
  rewards: Reward[];
  missionId?: string;
}) => {
  const navigate = useNavigate();

  return (
    <div className="border border-solid border-surface-250 bg-surface-50 rounded-lg p-4 flex flex-col gap-4  min-w-[360px] mmd:min-w-[315px]">
      <div className="w-full flex items-center justify-between">
        <p className="text-body-xl font-space-grotesk text-surface-700 font-semibold text-[#4F575E]">
          {name}
        </p>
      </div>
      <div className="w-fit flex flex-wrap gap-4">
        {rewards?.map((reward) => <ScoreCard score={reward.name} />)}
      </div>

      <div className="flex justify-between items-center w-full">
        <div className="flex items-center gap-2">
          <Avatar avatarSrc={AvatarImage} className="w-7 h-7" />
          <p
            className="text-body-lg font-space-grotesk text-surface-500 font-regular hover:underline cursor-pointer"
            onClick={() => navigate(`/community/${communityId}`)}
          >
            {community}
          </p>
        </div>
        {redeemed ? (
          <div className="flex items-center py-2 px-4 bg-[#EBEBEB] rounded-lg gap-2">
            <CheckCircleIcon />
            <p className="text-body-sm font-space-grotesk text-surface-500 font-regular">
              Redeemed
            </p>
          </div>
        ) : (
          <Button onClick={() => alert("Coming Soon")}>Redeem</Button>
        )}
      </div>
    </div>
  );
};
