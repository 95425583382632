import LineProgress from "../../ProgressBar/LineProgress";
import ScoreCard from "../ScoreCard";
import { Reward, Task } from "../../../hooks/useCommunity";
import { useNavigate } from "react-router-dom";

export interface CommunityMissionCardProps {
  nextTaskName: string;
  communityName: string;
  missionName: string;
  tasksFinished: number;
  tasksTotal: number;
  completed?: boolean;
  communityId?: string;
  missionImage?: string;
  _id?: string;
  rewards?: Reward[];
  title?: string;
  description?: string;
  tasks?: Task[];
  imageUrl?: string;
  open?: boolean;
  rewardImages?: string[];
  onClick?: () => void;
}

const OngoingMissionCard = ({
  nextTaskName,
  missionName,
  tasksFinished,
  tasksTotal,
  completed = false,
  missionImage,
  rewards,
  tasks,
  title,
  description,
  imageUrl,
  _id,
}: CommunityMissionCardProps) => {
  const completedPercentage = `${(tasksFinished / tasksTotal) * 100}`.slice(
    0,
    2,
  );

  const navigate = useNavigate();

  return (
    <div
      className="border border-solid border-surface-250 bg-surface-50 rounded-lg p-1.5 pb-4 flex flex-col gap-4 cursor-pointer"
      onClick={() => navigate(`/mission/${_id}`)}
    >
      <div className="relative h-[186px] rounded">
        <img
          alt="mission logo"
          src={
            missionImage ||
            "https://s3-alpha-sig.figma.com/img/0999/7a5b/cf184590c1b0015e13a3ef790d07665e?Expires=1731888000&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=G9WPDcROxJlyB0mDDBuX04vUATDEBApg-mdfdLv7gAPU8BCTg3BZUpHDOoOBexAgLjrMZyTSLHzpGof-EUuHkckdgh-iO8t3Z4cqVmwypEBvf0MFCoEWPwyOmKu6gHx-2e0kW46-cAZETxR1RD8D0peOkDnU5czlB3j~gAoJN7rrctdbr3MMfwvwAnossGfl6O2ApM5CCy-jecX86rpCF~Jv70RqS3-q5OXzzFlQ3tk8tt41Ulp-~BZNwIJYvLokGuZm5jTKg0Jd~pMXHPyrnuRU33GE8zYswymcA0FzWkqFaqNLkpkBNHSPaDT-EiRSRkATxtsO-wEUNMhCSBSUKg__"
          }
          className="w-full aspect-video rounded-t-[10px] object-contain"
        />
        <div className="absolute inset-0 bg-gradient-to-b from-white/0 to-white"></div>
      </div>
      <div className="px-2.5 gap-4 flex flex-col">
        <div className="w-full flex items-center justify-between">
          <p className="text-body-xl font-space-grotesk text-surface-700 font-semibold">
            {missionName}
          </p>
          <div className="w-fit flex flex-wrap gap-4">
            {rewards?.map((reward) => <ScoreCard score={reward.name} />)}
          </div>
        </div>
        {!completed && (
          <div className="flex flex-col gap-1">
            <LineProgress percentage={completedPercentage} />
            <div className="w-full flex justify-between">
              <p className="text-body-sm font-space-grotesk text-surface-500 font-regular">
                Next up: {nextTaskName}
              </p>
              <p className="text-body-sm font-space-grotesk text-surface-500 font-regular">
                {`${tasksFinished} / ${tasksTotal}`} ({completedPercentage}%)
              </p>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default OngoingMissionCard;
