import Navbar from "../../components/Navbar";
import { SegmentInfo, AuthSection } from "../../components/Landing";
import { useAuthContext } from "../../hooks";
import { Navigate } from "react-router-dom";
import Button from "../../components/Button";
import { useUserManagementContext } from "../../hooks/useUserManagementContext";
import { useRef, useState } from "react";

const Landing = () => {
  const { setActiveAuth } = useUserManagementContext();
  const { user } = useAuthContext();
  const [activeSection, setActiveSection] = useState("followers");
  const authSectionRef = useRef<HTMLDivElement>(null);

  if (user) {
    return <Navigate to="/dashboard" />;
  }

  return (
    <div
      className="w-full h-full flex-1 flex flex-col"
      style={{
        background: "linear-gradient(180deg, #E4FBFF -5.3%, #FFFFFF 22.59%)",
      }}
    >
      <Navbar transparent />

      <div className="flex flex-col items-center justify-center pt-20 mb-12 mmd:pt-12 mmd:pb-2">
        <div className="flex flex-col justify-center items-center mmd:flex-col">
          <div className="flex flex-row justify-center mmd:flex-col gap-24 mmd:gap-0">
            <SegmentInfo
              segment="followers"
              onLearnMoreClick={() => {
                setActiveSection("followers");
                authSectionRef.current?.scrollIntoView({
                  behavior: "smooth",
                  block: "start",
                });
              }}
            />
            <SegmentInfo
              segment="creators"
              className="mmd:mt-12"
              onLearnMoreClick={() => {
                setActiveSection("creators");
                authSectionRef.current?.scrollIntoView({
                  behavior: "smooth",
                  block: "start",
                });
              }}
            />
          </div>

          <Button
            className="mt-28 w-[210px] py-4 mmd:mt-16"
            onClick={() => setActiveAuth("signup")}
          >
            Create Account
          </Button>
        </div>
        <AuthSection
          ref={authSectionRef}
          activeSection={activeSection}
          setActiveSection={setActiveSection}
        />
      </div>
    </div>
  );
};

export default Landing;
