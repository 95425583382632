import { TabBar } from "./TabBar";
import { useUserManagementContext } from "../../hooks/useUserManagementContext";

export const ActionSection = ({ color }: { color: string }) => {
  const { setActiveAuth } = useUserManagementContext();
  return (
    <div className=" flex flex-col justify-center items-start mmd:mt-12 mmd:w-full">
      <TabBar
        tabs={[
          { label: "Sign in", value: "signin" },
          { label: "Sign up", value: "signup" },
        ]}
        activeTab={"signin"}
        setActiveTab={(value) => {
          setActiveAuth(value);
        }}
        color={color}
        tabClassName="!py-1"
      />

      <div className="bg-white rounded-xl mt-10 w-[400px]  mmd:w-full">
        <img src="/auth.png" alt="auth-bg" className="w-full h-full" />
      </div>
    </div>
  );
};
