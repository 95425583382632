export const ArrowRightIcon = (
  props: {
    color?: string;
    className?: string;
  } = {},
) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4 11.25H19.9002V12.75H4V11.25Z"
      fill={props.color ?? "white"}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.6387 18.0225L18.6865 11.9997L12.6387 5.97798L13.6971 4.91504L20.8123 11.9996L13.6972 19.0853L12.6387 18.0225Z"
      fill={props.color ?? "white"}
    />
  </svg>
);

export const ArrowLeftIcon = (
  props: { color?: string; className?: string } = {},
) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20 12.75L4.09978 12.75V11.25L20 11.25V12.75Z"
      fill={props.color ?? "white"}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.3613 5.97745L5.31353 12.0003L11.3613 18.022L10.3029 19.085L3.18773 12.0004L10.3028 4.91465L11.3613 5.97745Z"
      fill={props.color ?? "white"}
    />
  </svg>
);
