import { ReactNode } from "react";
import { Path } from "react-router-dom";

export interface AuthFormHeaderTypes {
  title: string;
  subtitle: string;
}

export interface AuthFormFooterTypes {
  actionText: string;
  linkText: string;
  linkUrl: string | Partial<Path>;
}

export interface AuthFormContainerTypes {
  children: ReactNode;
}

const AuthHeader = ({ title, subtitle }: AuthFormHeaderTypes) => (
  <div className="flex flex-col text-center">
    <h3 className="title-xl-bold first-letter:uppercase">{title}</h3>
    <p className="body-lg text-surface-700 first-letter:uppercase">
      {subtitle}
    </p>
  </div>
);

export default AuthHeader;
