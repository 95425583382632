import { dummyTableColumns } from "../../assets/dummyData";
import Table from "../../components/Table";
import { useImpersonateUser } from "../../hooks";
import { useAllUser } from "../../hooks/useAllUser";
import { useMemo, useState, useEffect, useRef, useCallback } from "react";
import { Loader } from "../../components";
import SearchFilter from "../../components/FilterMissions/SearchFilter";
import { debounce } from "lodash";

const FilterMissions = ({
  setFilters,
}: {
  setFilters: (filters: { search: string }) => void;
}) => {
  const debouncedSetSearch = debounce((value: string) => {
    setFilters({ search: value });
  }, 300);

  return (
    <div>
      <SearchFilter
        name="filter_by_text"
        onChange={(e: any) => {
          debouncedSetSearch(e.target.value);
        }}
        placeholder="Search By Email"
      />
    </div>
  );
};

const Test = () => {
  const observerTarget = useRef(null);
  const { mutate: impersonateUser } = useImpersonateUser();
  const [filters, setFilters] = useState({
    search: "",
  });

  const { data, fetchNextPage, hasNextPage, isFetchingNextPage, isLoading } =
    useAllUser(filters);

  // Add intersection observer handler
  const handleObserver = useCallback(
    (entries: IntersectionObserverEntry[]) => {
      const [target] = entries;
      if (target.isIntersecting && hasNextPage && !isFetchingNextPage) {
        fetchNextPage();
      }
    },
    [fetchNextPage, hasNextPage, isFetchingNextPage],
  );

  // Add useEffect for intersection observer
  useEffect(() => {
    const element = observerTarget.current;
    const option = {
      threshold: 0,
    };

    const observer = new IntersectionObserver(handleObserver, option);
    if (element) observer.observe(element);

    return () => {
      if (element) observer.unobserve(element);
    };
  }, [handleObserver]);

  const dataReformed = useMemo(() => {
    const allUsers = data?.pages.flatMap((page) => page.users) || [];
    return allUsers
      ?.map((el: any) => ({
        ...el,
        completedTasks: "3/8",
        createdCommunities: el.createdCommunities
          .map((community: any) => community.name)
          .join(", "),
        logIn: "log In",
      }))
      .sort((a: any, b: any) => {
        const aHasCommunities =
          a.createdCommunities && a.createdCommunities.trim() !== "";
        const bHasCommunities =
          b.createdCommunities && b.createdCommunities.trim() !== "";
        return bHasCommunities - aHasCommunities;
      });
  }, [data]);

  const impersonateHandle = (userId: string) => {
    if (!userId) return;

    impersonateUser(userId, {
      onSuccess: ({ token }) => {
        window.open(`${window.location.origin}/?token=${token}`, "_blank");
      },
    });
  };

  return (
    <div className="px-56 py-5 flex items-center justify-center">
      <div className="flex flex-col gap-4" style={{ width: "800px" }}>
        <div style={{ width: "60%" }}>
          <FilterMissions setFilters={setFilters} />
        </div>
        {isLoading ? (
          <Loader size="lg" fullScreen />
        ) : (
          <>
            <div style={{ width: "100%" }}>
              <Table
                columns={dummyTableColumns}
                rows={dataReformed as any}
                action={(data) => impersonateHandle(data._id)}
              />
            </div>
            <div ref={observerTarget} className="h-10" />
            {isFetchingNextPage && (
              <div className="self-center">
                <Loader size="lg" />
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default Test;
