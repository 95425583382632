import { useInfiniteQuery } from "@tanstack/react-query";
import { useAPI } from "../useApi";
import { QUERY_KEYS } from "../../constants";
import { User } from "../../types";

export interface UsersResponse {
  users: User[];
  currentPage: number;
  totalPages: number;
}

export const useAllUser = (filters: {
  search?: string;
  limit?: number;
  // Add any other relevant filter fields for users
}) => {
  const { post } = useAPI();

  return useInfiniteQuery<UsersResponse, Error>({
    queryKey: [QUERY_KEYS.ALL_USERS, filters],
    queryFn: async ({ pageParam = 1 }) => {
      try {
        const response = await post("/api/user/filter", {
          page: pageParam,
          limit: filters.limit ? filters.limit : 10,
          search: filters.search,
        });

        if (
          !response ||
          typeof response !== "object" ||
          !response.currentPage
        ) {
          throw new Error("Invalid API response");
        }
        return response;
      } catch (error) {
        console.error("Error fetching users:", error);
        throw error;
      }
    },
    getNextPageParam: (lastPage) => {
      if (!lastPage || !lastPage.currentPage || !lastPage.totalPages) {
        return null;
      }
      const { currentPage, totalPages } = lastPage;
      return currentPage < totalPages ? currentPage + 1 : null;
    },
    initialPageParam: 1,
  });
};
