import { useQuery } from "@tanstack/react-query";
import { useAPI } from "../useApi";
import { QUERY_KEYS } from "../../constants";

export const useCommunityAnalytics = ({
  _id,
}: {
  _id: string | undefined;
  includeInactive?: boolean;
  includeUnlaunched?: boolean;
  includeOversubscribed?: boolean;
}) => {
  const { get } = useAPI();

  return useQuery({
    queryKey: [QUERY_KEYS.COMMUNITY],
    queryFn: () => _id && get(`/api/community/${_id}/stats`),
  });
};
