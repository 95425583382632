import { useMutation, useQueryClient } from "@tanstack/react-query";
import { QUERY_KEYS } from "../../constants";
import { useAPI } from "../useApi";
import { Mission, Task, TaskStatus } from "../useCommunity";
import { useErrorHandler } from "../useErrorHandler";

export const absoluteLink = (link: string) => {
  return link.startsWith("http://") || link.startsWith("https://")
    ? link
    : `https://${link}`;
};

const updatedMission = (
  mission: Mission,
  taskId: string,
  taskStatus: TaskStatus,
) => {
  const res = {
    ...mission,
    tasks: mission.tasks.map((elem: Task) => {
      return elem._id === taskId
        ? { ...elem, status: taskStatus }
        : { ...elem };
    }),
  };
  if (res.tasks.every((task: Task) => task.status === TaskStatus.COMPLETED)) {
    res.completedAt = new Date().toLocaleString();
  }
  return res;
};

const updatedMissionWithMultipleTasks = (mission: Mission, data: any) => {
  const updatedTasks = mission.tasks.map((task: Task) => {
    const update = data.results.find(
      (result: any) => result.taskId === task._id,
    );
    return update ? { ...task, status: update.status } : task;
  });

  const updatedMission = {
    ...mission,
    tasks: updatedTasks,
    lastCheckTime: data.lastCheckTime,
    completedAt: data.allTasksCompleted
      ? new Date().toLocaleString()
      : mission.completedAt,
  };

  return updatedMission;
};

export const useStartTask = () => {
  const { post } = useAPI();
  const queryClient = useQueryClient();
  const { handleApiError } = useErrorHandler();

  return useMutation({
    mutationFn: async (data: any) => post(`/api/task/start/${data._id}`, {}),
    onSuccess: (data, variables) => {
      window.open(
        absoluteLink(
          variables.type === "X tweet"
            ? `https://x.com/intent/post?text=${variables.link}`
            : variables.link,
        ),
        "_blank",
        "noopener,noreferrer",
      );
      queryClient.setQueryData([QUERY_KEYS.USER_MISSION], (oldData: Mission) =>
        updatedMission(oldData, variables._id, TaskStatus.IN_PROGRESS),
      );
    },
    onError: (error) => {
      handleApiError(error);
    },
  });
};

export const useCheckAllTasks = () => {
  const { post } = useAPI();
  const queryClient = useQueryClient();
  const { handleApiError } = useErrorHandler();
  return useMutation({
    mutationFn: async (data: any) =>
      post(`/api/task/check/all/${data.missionId}`, {}),
    onSuccess: (data, variables) => {
      queryClient.setQueryData([QUERY_KEYS.USER_MISSION], (oldData: Mission) =>
        updatedMissionWithMultipleTasks(oldData, data),
      );
      queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.USER_REWARDS] });
      // queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.USER_MISSION] });
    },
    onError: (error, variables) => {
      handleApiError(error);
      queryClient.setQueryData([QUERY_KEYS.USER_MISSION], (oldData: Mission) =>
        updatedMission(oldData, variables._id, TaskStatus.FAILED),
      );
    },
  });
};
