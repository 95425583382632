import { useState } from "react";
import { useAPI } from "../useApi";

export const useFileUpload = () => {
  const [isUploading, setIsUploading] = useState(false);
  const { post } = useAPI();

  const uploadFile = async (file: File) => {
    setIsUploading(true);
    try {
      const data = await post("/api/file/get-upload-url", {
        fileName: file.name,
        contentType: file.type,
      });

      const uploadUrl = data.uploadUrl;
      const response = await fetch(uploadUrl, { method: "PUT", body: file });

      setIsUploading(false);
      if (response.ok) {
        return { success: true, fileUrl: data.fileUrl };
      } else {
        throw new Error("Failed to upload file");
      }
    } catch (error) {
      console.error("Error uploading file:", error);
      setIsUploading(false);
      return { success: false };
    }
  };

  return { uploadFile, isUploading };
};
