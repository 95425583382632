import useMission from "../../../hooks/useUserMissions/useMission";
import MissionCard from "../../CommunityPreviewCard/MissionCard";
import EditMissionBody from "./EditMissionBody";
import { Loader } from "../../Loader";
import { useParams, useNavigate } from "react-router-dom";
const dummyImage =
  "https://s3-alpha-sig.figma.com/img/902c/5110/167cbdd56b840b66989e8bb19cbc603e?Expires=1732492800&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=nuXU~Azjx2-W6VWgAdeQpS0R2uweyLkSMHqaVxFuo34fmiupI3JFckzMrTUOFVTLn7yPdb8ZGh-LiLXUor4-Y-sJkZAzsCEK-nj9CLjE6ypFjDAYtaXppzHF3xCXWeRdVE4dyYqBSGCPikeNLMuvtKjcqME2KW46MAfea-UjG2eY4I61Vf4OjeFVyKMBShfPTujhaM3ZVBqTnhaYHrlmmaznNmsuZEddQPkdqlOXV5DTjYBizetbAf7s9SNrI8fMSqHiBmvNLODXzzNdtoxEAwm40V73nXV13-lcpUVWlI0Huoyl2S3QkAXFfIovLpj~53tMUaSfScoieExDsWyrDQ__";

const EditMission = () => {
  const { missionId } = useParams();
  const navigate = useNavigate();
  const { data, isLoading, isRefetching } = useMission({ _id: missionId });

  if (isLoading || isRefetching) return <Loader size="lg" fullScreen />;

  return (
    <div className="flex mmd:flex-col-reverse mmd:gap-6 mmd:p-4">
      <div className="flex flex-col bg-surface-50 rounded-xl mx-6 p-6 gap-6 flex-[3] mmd:p-2 mmd:mx-0">
        <EditMissionBody data={data} goBack={() => navigate(-1)} />
      </div>

      <div className="mr-10 flex-[2] mmd:mr-0">
        <MissionCard
          _id={data?._id}
          title={data?.name}
          imageUrl={data?.imageUrl || dummyImage}
          rewards={data?.rewards}
          description={data?.description}
          tags={data?.tags}
        />
      </div>
    </div>
  );
};

export default EditMission;
