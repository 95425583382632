const ArrowDown = () => {
  return (
    <svg
      width="14"
      height="8"
      viewBox="0 0 14 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.42345 7.77997L0.951172 1.30766L2.03886 0.219971L7.42345 5.60459L12.8081 0.219971L13.8958 1.30766L7.42345 7.77997Z"
        fill="#202327"
      />
    </svg>
  );
};

export default ArrowDown;
