import { useEffect, useMemo, useState } from "react";
import ProfileManagement from "../../components/UserManagement/ProfileManagement";
import WalletManagement from "../../components/UserManagement/WalletManagement";
import LeftNavbar from "../../components/LeftNavbar";
import ProfileIcon from "../../assets/icons/profile";
import WalletIcon from "../../assets/icons/wallet";
import HeaderWithLeftNavbar from "../../components/Navbar/HeaderWithLeftNavbar";
import { useScreenSize } from "../../hooks";
import Navbar from "../../components/Navbar";
import { useUserManagementContext } from "../../hooks/useUserManagementContext";
import ItemsIcon from "../../assets/icons/items";
import ItemsManagement from "../../components/UserManagement/ItemsManagement";
import { useSearchParams } from "react-router-dom";

type TabKeys = "Profile" | "Wallet" | "My Items";

const UserManagement = () => {
  const { isMobile } = useScreenSize();

  const { activeTab, setActiveTab } = useUserManagementContext() as {
    activeTab: TabKeys;
    setActiveTab: (data: string | undefined) => void;
  };
  const [ActiveComponent, setActiveComponent] = useState<JSX.Element | null>(
    null,
  );
  const [searchParams] = useSearchParams();
  const defaultTab = searchParams.get("tab");

  const componentMapper = useMemo(
    () => ({
      Profile: <ProfileManagement />,
      Wallet: <WalletManagement />,
      "My Items": <ItemsManagement />,
    }),
    [],
  );

  const navbarItems = [
    {
      label: "Profile",
      Icon: ProfileIcon,
    },
    {
      label: "Wallet",
      Icon: WalletIcon,
    },
    {
      label: "My Items",
      Icon: ItemsIcon,
    },
  ];

  useEffect(() => {
    if (defaultTab) {
      setActiveTab(defaultTab);
    }
  }, [defaultTab]);

  useEffect(() => {
    setActiveComponent(componentMapper[activeTab]);
  }, [activeTab, componentMapper]);

  return (
    <div className="w-full flex h-screen bg-surface-100">
      {!isMobile ? (
        <div className="min-w-60">
          <LeftNavbar
            navbarItems={navbarItems}
            activeTab={activeTab}
            setActiveTab={setActiveTab}
          />
        </div>
      ) : null}
      <div className="flex flex-col w-full max-h-screen overflow-auto">
        {isMobile ? <Navbar /> : <HeaderWithLeftNavbar />}
        {ActiveComponent || <div>Component not found</div>}
      </div>
    </div>
  );
};

export default UserManagement;
