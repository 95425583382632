const AnalyticsIcon = (props) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M6.02229 3.02832H13.9765C15.7567 3.02832 17.1998 4.47143 17.1998 6.25158V10.6168C17.1998 12.3969 15.7567 13.84 13.9765 13.84H6.02229C4.24292 13.84 2.7998 12.3969 2.7998 10.6168V6.25158C2.7998 4.47143 4.24292 3.02832 6.02229 3.02832Z"
      stroke={props.color || "#202327"}
      strokeWidth="1.44"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.10352 6.76562V10.1018M12.8958 7.45051V10.1009M10.1555 8.53448V10.1014"
      stroke={props.color || "#202327"}
      strokeWidth="1.44"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.04492 16.9717H13.9556"
      stroke={props.color || "#202327"}
      strokeWidth="1.44"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.30772 13.8408L7.79785 16.9746M11.6928 13.8408L12.2026 16.9746"
      stroke={props.color || "#202327"}
      strokeWidth="1.44"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default AnalyticsIcon;
