import { useQuery } from "@tanstack/react-query";
import { useAPI } from "../useApi";
import { QUERY_KEYS } from "../../constants";

const useGetMissionStats = ({ _id }: { _id: string }) => {
  const { get } = useAPI();

  const { data, isLoading } = useQuery({
    queryKey: [QUERY_KEYS.MISSION_STATS, _id],
    queryFn: () => _id && get(`/api/mission/${_id}/stats`),
  });

  return {
    data,
    isLoading,
  };
};

export default useGetMissionStats;
