import { createContext, useState } from "react";
import { Reward } from "../hooks/useCommunity";

export const RewardContext = createContext<any>(null);

export const RewardProvider = ({ children }: { children: React.ReactNode }) => {
  const [activeRedeemableReward, setActiveRedeemableReward] = useState<
    Reward | undefined
  >();

  return (
    <RewardContext.Provider
      value={{
        activeRedeemableReward,
        setActiveRedeemableReward,
      }}
    >
      {children}
    </RewardContext.Provider>
  );
};
