import classNames from "classnames";
import { CupIcon } from "../../assets/icons";
import { Reward } from "../../hooks/useCommunity";
import Button, { ButtonType } from "../Button";
import { useContext } from "react";
import { RewardContext } from "../../context/RewardContext";
import LockIcon from "../../assets/icons/lock";

export const RewardCard = ({
  reward,
  direction,
  hideButton = false,
  status = "redeemable",
}: {
  reward: Reward;
  direction?: "row" | "col";
  hideButton?: boolean;
  status?: string;
}) => {
  const { setActiveRedeemableReward } = useContext(RewardContext);
  const rewardsCardStyle = classNames(
    " flex p-2 bg-surface-800 border-1 border-surface-800 rounded-2xl items-center gap-4  mmd:gap-4",
    {
      "md:min-w-[500px]": direction === "row" || hideButton,
      "md:min-w-[400px]": direction === "col" && !hideButton,
      "mmd:flex-col mmd:items-start": !hideButton,
    },
  );

  const rewardNameStyle = classNames(
    "text-md font-semibold text-surface-50 flex-1 ",
    {
      "": direction === "row" || hideButton,
      "break-words": direction === "col" && !hideButton,
    },
  );

  return (
    <div className={rewardsCardStyle}>
      <div className="flex flex-row items-center gap-4 flex-1 mr-2">
        <img
          src={reward.rewardImage ? reward.rewardImage : reward.rewardEmoji}
          alt={reward.name}
          className="w-16 h-16 rounded-2xl"
        />
        <p className={rewardNameStyle}>{reward.name}</p>
      </div>
      {!hideButton && (
        <>
          <button className="flex rounded-xl py-2 px-3 mmd:hidden">
            <Button
              category={ButtonType.SECONDARY}
              action={() => setActiveRedeemableReward({ ...reward, status })}
            >
              <p>{status === "redeemable" ? "Redeem" : "Redeemed"}</p>
            </Button>
          </button>

          <div className="flex-row items-center gap-4 flex-1 hidden mmd:flex bg-surface-700 p-2 rounded-md w-full justify-start">
            <div className="w-9 h-9 flex items-center justify-center bg-[#FF9100] rounded-full">
              <CupIcon fill="white" />
            </div>
            <span className="text-sm font-semibold text-surface-50 flex-1">
              Reward Code
            </span>
            <button className="bg-white rounded-xl py-2 px-3 ">
              <Button
                category={ButtonType.SECONDARY}
                action={() => setActiveRedeemableReward({ ...reward, status })}
              >
                <p>{status === "redeemable" ? "Redeem" : "Redeemed"}</p>
              </Button>
            </button>
          </div>
        </>
      )}
      {hideButton && (
        <div className="bg-surface-250 px-4 py-2.5 rounded-lg h-full flex items-center justify-center items-center justify-center">
          <LockIcon />
        </div>
      )}
    </div>
  );
};
