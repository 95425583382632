import { useAuthContext } from "../../hooks";
import UserCard from "./UserCard";
import BoonModal from "../Modal";
import { useState } from "react";
import EditUser from "./EditUser";
import EditButton from "../Button/EditButton";
import { TwitterIcon } from "../../assets/icons";
import Button, { ButtonType } from "../Button";
import useTwitterOauth from "../../hooks/useTwitterOauth";
import { Loader } from "../Loader";
const SocialMedia = ({ socialMediaName, socialMediaUrl }: any) => (
  <div className="flex flex-col gap-2 w-1/2">
    <p className="body-lg text-surface-500">{socialMediaName}</p>
    <p className="body-md-bold text-surface-900">{socialMediaUrl}</p>
  </div>
);

const ProfileManagement = () => {
  const { user, isValidating } = useAuthContext();
  const [editModal, setEditModal] = useState<string | undefined>();
  const { mutate: twitterOauth, isPending: isTwitterOauthLoading } =
    useTwitterOauth({
      onSuccess: (data) => {
        window.location.href = data.authUrl;
      },
    });

  if (isValidating) return <Loader size="lg" fullScreen />;

  if (!user) {
    return (
      <div className="w-full h-full flex justify-center items-center">
        <p className="text-body-lg font-medium">User not found</p>
      </div>
    );
  }

  return (
    <div className="bg-surface-50 flex flex-col p-6 gap-6 rounded-xl ml-6 mr-10 mmd:mt-6 mmd:mx-4">
      <p className="title-lg-medium text-surface-700">My Profile</p>
      <div className="flex flex-col gap-4">
        <div className="flex justify-between p-6 border border-solid border-surface-250 rounded-xl mmd:flex-col">
          <UserCard
            name={user.name}
            email={user.email}
            surname={user.surname}
            profilePicture={user.profilePicture}
          />
          <EditButton
            text="Edit Profile"
            classNames="items-center mmd:mt-2 mmd:ml-[84px]"
            onClick={() => setEditModal("profile")}
          />
        </div>

        <div className="flex justify-between p-6 border border-solid border-surface-250 rounded-xl mmd:flex-col">
          <p className="body-xl-medium text-surface-700 flex items-center">
            Password
          </p>
          <EditButton
            text="Edit Password"
            classNames="items-center mmd:mt-2"
            onClick={() => setEditModal("password")}
          />
        </div>

        <div className="flex flex-col p-6 border border-solid border-surface-250 rounded-xl gap-2.5">
          <p className="body-xl-medium text-surface-700">Social Media</p>
          <div className="flex gap-2.5 mmd:flex-col">
            <div className="grid grid-cols-2 gap-4 w-full mmd:flex mmd:flex-col">
              {user.socialNetworkLinks?.map((elem, index) => (
                <div key={index}>
                  <SocialMedia
                    socialMediaName={elem.networkName}
                    socialMediaUrl={elem.url}
                  />
                </div>
              ))}
            </div>
            <EditButton
              text="Edit"
              classNames="items-start mmd:mt-2"
              onClick={() => setEditModal("socialMedia")}
            />
          </div>
        </div>
        <div>
          <Button
            category={ButtonType.SECONDARY}
            onClick={() => {
              twitterOauth();
            }}
          >
            {user.isTwitterConnected ? (
              <div className="flex items-center gap-2 p-2">
                ✅ <TwitterIcon /> Connected To Twitter(X) - Click To Reconnect
              </div>
            ) : isTwitterOauthLoading ? (
              <Loader size="md" />
            ) : (
              <div className="flex items-center gap-2 p-2">
                <TwitterIcon /> Connect To Twitter(X)
              </div>
            )}
          </Button>
        </div>
        {editModal && (
          <BoonModal
            onClose={() => setEditModal(undefined)}
            options={{
              mobileMinVh: editModal === "password" ? "42vh" : undefined,
            }}
          >
            <EditUser
              editModal={editModal}
              closeModal={() => setEditModal(undefined)}
            />
          </BoonModal>
        )}
      </div>
    </div>
  );
};

export default ProfileManagement;
