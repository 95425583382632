import * as React from "react";
const CupIcon = (props) => (
  <svg
    width={18}
    height={18}
    viewBox="0 0 18 18"
    xmlns="http://www.w3.org/2000/svg"
    fill="#202327"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.25252 2.52035C4.35802 2.41479 4.50114 2.35547 4.65039 2.35547H13.7258C14.0364 2.35547 14.2883 2.60731 14.2883 2.91797H13.7258C14.2883 2.91797 14.2883 2.91776 14.2883 2.91797V2.92068L14.2882 2.92632L14.2881 2.9462C14.288 2.9632 14.2877 2.98764 14.2871 3.01903C14.2859 3.0818 14.2835 3.1724 14.279 3.28687C14.2697 3.51568 14.2514 3.84061 14.2149 4.2298C14.1422 5.00551 13.996 6.04903 13.7003 7.10008C13.4064 8.1444 12.9533 9.2385 12.2422 10.0811C11.6222 10.8155 10.8022 11.3603 9.74828 11.5031V12.438H11.6182C11.8628 12.438 12.0794 12.5961 12.1539 12.8291L12.6943 14.5178H13.4966C13.8072 14.5178 14.0591 14.7696 14.0591 15.0803C14.0591 15.3909 13.8072 15.6428 13.4966 15.6428H12.3006C12.2892 15.6431 12.2779 15.6431 12.2666 15.6428H6.21755C6.20627 15.6431 6.19493 15.6431 6.18358 15.6428H4.98748C4.67682 15.6428 4.42498 15.3909 4.42498 15.0803C4.42498 14.7696 4.67682 14.5178 4.98748 14.5178H5.78986L6.33023 12.8291C6.40478 12.5961 6.62135 12.438 6.86597 12.438H8.62328V11.5018C7.57373 11.3571 6.75652 10.8134 6.13807 10.0811C5.42653 9.23873 4.97285 8.1447 4.6784 7.10038C4.38205 6.04935 4.23527 5.00585 4.16204 4.23014C4.12529 3.84096 4.10678 3.51603 4.09743 3.28722C4.09276 3.17275 4.09037 3.08215 4.08916 3.01939C4.08855 2.988 4.08824 2.96356 4.08807 2.94655L4.08792 2.92668L4.0879 2.92104L4.08789 2.91932C4.08789 2.9191 4.08789 2.91833 4.65039 2.91797L4.08789 2.91833C4.08779 2.76909 4.14701 2.62592 4.25252 2.52035ZM5.23321 3.48047C5.24346 3.66121 5.25888 3.87897 5.28206 4.1244C5.35148 4.85985 5.48941 5.83122 5.76118 6.79508C6.03484 7.76565 6.43271 8.6865 6.99753 9.3552C7.54823 10.0072 8.25008 10.415 9.19028 10.415C10.1306 10.415 10.8323 10.0073 11.3825 9.35535C11.9469 8.68673 12.3443 7.76588 12.6173 6.79537C12.8885 5.83155 13.0259 4.86018 13.0949 4.12474C13.1179 3.87916 13.1332 3.66128 13.1433 3.48047H5.23321ZM9.165 13.563H7.27657L6.97106 14.5178H11.5131L11.2076 13.563H9.20655C9.19965 13.5632 9.19268 13.5634 9.18578 13.5634C9.1788 13.5634 9.1719 13.5632 9.165 13.563Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.89587 4.17654C1.9278 3.89194 2.16847 3.67676 2.45486 3.67676H4.72808C5.03873 3.67676 5.29058 3.9286 5.29058 4.23926C5.29058 4.54992 5.03873 4.80176 4.72808 4.80176H3.00226C3.00959 5.05209 3.03658 5.37334 3.10888 5.72299C3.275 6.52634 3.66655 7.42294 4.54844 8.00355C4.80791 8.17433 4.87979 8.52315 4.70896 8.78265C4.53813 9.04215 4.18931 9.114 3.92983 8.94315C2.70781 8.1387 2.20723 6.91822 2.00718 5.9508C1.90658 5.46428 1.8785 5.02736 1.87532 4.71172C1.87373 4.55333 1.87839 4.42391 1.88366 4.33212C1.8863 4.28619 1.8891 4.24956 1.89136 4.2233C1.89249 4.21017 1.89349 4.19961 1.89427 4.19178L1.89527 4.18208L1.89562 4.17879L1.89575 4.17753L1.89587 4.17654C1.8959 4.1763 1.89587 4.17654 2.45486 4.23926L1.89587 4.17654Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.084 4.23926C13.084 3.9286 13.3358 3.67676 13.6465 3.67676H15.9197C16.2061 3.67676 16.4468 3.89194 16.4787 4.17654L15.9197 4.23926C16.4787 4.17654 16.4787 4.17631 16.4787 4.17654L16.4789 4.17879L16.4793 4.18208L16.4803 4.19178C16.4811 4.19961 16.4821 4.21017 16.4832 4.2233C16.4855 4.24956 16.4882 4.28619 16.4909 4.33212C16.4962 4.42391 16.5008 4.55333 16.4993 4.71172C16.496 5.02736 16.468 5.46428 16.3674 5.9508C16.1673 6.91822 15.6668 8.1387 14.4447 8.94315C14.1853 9.114 13.8365 9.04215 13.6656 8.78265C13.4948 8.52315 13.5667 8.17433 13.8261 8.00355C14.708 7.42294 15.0996 6.52634 15.2657 5.72299C15.338 5.37334 15.365 5.05209 15.3723 4.80176H13.6465C13.3358 4.80176 13.084 4.54992 13.084 4.23926Z"
    />
  </svg>
);
export default CupIcon;
