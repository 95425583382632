import classNames from "classnames";
import { TextInputProps } from "../TextInput";
import SearchIcon from "../../assets/icons/search";

const SearchFilter = ({
  label,
  className,
  errorMessage,
  type,
  registerInForm = () => ({}),
  name,
  validation,
  ...inputProps
}: TextInputProps) => {
  const inputClasses = classNames(
    `${className} text-sm w-full outline-none py-2 px-4 rounded-[8px] transition-all duration-300 ease-in-out focus:shadow-md hover:scale-101 bg-white placeholder:body-md placeholder:capitalize hover:bg-surface-150 [&_input]:hover:bg-surface-150`,
    {
      "border-2 border-red-500 opacity-100": errorMessage,
      "border-2 border-surface-250 opacity-80": !errorMessage,
    },
  );

  const errorClasses = classNames(
    "text-red-500 text-sm font-medium mb-3 transition-opacity duration-300 ease-in-out",
    {
      "opacity-100": errorMessage,
      "opacity-0": !errorMessage,
    },
    "pl-2",
  );

  return (
    <div className="flex flex-col gap-2 items-start w-full">
      {label && (
        <p className="body-lg font-medium text-surface-700 ${labelClassName}">
          {label}
        </p>
      )}
      <div className={`${inputClasses} flex items-center gap-2`}>
        <SearchIcon />
        <input
          className="w-full outline-none duration-300 ease-in-out"
          type={type || "text"}
          {...registerInForm(name, validation)}
          {...inputProps}
        />
      </div>
      {errorMessage ? (
        <p className={errorClasses}>{errorMessage || "\u00A0"}</p>
      ) : (
        ""
      )}
    </div>
  );
};

export default SearchFilter;
