import { SocialNetworkLink } from "../../types";
import YoutubeIcon from "../../assets/icons/youtube";
import TikTokIcon from "../../assets/icons/tiktok";
import InstagramIcon from "../../assets/icons/instagram";
import TwitterIcon from "../../assets/icons/twitter";
import DiscordIcon from "../../assets/icons/discord";
import TrashIcon from "../../assets/icons/trash";
import { SingleDropDown } from "../Dropdown";
import { TextInput } from "../TextInput";
import { v4 } from "uuid";
import PlusIcon from "../../assets/icons/plus";
import classNames from "classnames";

interface SocialMediaEditorProps {
  socialNetworkLinks: SocialNetworkLink[];
  setSocialNetworkLinks: (data: any) => void;
  handleDelete: (prop1?: string) => void;
  handleUpdate: (
    prop1: "networkName" | "url",
    prop2: string,
    prop3?: string,
  ) => void;
  direction: string;
  required?: boolean;
}

const SocialMediaEditor = ({
  socialNetworkLinks,
  setSocialNetworkLinks,
  handleDelete,
  handleUpdate,
  direction,
  required = true,
}: SocialMediaEditorProps) => {
  const socialMediaOptions = [
    {
      display: "Youtube",
      value: "Youtube",
      Icon: YoutubeIcon,
    },
    {
      display: "Tik Tok",
      value: "Tik Tok",
      Icon: TikTokIcon,
    },
    {
      display: "Instagram",
      value: "Instagram",
      Icon: InstagramIcon,
    },
    {
      display: "Twitter",
      value: "Twitter",
      Icon: TwitterIcon,
    },
    {
      display: "Discord",
      value: "Discord",
      Icon: DiscordIcon,
    },
    {
      display: "Other",
      value: "Other",
    },
  ];

  return (
    <div className="flex flex-col gap-2">
      <p className="body-lg text-surface-900">
        Link to socials {required ? "*" : ""}
      </p>
      {socialNetworkLinks?.map((elem) => (
        <div
          key={`${elem.networkName}-${elem._id}`}
          className="flex gap-3 items-center"
        >
          <div
            className={classNames("flex w-full gap-2", {
              "flex-col": direction === "col",
            })}
          >
            <div
              className={`${direction === "col" ? "w-full items-end" : "w-1/3 items-end"} flex`}
            >
              <SingleDropDown
                placeholder="Social Media"
                data={socialMediaOptions}
                name={`networkName-${elem._id}`}
                value={elem.networkName}
                setValueForm={(name, value) => {
                  console.log("NAME: ", name);
                  console.log("VALUE: ", value);
                  console.log("ID: ", elem._id);
                  handleUpdate("networkName", value, elem._id);
                }}
              />
            </div>
            <div className={direction === "col" ? "w-full" : "w-2/3"}>
              <TextInput
                name={`networkUrl-${elem._id}`}
                defaultValue={elem.url}
                placeholder="Link"
                validation={{ required: "Old Password is required" }}
                labelClassName="body-lg text-surface-900"
                onChange={(e: any) =>
                  handleUpdate("url", e.target.value, elem._id)
                }
              />
            </div>
          </div>
          <TrashIcon
            width={24}
            height={24}
            color="#8E979F"
            className="cursor-pointer"
            onClick={() => handleDelete(elem._id)}
          />
        </div>
      ))}
      <div
        className="flex items-center justify-center gap-2"
        onClick={() =>
          setSocialNetworkLinks([
            ...socialNetworkLinks,
            { _id: v4(), networkName: "", url: "" },
          ])
        }
      >
        <PlusIcon color="#9382FF cursor-pointer" />
        <p className="body-md text-primary-500 cursor-pointer">Add new Link</p>
      </div>
    </div>
  );
};

export default SocialMediaEditor;
