import * as React from "react";
const RocketIcon = (props) => (
  <svg
    width={18}
    height={18}
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.2781 10.0369L9.15634 12.3128C8.97979 12.454 8.76859 12.6342 8.61867 12.764C8.49214 12.8735 8.33487 12.9413 8.16829 12.9561L7.43105 13.0217C7.19997 13.0423 6.97166 12.9593 6.80761 12.7953L5.5029 11.4906C5.3549 11.3426 5.27227 11.1416 5.27345 10.9322L5.2781 10.1086C5.27916 9.92004 5.34819 9.73854 5.471 9.59551C5.60228 9.44266 5.77653 9.23716 5.91172 9.06811L8.18757 5.94631C9.73489 4.01215 12.0775 2.88623 14.5545 2.88623C14.9874 2.88623 15.3382 3.23713 15.3382 3.67C15.3382 6.14692 14.2123 8.48956 12.2781 10.0369Z"
      stroke="white"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.6532 9.73828V13.1728C12.6532 13.4696 12.4855 13.741 12.2199 13.8738L10.3265 14.8206C10.1232 14.9221 9.88592 14.9306 9.676 14.8436C9.46607 14.7567 9.30423 14.5829 9.23238 14.3674L8.73438 12.8734"
      stroke="white"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5.35411 9.49545L3.86014 8.99745C3.64459 8.9256 3.47083 8.76375 3.38388 8.5539C3.29693 8.34397 3.30535 8.10667 3.40696 7.90342L4.35375 6.00991C4.4865 5.7444 4.75786 5.57666 5.05471 5.57666H8.48917"
      stroke="white"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.15669 14.1573C5.91728 15.7507 4.05209 15.4535 2.83594 15.6362C3.01864 14.4201 2.73169 12.5651 4.32503 12.3257"
      stroke="white"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11.5352 5.79939C12.1495 5.61403 12.6341 6.12022 12.5235 6.78773"
      stroke="white"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default RocketIcon;
