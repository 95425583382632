import classNames from "classnames";
import { ArrowLeftIcon, ArrowRightIcon } from "../assets/icons";

export const ControlArrows = ({
  mode = "light",
  onLeftClick,
  onRightClick,
  leftDisabled,
  rightDisabled,
  className,
}: {
  mode?: "light" | "dark";
  onLeftClick?: () => void;
  onRightClick?: () => void;
  leftDisabled?: boolean;
  rightDisabled?: boolean;
  className?: string;
}) => {
  const color = mode === "light" ? "white" : "#202327";

  if (leftDisabled && rightDisabled) return null;

  return (
    <div
      className={classNames("flex flex-row gap-4 cursor-pointer", className)}
    >
      <button
        onClick={onLeftClick}
        disabled={leftDisabled}
        className="disabled:opacity-50"
      >
        <ArrowLeftIcon color={color} />
      </button>
      <button
        onClick={onRightClick}
        disabled={rightDisabled}
        className="disabled:opacity-50"
      >
        <ArrowRightIcon color={color} />
      </button>
    </div>
  );
};
