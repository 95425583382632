import { useQuery } from "@tanstack/react-query";
import { QUERY_KEYS } from "../../constants";
import { useAPI } from "../useApi";

export interface ManagedCommunity {
  _id: string;
  name: string;
  description: string;
  logoUrl: string;
  url: string;
  stats: {
    totalMissions: number;
    enrolledUsers: number;
    sectionsCount: number;
  };
  onClick?: () => void;
}

const useManagedCommunities = () => {
  const { get } = useAPI();

  return useQuery<ManagedCommunity[]>({
    queryKey: [QUERY_KEYS.CREATED_COMMUNITIES],
    queryFn: () =>
      get(`/api/user/communities`).then((res) => res.managedCommunities),
  });
};

export default useManagedCommunities;
