import BoonModal from "../Modal";
import { CloseIcon } from "../../assets/icons";
import Button, { ButtonType } from "../Button";

const MissionCompletionModal = ({
  name,
  imageUrl,
  userName,
  onClose,
}: {
  name: string;
  imageUrl: string;
  userName: string | undefined;
  onClose: () => void;
}) => {
  return (
    <BoonModal onClose={onClose} style={{ padding: "0px", overflow: "hidden" }}>
      <div className="overflow-auto max-h-[90vh] relative">
        <CloseIcon
          color="white"
          onClick={onClose}
          className="absolute top-6 right-6 cursor-pointer"
        />
        <div className="flex flex-col p-1.5 pb-6 gap-6">
          <img src={imageUrl} className="rounded-2xl w-full aspect-video rounded-t-[10px] object-contain" />
          <div className="flex flex-col px-2.5 gap-16">
            <div className="flex flex-col gap-4">
              <div className="flex flex-col gap-4">
                <p className="title-xl-bold text-surface-900">
                  Congrats {userName}
                </p>
                <div className="flex flex-col gap-2.5">
                  <p className="body-lg text-surface-900">
                    You've completed <strong>{name}</strong> mission
                    successfully 🎉
                  </p>
                </div>
              </div>
            </div>
            <Button
              category={ButtonType.PRIMARY}
              className="py-3"
              action={onClose}
            >
              Close
            </Button>
          </div>
        </div>
      </div>
    </BoonModal>
  );
};

export default MissionCompletionModal;
