import { Community } from "../../hooks/useCommunity";
import CreateCommunity from "../CreateCommunity";

const CommunityDetails = ({ community }: { community: Community }) => {
  return (
    <div className="px-20 mmd:px-4 mmd:mt-4 mmd:mb-12">
      <CreateCommunity updater defaultValues={community} />
    </div>
  );
};
export default CommunityDetails;
