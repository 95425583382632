import { CommunityHeaderProps } from "../../components/CommunityPreviewCard/CommunityHeader";
import { RewardType } from "../../components/RewardRedemption";
import { TableColumn, TableRow } from "../../components/Table";

export const dummyCommunityProps: CommunityHeaderProps = {
  _id: "",
  numberOfTasks: 999,
  numberOfMembers: 32,
  currency: "ETH",
  title: "Azuki",
  numberOfMissionsDone: 342,
  numberOfMissions: 135,
  isUserJoined: true,
  description: `What is Lorem Ipsum? 
    Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.It is a long established 
    fact that a reader  publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many web sites still in their infancy. Various versions have evolved over the years, sometimes by accident, sometimes on purpose (injected humour and the like).`,
};

export const dummyCommunityBodyProps: any = [
  {
    missionType: "Begginer Missions",
    description:
      "All the communities you are a member of will appear here. You can join a community by clicking on the join button for a given community.",
    missions: [
      {
        reward: "200",
        title: "These are begginer missions",
        description:
          "Join the Stabull Road-to-IDO Campaign on Magic Square! Compete for a chance to win from a prize pool of $200,000 worth of $STABUL Tokens.",
        image:
          "https://s3-alpha-sig.figma.com/img/6821/7963/eaf5cb42f1b1de6d9736edc3e6b6d640?Expires=1730678400&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=MVWaUB4HQMYD9nIkL-4ufCHRbBXnn9jst7pGhjBh5XI5RNUDEJPEEimv60PlVduFQ2bzjLyOZxC~R1cNdNOYP0mIA6TxUlpkAykxedwdAQIV9kTxfHZe~j92~9GQjXsuDj-QrOY11Yfn5QIxpsyvRudolTLJkEddYYhF67JkzfkdhQU8YtRjCOzrLN-pBTl5nIQLrNp-691JBIDHYy25~DFkFxNrGKXiIBy-AaK9ebcqeeiiknifMZABuNCmu9o0ZwrFG3ntymOnagDLVxjUJId0oIIy-832xQ0iqRwTj48rVM4QH1ReFOZ-uZyOvwA~~tLVyTcSRxmg1idUKtdsKw__",
        tasks: ["Begginer Inform 1", "Begginer Inform 2", "Begginer Inform 3"],
      },
      {
        reward: "201",
        title: "These are begginer missions",
        description:
          "Join the Stabull Road-to-IDO Campaign on Magic Square! Compete for a chance to win from a prize pool of $200,000 worth of $STABUL Tokens.",
        image:
          "https://s3-alpha-sig.figma.com/img/6821/7963/eaf5cb42f1b1de6d9736edc3e6b6d640?Expires=1730678400&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=MVWaUB4HQMYD9nIkL-4ufCHRbBXnn9jst7pGhjBh5XI5RNUDEJPEEimv60PlVduFQ2bzjLyOZxC~R1cNdNOYP0mIA6TxUlpkAykxedwdAQIV9kTxfHZe~j92~9GQjXsuDj-QrOY11Yfn5QIxpsyvRudolTLJkEddYYhF67JkzfkdhQU8YtRjCOzrLN-pBTl5nIQLrNp-691JBIDHYy25~DFkFxNrGKXiIBy-AaK9ebcqeeiiknifMZABuNCmu9o0ZwrFG3ntymOnagDLVxjUJId0oIIy-832xQ0iqRwTj48rVM4QH1ReFOZ-uZyOvwA~~tLVyTcSRxmg1idUKtdsKw__",
        tasks: ["Begginer Inform 1", "Begginer Inform 2", "Begginer Inform 3"],
      },
      {
        reward: "202",
        title: "These are begginer missions",
        description:
          "Join the Stabull Road-to-IDO Campaign on Magic Square! Compete for a chance to win from a prize pool of $200,000 worth of $STABUL Tokens.",
        image:
          "https://s3-alpha-sig.figma.com/img/6821/7963/eaf5cb42f1b1de6d9736edc3e6b6d640?Expires=1730678400&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=MVWaUB4HQMYD9nIkL-4ufCHRbBXnn9jst7pGhjBh5XI5RNUDEJPEEimv60PlVduFQ2bzjLyOZxC~R1cNdNOYP0mIA6TxUlpkAykxedwdAQIV9kTxfHZe~j92~9GQjXsuDj-QrOY11Yfn5QIxpsyvRudolTLJkEddYYhF67JkzfkdhQU8YtRjCOzrLN-pBTl5nIQLrNp-691JBIDHYy25~DFkFxNrGKXiIBy-AaK9ebcqeeiiknifMZABuNCmu9o0ZwrFG3ntymOnagDLVxjUJId0oIIy-832xQ0iqRwTj48rVM4QH1ReFOZ-uZyOvwA~~tLVyTcSRxmg1idUKtdsKw__",
        tasks: ["Begginer Inform 1", "Begginer Inform 2", "Begginer Inform 3"],
      },
      {
        reward: "203",
        title: "These are begginer missions",
        description:
          "Join the Stabull Road-to-IDO Campaign on Magic Square! Compete for a chance to win from a prize pool of $200,000 worth of $STABUL Tokens.",
        image:
          "https://s3-alpha-sig.figma.com/img/6821/7963/eaf5cb42f1b1de6d9736edc3e6b6d640?Expires=1730678400&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=MVWaUB4HQMYD9nIkL-4ufCHRbBXnn9jst7pGhjBh5XI5RNUDEJPEEimv60PlVduFQ2bzjLyOZxC~R1cNdNOYP0mIA6TxUlpkAykxedwdAQIV9kTxfHZe~j92~9GQjXsuDj-QrOY11Yfn5QIxpsyvRudolTLJkEddYYhF67JkzfkdhQU8YtRjCOzrLN-pBTl5nIQLrNp-691JBIDHYy25~DFkFxNrGKXiIBy-AaK9ebcqeeiiknifMZABuNCmu9o0ZwrFG3ntymOnagDLVxjUJId0oIIy-832xQ0iqRwTj48rVM4QH1ReFOZ-uZyOvwA~~tLVyTcSRxmg1idUKtdsKw__",
        tasks: ["Begginer Inform 1", "Begginer Inform 2", "Begginer Inform 3"],
      },
      {
        reward: "20",
        title: "These are begginer missions",
        description:
          "Join the Stabull Road-to-IDO Campaign on Magic Square! Compete for a chance to win from a prize pool of $200,000 worth of $STABUL Tokens.",
        image:
          "https://s3-alpha-sig.figma.com/img/6821/7963/eaf5cb42f1b1de6d9736edc3e6b6d640?Expires=1730678400&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=MVWaUB4HQMYD9nIkL-4ufCHRbBXnn9jst7pGhjBh5XI5RNUDEJPEEimv60PlVduFQ2bzjLyOZxC~R1cNdNOYP0mIA6TxUlpkAykxedwdAQIV9kTxfHZe~j92~9GQjXsuDj-QrOY11Yfn5QIxpsyvRudolTLJkEddYYhF67JkzfkdhQU8YtRjCOzrLN-pBTl5nIQLrNp-691JBIDHYy25~DFkFxNrGKXiIBy-AaK9ebcqeeiiknifMZABuNCmu9o0ZwrFG3ntymOnagDLVxjUJId0oIIy-832xQ0iqRwTj48rVM4QH1ReFOZ-uZyOvwA~~tLVyTcSRxmg1idUKtdsKw__",
        tasks: ["Begginer Inform 1", "Begginer Inform 2", "Begginer Inform 3"],
      },
    ],
  },
  {
    missionType: "Advanced Missions",
    description:
      "All the communities you are a member of will appear here. You can join a community by clicking on the join button for a given community.",
    missions: [
      {
        reward: "20",
        title: "These are advanced missions",
        description:
          "Join the Stabull Road-to-IDO Campaign on Magic Square! Compete for a chance to win from a prize pool of $200,000 worth of $STABUL Tokens.",
        image:
          "https://s3-alpha-sig.figma.com/img/6821/7963/eaf5cb42f1b1de6d9736edc3e6b6d640?Expires=1730678400&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=MVWaUB4HQMYD9nIkL-4ufCHRbBXnn9jst7pGhjBh5XI5RNUDEJPEEimv60PlVduFQ2bzjLyOZxC~R1cNdNOYP0mIA6TxUlpkAykxedwdAQIV9kTxfHZe~j92~9GQjXsuDj-QrOY11Yfn5QIxpsyvRudolTLJkEddYYhF67JkzfkdhQU8YtRjCOzrLN-pBTl5nIQLrNp-691JBIDHYy25~DFkFxNrGKXiIBy-AaK9ebcqeeiiknifMZABuNCmu9o0ZwrFG3ntymOnagDLVxjUJId0oIIy-832xQ0iqRwTj48rVM4QH1ReFOZ-uZyOvwA~~tLVyTcSRxmg1idUKtdsKw__",
        tasks: ["Advanced Inform 1", "Advanced Inform 2", "Advanced Inform 3"],
      },
      {
        reward: "252",
        title: "These are advanced missions",
        description:
          "Join the Stabull Road-to-IDO Campaign on Magic Square! Compete for a chance to win from a prize pool of $200,000 worth of $STABUL Tokens.",
        image:
          "https://s3-alpha-sig.figma.com/img/6821/7963/eaf5cb42f1b1de6d9736edc3e6b6d640?Expires=1730678400&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=MVWaUB4HQMYD9nIkL-4ufCHRbBXnn9jst7pGhjBh5XI5RNUDEJPEEimv60PlVduFQ2bzjLyOZxC~R1cNdNOYP0mIA6TxUlpkAykxedwdAQIV9kTxfHZe~j92~9GQjXsuDj-QrOY11Yfn5QIxpsyvRudolTLJkEddYYhF67JkzfkdhQU8YtRjCOzrLN-pBTl5nIQLrNp-691JBIDHYy25~DFkFxNrGKXiIBy-AaK9ebcqeeiiknifMZABuNCmu9o0ZwrFG3ntymOnagDLVxjUJId0oIIy-832xQ0iqRwTj48rVM4QH1ReFOZ-uZyOvwA~~tLVyTcSRxmg1idUKtdsKw__",
        tasks: ["Advanced Inform 1", "Advanced Inform 2", "Advanced Inform 3"],
      },
      {
        reward: "221",
        title: "These are advanced missions",
        description:
          "Join the Stabull Road-to-IDO Campaign on Magic Square! Compete for a chance to win from a prize pool of $200,000 worth of $STABUL Tokens.",
        image:
          "https://s3-alpha-sig.figma.com/img/6821/7963/eaf5cb42f1b1de6d9736edc3e6b6d640?Expires=1730678400&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=MVWaUB4HQMYD9nIkL-4ufCHRbBXnn9jst7pGhjBh5XI5RNUDEJPEEimv60PlVduFQ2bzjLyOZxC~R1cNdNOYP0mIA6TxUlpkAykxedwdAQIV9kTxfHZe~j92~9GQjXsuDj-QrOY11Yfn5QIxpsyvRudolTLJkEddYYhF67JkzfkdhQU8YtRjCOzrLN-pBTl5nIQLrNp-691JBIDHYy25~DFkFxNrGKXiIBy-AaK9ebcqeeiiknifMZABuNCmu9o0ZwrFG3ntymOnagDLVxjUJId0oIIy-832xQ0iqRwTj48rVM4QH1ReFOZ-uZyOvwA~~tLVyTcSRxmg1idUKtdsKw__",
        tasks: ["Advanced Inform 1", "Advanced Inform 2", "Advanced Inform 3"],
      },
      {
        reward: "223",
        title: "These are advanced missions",
        description:
          "Join the Stabull Road-to-IDO Campaign on Magic Square! Compete for a chance to win from a prize pool of $200,000 worth of $STABUL Tokens.",
        image:
          "https://s3-alpha-sig.figma.com/img/6821/7963/eaf5cb42f1b1de6d9736edc3e6b6d640?Expires=1730678400&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=MVWaUB4HQMYD9nIkL-4ufCHRbBXnn9jst7pGhjBh5XI5RNUDEJPEEimv60PlVduFQ2bzjLyOZxC~R1cNdNOYP0mIA6TxUlpkAykxedwdAQIV9kTxfHZe~j92~9GQjXsuDj-QrOY11Yfn5QIxpsyvRudolTLJkEddYYhF67JkzfkdhQU8YtRjCOzrLN-pBTl5nIQLrNp-691JBIDHYy25~DFkFxNrGKXiIBy-AaK9ebcqeeiiknifMZABuNCmu9o0ZwrFG3ntymOnagDLVxjUJId0oIIy-832xQ0iqRwTj48rVM4QH1ReFOZ-uZyOvwA~~tLVyTcSRxmg1idUKtdsKw__",
        tasks: ["Advanced Inform 1", "Advanced Inform 2", "Advanced Inform 3"],
      },
      {
        reward: "100",
        title: "These are advanced missions",
        description:
          "Join the Stabull Road-to-IDO Campaign on Magic Square! Compete for a chance to win from a prize pool of $200,000 worth of $STABUL Tokens.",
        image:
          "https://s3-alpha-sig.figma.com/img/6821/7963/eaf5cb42f1b1de6d9736edc3e6b6d640?Expires=1730678400&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=MVWaUB4HQMYD9nIkL-4ufCHRbBXnn9jst7pGhjBh5XI5RNUDEJPEEimv60PlVduFQ2bzjLyOZxC~R1cNdNOYP0mIA6TxUlpkAykxedwdAQIV9kTxfHZe~j92~9GQjXsuDj-QrOY11Yfn5QIxpsyvRudolTLJkEddYYhF67JkzfkdhQU8YtRjCOzrLN-pBTl5nIQLrNp-691JBIDHYy25~DFkFxNrGKXiIBy-AaK9ebcqeeiiknifMZABuNCmu9o0ZwrFG3ntymOnagDLVxjUJId0oIIy-832xQ0iqRwTj48rVM4QH1ReFOZ-uZyOvwA~~tLVyTcSRxmg1idUKtdsKw__",
        tasks: ["Advanced Inform 1", "Advanced Inform 2", "Advanced Inform 3"],
      },
    ],
  },
];

export const dummyTableRows: TableRow[] = [
  {
    _id: "1",
    name: "User 1",
    email: "E-mail 1",
    completedTasks: "3/8",
    logIn: "log In",
  },
  {
    _id: "2",
    name: "User 2",
    email: "E-mail 2",
    completedTasks: "3/8",
    logIn: "log In",
  },
  {
    _id: "3",
    name: "User 3",
    email: "E-mail 3",
    completedTasks: "3/8",
    logIn: "log In",
  },
  {
    _id: "4",
    name: "User 4",
    email: "E-mail 4",
    completedTasks: "3/8",
    logIn: "log In",
  },
  {
    _id: "5",
    name: "User 5",
    email: "E-mail 5",
    completedTasks: "3/8",
    logIn: "log In",
  },
  {
    _id: "6",
    name: "User 6",
    email: "E-mail 6",
    completedTasks: "3/8",
    logIn: "log In",
  },
];

export const dummyTableColumns: TableColumn[] = [
  { _id: "name", title: "Name", type: "text" },
  { _id: "email", title: "E-mail", type: "text" },
  { _id: "completedTasks", title: "Completed tasks", type: "text" },
  { _id: "createdCommunities", title: "Created communities", type: "text" },
  { _id: "logIn", title: "Log In", type: "button" },
];

export const dummyStatTableRows: TableRow[] = [
  {
    _id: "1",
    name: "User 1",
    email: "E-mail 1",
    completedTasks: "3/8",
    createdCommunities: "3/8",
    nextTask: "3/8",
  },
  {
    _id: "2",
    name: "User 2",
    email: "E-mail 2",
    completedTasks: "3/8",
    createdCommunities: "3/8",
    nextTask: "3/8",
  },
  {
    _id: "3",
    name: "User 3",
    email: "E-mail 3",
    completedTasks: "3/8",
    createdCommunities: "3/8",
    nextTask: "3/8",
  },
  {
    _id: "4",
    name: "User 4",
    email: "E-mail 4",
    completedTasks: "3/8",
    createdCommunities: "3/8",
    nextTask: "3/8",
  },
  {
    _id: "5",
    name: "User 5",
    email: "E-mail 5",
    completedTasks: "3/8",
    createdCommunities: "3/8",
    nextTask: "3/8",
  },
  {
    _id: "6",
    name: "User 6",
    email: "E-mail 6",
    completedTasks: "3/8",
    createdCommunities: "3/8",
    nextTask: "3/8",
  },
];

export const dummyStatTableColumns: TableColumn[] = [
  { _id: "name", title: "Name", type: "text" },
  { _id: "email", title: "E-mail", type: "text" },
  { _id: "completedTasks", title: "Completed tasks", type: "text" },
  { _id: "createdCommunities", title: "Created communities", type: "text" },
  { _id: "nextTask", title: "Next task", type: "text" },
];

export const dummyUserMission: any = {
  _id: "671895dc72fb5f42eb22317c",
  name: "Eminem Albums Expert test",
  description: "Mission covers tasks around Eminem's Albums. test again",
  longDescription:
    "Mission covers tasks around Eminem's Albums. Mission covers tasks around Eminem's Albums. Mission covers tasks around Eminem's Albums. Mission covers tasks around Eminem's Albums.",
  community: "6718957c72fb5f42eb223173",
  users: [],
  tasks: [
    {
      _id: "673af4ebc1d583a52f85eddf",
      mission: "671895dc72fb5f42eb22317c",
      type: "X like",
      createdAt: "2024-11-18T08:03:55.864Z",
      updatedAt: "2024-11-18T08:03:55.864Z",
      order: 2,
      link: "x.like",
      __v: 0,
    },
    {
      _id: "673af518c1d583a52f85edf0",
      mission: "671895dc72fb5f42eb22317c",
      type: "Watch Youtube",
      createdAt: "2024-11-18T08:04:40.666Z",
      updatedAt: "2024-11-18T08:04:40.666Z",
      order: 1,
      link: "yt.be",
      __v: 0,
      status: "in_progress",
      startedAt: "2024-12-09T15:13:53.335Z",
    },
    {
      _id: "673af52ec1d583a52f85ee02",
      mission: "671895dc72fb5f42eb22317c",
      type: "X like",
      createdAt: "2024-11-18T08:05:02.003Z",
      updatedAt: "2024-11-18T08:05:02.003Z",
      order: 4,
      status: "failed",
      link: "tuktuk.com",
      __v: 0,
    },
    {
      _id: "673af62cc1d583a52f85ee3e",
      mission: "671895dc72fb5f42eb22317c",
      type: "X retweet",
      createdAt: "2024-11-18T08:09:16.441Z",
      updatedAt: "2024-11-18T08:09:16.441Z",
      order: 3,
      status: "completed",
      link: "retwetre",
      __v: 0,
    },
    {
      _id: "673b7af2c1d583a52f85f9f6",
      mission: "671895dc72fb5f42eb22317c",
      createdAt: "2024-11-18T17:35:46.113Z",
      updatedAt: "2024-11-18T17:35:46.113Z",
      order: 5,
      link: "sadf",
      __v: 0,
      type: "TikTok comment",
    },
  ],
  createdAt: "2024-10-23T06:21:16.767Z",
  updatedAt: "2024-10-23T06:21:16.767Z",
  __v: 0,
  featured: true,
  rewards: [
    {
      _id: "67308ea5c4d7366968f32fc8",
      name: "BLAAA",
      type: "Digital Collectible",
      mission: "671895dc72fb5f42eb22317c",
      __v: 0,
    },
    {
      _id: "67308ea5c4d7366968f32fc8",
      name: "BLAAA",
      type: "Digital Collectible",
      mission: "671895dc72fb5f42eb22317c",
      __v: 0,
    },
    {
      _id: "67308ea5c4d7366968f32fc8",
      name: "BLAAA",
      type: "Digital Collectible",
      mission: "671895dc72fb5f42eb22317c",
      __v: 0,
    },
    {
      _id: "67308ea5c4d7366968f32fc8",
      name: "BLAAA",
      type: "Digital Collectible",
      mission: "671895dc72fb5f42eb22317c",
      __v: 0,
    },
    {
      _id: "67308ea5c4d7366968f32fc8",
      name: "BLAAA",
      type: "Digital Collectible",
      mission: "671895dc72fb5f42eb22317c",
      __v: 0,
    },
    {
      _id: "67308ea5c4d7366968f32fc8",
      name: "BLAAA",
      type: "Digital Collectible",
      mission: "671895dc72fb5f42eb22317c",
      __v: 0,
    },
    {
      _id: "67308ea5c4d7366968f32fc8",
      name: "BLAAA",
      type: "Digital Collectible",
      mission: "671895dc72fb5f42eb22317c",
      __v: 0,
    },
    {
      _id: "67308ea5c4d7366968f32fc8",
      name: "BLAAA",
      type: "Digital Collectible",
      mission: "671895dc72fb5f42eb22317c",
      __v: 0,
    },
    {
      _id: "67308ea5c4d7366968f32fc8",
      name: "BLAAA",
      type: "Digital Collectible",
      mission: "671895dc72fb5f42eb22317c",
      __v: 0,
    },
    {
      _id: "67308ea5c4d7366968f32fc8",
      name: "BLAAA",
      type: "Digital Collectible",
      mission: "671895dc72fb5f42eb22317c",
      __v: 0,
    },
  ],
  reward: "5",
  entryCriteria: {
    minimumTwitterFollowers: 142,
  },
  tags: ["sport", "sleep"],
  imageUrl:
    "https://cdn.pixabay.com/photo/2017/12/20/03/46/city-3029160_1280.jpg",
};

export const dummyRewards = [
  {
    _id: "6760742bab36dad0fe30033f",
    type: RewardType.DIGITAL_COLLECTIBLE,
    name: "Digital Hug From Nika",
    successMessage: "Congratulations you can now receive digital hug from Nika",
    disclaimer: "Digital Hugs aren't Real",
    redemptionInstructions:
      "To get a digital Hug contact nika on his email: nikasakana@gmail.com",
    couponCodes: [],
    __v: 0,
    rewardImage:
      "https://pub-b9fb79e39ad54a49a2d3fcb173cb2930.r2.dev/76ec501a-6733-4e0e-afde-91a4c11f4abe",
  },
  {
    _id: "676074e3ab36dad0fe300408",
    type: RewardType.DIGITAL_GIFT,
    name: "Best Reward",
    successMessage: "Best Rewards",
    disclaimer: "It is not real",
    redemptionInstructions: "Best Reward",
    couponCodes: [],
    __v: 0,
  },
];
