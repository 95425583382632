const AddDocumentsIcon = (props) => (
  <svg
    width="18"
    height="19"
    viewBox="0 0 18 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M8.99895 16.2486H12.9265C14.2984 16.2486 15.4112 15.1358 15.4112 13.7632V9.89578C15.4112 8.5232 14.2984 7.41113 12.9265 7.41113H5.07131C3.69874 7.41113 2.58594 8.5232 2.58594 9.89578V13.7632C2.58594 15.1358 3.69874 16.2486 5.07131 16.2486H5.66488"
      stroke="white"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.61328 11.9934H10.3862M8.99936 10.6074V13.3802"
      stroke="white"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M4.63672 5.08377H13.364M6.53936 2.75H11.4605"
      stroke="white"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default AddDocumentsIcon;
