import { useMutation, useQueryClient } from "@tanstack/react-query";
import { QUERY_KEYS } from "../../constants";
import { useAPI } from "../useApi";
import { useErrorHandler } from "../useErrorHandler";

const useFeaturedToggleMission = ({ missionId }: { missionId: string }) => {
  const { patch } = useAPI();
  const queryClient = useQueryClient();
  const { handleApiError } = useErrorHandler();

  return useMutation({
    mutationFn: async (featured: boolean) =>
      patch(`/api/mission/${missionId}`, { featured: featured }),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.FEATURED_MISSIONS],
      });
    },
    onError: (error) => {
      handleApiError(error);
    },
  });
};

export default useFeaturedToggleMission;
