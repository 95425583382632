export interface NavbarItemProps {
  text: string;
  classNames?: string[];
  onClick?: () => void;
  disabled?: boolean;
  chosen?: boolean;
}

const NavbarItem: React.FC<NavbarItemProps> = ({
  text,
  classNames,
  onClick,
  disabled,
  chosen,
}: NavbarItemProps) => {
  return (
    <div
      className={`flex items-center justify-center p-1 cursor-pointer ${disabled ? "opacity-50" : ""} ${chosen ? "decoration-secondary-200 underline decoration-3 underline-offset-8" : "hover:decoration-secondary-200 hover:decoration-opacity-secondary hover:underline hover:decoration-1 hover:underline-offset-8"}`}
      onClick={() => onClick && onClick()}
    >
      <p
        className={`body-lg text-surface-900 text-center ${
          classNames?.join(" ") || ""
        }`}
      >
        {text}
      </p>
    </div>
  );
};

export default NavbarItem;
