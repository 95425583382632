import axios from "axios";
import { useMemo } from "react";
import { API_URL } from "../constants/index";
import { useAuthContext } from "./useAuthContext";

export const useAPI = () => {
  const { user } = useAuthContext();

  return useMemo(() => {
    const axiosInstance = axios.create({
      baseURL: API_URL,
    });

    axiosInstance.interceptors.request.use((config) => {
      if (user) {
        config.headers.Authorization = `Bearer ${user.token}`;
      }
      return config;
    });

    return {
      get: <T = any>(url: string, config = {}) =>
        axiosInstance.get<T>(url, config).then((e) => e.data),
      post: <T = any>(url: string, data: any, config = {}) =>
        axiosInstance.post<T>(url, data, config).then((e) => e.data),
      put: <T = any>(url: string, data: any, config = {}) =>
        axiosInstance.put<T>(url, data, config).then((e) => e.data),
      delete: <T = any>(url: string, config = {}) =>
        axiosInstance.delete<T>(url, config).then((e) => e.data),
      patch: <T = any>(url: string, data: any, config = {}) =>
        axiosInstance.patch<T>(url, data, config).then((e) => e.data),
    };
  }, [user]);
};
