const WalletIcon = (props) => (
  <svg
    width={20}
    height={20}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.5219 8.88021C12.9051 8.88021 12.4053 9.37996 12.4053 9.99604C12.4053 10.6121 12.9051 11.1119 13.5219 11.1119H17.7074C18.0525 11.1119 18.3324 11.3917 18.3324 11.7369C18.3324 12.082 18.0525 12.3619 17.7074 12.3619H13.5219C12.2155 12.3619 11.1553 11.3032 11.1553 9.99604C11.1553 8.68887 12.2155 7.63025 13.5219 7.63025H17.7074C18.0525 7.63025 18.3324 7.91007 18.3324 8.25525C18.3324 8.60046 18.0525 8.88021 17.7074 8.88021H13.5219Z"
      stroke={props.color || "#202327"}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.08301 6.87801C2.08301 4.43121 4.06634 2.44788 6.51314 2.44788H13.9028C16.3497 2.44788 18.333 4.43121 18.333 6.87801V13.1188C18.333 15.5656 16.3497 17.549 13.9028 17.549H6.51314C4.06634 17.549 2.08301 15.5656 2.08301 13.1188V6.87801ZM6.51314 3.69788C4.7567 3.69788 3.33301 5.12157 3.33301 6.87801V13.1188C3.33301 14.8753 4.7567 16.299 6.51314 16.299H13.9028C15.6593 16.299 17.083 14.8753 17.083 13.1188V6.87801C17.083 5.12157 15.6593 3.69788 13.9028 3.69788H6.51314Z"
      stroke={props.color || "#202327"}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.9707 8.35938C7.31588 8.35938 7.5957 8.63921 7.5957 8.98438V11.009C7.5957 11.3542 7.31588 11.634 6.9707 11.634C6.62552 11.634 6.3457 11.3542 6.3457 11.009V8.98438C6.3457 8.63921 6.62552 8.35938 6.9707 8.35938Z"
      fill={props.color || "#202327"}
    />
  </svg>
);
export default WalletIcon;
