const FileIcon = (props) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.9361 5.23801H8.06383C7.43952 5.23801 6.93359 4.73207 6.93359 4.10777V3.63022C6.93359 3.00592 7.43952 2.5 8.06383 2.5H11.9361C12.5604 2.5 13.0663 3.00592 13.0663 3.63022V4.10777C13.0663 4.73207 12.5604 5.23801 11.9361 5.23801Z"
      stroke={props.color || "#202327"}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13.0672 3.82812C14.795 3.82812 16.196 5.22915 16.196 6.95693V14.3715C16.196 16.0992 14.795 17.5003 13.0672 17.5003H6.93446C5.20669 17.5003 3.80566 16.0992 3.80566 14.3715V6.95693C3.80566 5.22915 5.20669 3.82812 6.93446 3.82812"
      stroke={props.color || "#202327"}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.69238 13.029L7.32722 13.6638L8.56284 12.4282"
      stroke={props.color || "#202327"}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.69238 8.98951L7.32722 9.62434L8.56284 8.38867"
      stroke={props.color || "#202327"}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.7344 13.5742H13.307"
      stroke={props.color || "#202327"}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.7344 9.53467H13.307"
      stroke={props.color || "#202327"}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default FileIcon;
