import { useQuery } from "@tanstack/react-query";
import { QUERY_KEYS } from "../../constants";
import { useAPI } from "../useApi";
import { Mission } from "../useCommunity";

export interface UserMissions {
  missions: Mission[];
}

export const useUserMissions = () => {
  const { get } = useAPI();

  return useQuery<UserMissions, Error>({
    queryKey: [QUERY_KEYS.JOINED_MISSIONS],
    queryFn: () => get(`/api/user/missions`),
  });
};

export const useUserMission = (_id: string) => {
  const { get } = useAPI();

  return useQuery<Mission, Error>({
    queryKey: [QUERY_KEYS.USER_MISSION],
    queryFn: () => get(`/api/user/mission/${_id}`),
    refetchOnWindowFocus: false,
  });
};

export const useFeaturedMissions = () => {
  const { get } = useAPI();

  return useQuery<Mission[], Error>({
    retry: false,
    queryKey: [QUERY_KEYS.FEATURED_MISSIONS],
    queryFn: () =>
      get(`/api/mission/featured`).then((res) => res.featuredMissions),
  });
};
