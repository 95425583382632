import { useAPI } from "../useApi";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { QUERY_KEYS } from "../../constants";
import { useSuccessHandler } from "../useSuccessHandler";

export interface CreateMissionType {
  name: string;
  description: string;
  communityId: string;
  minimumTwitterFollowers: string;
  imageUrl: string;
}

export interface useCreateMissionTypes {
  communityId: string;
}

export const useCreateMission = ({ communityId }: useCreateMissionTypes) => {
  const { post } = useAPI();
  const queryClient = useQueryClient();
  const { showSuccessMessage } = useSuccessHandler();

  return useMutation({
    mutationFn: async (data: CreateMissionType) => {
      return post("/api/mission", { ...data, communityId });
    },
    onSuccess: () => {
      showSuccessMessage("Mission created successfully");
      queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.COMMUNITY] });
    },
  });
};

export const useUpdateMission = () => {
  const { put } = useAPI();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (data: any) => {
      return put(`/api/mission/${data._id}`, { ...data });
    },
    onSuccess: (res) => {
      queryClient.setQueryData([QUERY_KEYS.MISSION, res._id], () => {
        return res;
      });

      queryClient.setQueryData(
        [QUERY_KEYS.COMMUNITY, res.community, true],
        (data: any) => {
          if (!data) return data;
          return {
            ...data,
            sections: (
              data as { sections: { missions: { _id: string }[] }[] }
            ).sections.map((section: { missions: { _id: string }[] }) => ({
              ...section,
              missions: section.missions.map((mission: { _id: string }) =>
                mission._id === res._id ? res : mission,
              ),
            })),
          };
        },
      );
    },
  });
};
