import { createContext, useState } from "react";
import { MissionCardProps } from "../components/CommunityPreviewCard/MissionCard";
import { Community, Section } from "../hooks/useCommunity";

export const CommunityManagementContext = createContext<any>(null);

export const CommunityManagementProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [activeTab, setActiveTab] = useState<string>("Mission Manager");
  const [activeMission, setActiveMission] = useState<
    MissionCardProps | undefined
  >(undefined);
  const [activeCommunity, setActiveCommunity] = useState<Community | undefined>(
    undefined,
  );
  const [activeSection, setActiveSection] = useState<Section | undefined>(
    undefined,
  );

  return (
    <CommunityManagementContext.Provider
      value={{
        activeTab,
        setActiveTab,
        activeMission,
        setActiveMission,
        activeCommunity,
        setActiveCommunity,
        activeSection,
        setActiveSection,
      }}
    >
      {children}
    </CommunityManagementContext.Provider>
  );
};
