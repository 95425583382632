import { useMutation, useQueryClient } from "@tanstack/react-query";
import { QUERY_KEYS } from "../../constants";
import { useAPI } from "../useApi";
import { useErrorHandler } from "../useErrorHandler";
const useDuplicateMission = ({ missionId }: { missionId: string }) => {
  const { post } = useAPI();
  const queryClient = useQueryClient();
  const { handleApiError } = useErrorHandler();
  const {
    mutate: duplicateMission,
    isPending: isDuplicateMissionPending,
    isError: isDuplicateMissionError,
    error: duplicateMissionError,
    isSuccess: isDuplicateMissionSuccess,
  } = useMutation({
    mutationFn: async () => post(`/api/mission/${missionId}/duplicate`, {}),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.COMMUNITY] });
    },
    onError: (error) => {
      handleApiError(error);
    },
  });

  return {
    duplicateMission,
    isDuplicateMissionPending,
    isDuplicateMissionError,
    duplicateMissionError,
    isDuplicateMissionSuccess,
  };
};

export default useDuplicateMission;
