import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useAPI } from "../useApi";
import { QUERY_KEYS } from "../../constants";
import { User } from "../../types";
import { useAuthContext } from "../useAuthContext";
import { useErrorHandler } from "../useErrorHandler";

export enum TaskStatus {
  FAILED = "failed",
  IN_PROGRESS = "in_progress",
  COMPLETED = "completed",
  LOCKED = "locked",
}

export interface Task {
  // name?: string;
  // description?: string;
  mission?: string;
  type: string;
  link: string;
  createdAt: string;
  updatedAt: string;
  order?: number;
  status?: TaskStatus;
  _id?: string;
  image?: string;
}

export interface Reward {
  _id?: string;
  name?: string;
  type: "Digital Collectible" | "Digital Gift" | "Offline Gift";
  fileLink?: string;
  rewardImage?: string;
  rewardEmoji?: string;
  imageUrl?: string;
  units?: number;
  unitsPerUser?: number;
  mission?: Mission;
  fileLinks?: string[];
  description?: string;
  totalRedeemLimit?: number;
  disclaimer?: string;
  validityPeriodStart?: Date;
  validityPeriodEnd?: Date;
  redemptionInstructions?: string;
  fulfilmentOption?: "1st party" | "3rd party" | "In-venue";
  thirdPartyRedemptionUrl?: string;
  couponCodes?: string[];
  uniqueCouponCode?: boolean;
  successMessage?: string;
  venueName?: string;
  venueAddress?: string;
  venueLocationLink?: string;
  status?: string;
  address?: string;
  userRedeemedCouponCode?: string;
}

export interface Mission {
  name: string;
  description: string;
  community: Community;
  users: string[];
  tasks: Task[];
  createdAt: string;
  updatedAt: string;
  _id: string;
  reward: Reward;
  rewards: Reward[];
  type: string;
  imageUrl: string;
  completedAt: string;
  completedTasks: Array<Task>;
  nextTask: Task;
  tags?: string[];
  active: boolean;
  launched: boolean;
  unlaunched: boolean;
  oversubscribed: boolean;
  entryCriteria?: any;
  disclaimer?: string;
  validFrom?: string;
  validTo?: string;
  author?: User;
}

export interface Section {
  _id?: string;
  name: string;
  missions: Mission[];
}

export interface Member {
  _id: string;
  email: string;
  name: string;
  role: string;
}

export interface Community {
  _id: string;
  name: string;
  description: string;
  private: boolean;
  socials: {
    type: string;
    url: string;
  }[];
  logoUrl: string;
  coverImageUrl: string;
  url: string;
  tags: string[];
  createdAt: Date;
  updatedAt: Date;
  author: User;
  members: User[];
  allowedStrategy: string;
  missions: Mission[];
  sections?: Section[];
}

export const useCommunity = ({
  _id,
  includeInactive = false,
  includeUnlaunched = false,
  includeOversubscribed = false,
}: {
  _id: string | undefined;
  includeInactive?: boolean;
  includeUnlaunched?: boolean;
  includeOversubscribed?: boolean;
}) => {
  const { get } = useAPI();

  return useQuery({
    queryKey: [
      QUERY_KEYS.COMMUNITY,
      _id,
      includeInactive,
      includeUnlaunched,
      includeOversubscribed,
    ],
    queryFn: () =>
      _id &&
      get(
        `/api/community/${_id}?includeInactive=${includeInactive}&includeUnlaunched=${includeUnlaunched}&includeOversubscribed=${includeOversubscribed}`,
      ),
  });
};

export const useCommunityWithUsername = ({
  username,
  includeInactive = false,
  includeUnlaunched = false,
  includeOversubscribed = false,
}: {
  username: string | undefined;
  includeInactive?: boolean;
  includeUnlaunched?: boolean;
  includeOversubscribed?: boolean;
}) => {
  const { get } = useAPI();

  return useQuery({
    retry: false,
    queryKey: [
      QUERY_KEYS.COMMUNITY,
      username,
      includeInactive,
      includeUnlaunched,
      includeOversubscribed,
    ],
    queryFn: () =>
      username &&
      get(
        `/api/community/by-url/${username}?includeInactive=${includeInactive}&includeUnlaunched=${includeUnlaunched}&includeOversubscribed=${includeOversubscribed}`,
      ),
  });
};

export const useJoinCommunity = ({ _id }: { _id: string | undefined }) => {
  const { post } = useAPI();
  const queryClient = useQueryClient();
  const { updateUserContext } = useAuthContext();
  const { handleApiError } = useErrorHandler();
  return useMutation({
    mutationFn: async () => post(`/api/community/${_id}/join`, {}),
    onSuccess: async (newJoinedCommunity) => {
      queryClient.setQueryData(
        [QUERY_KEYS.CURRENT_USER],
        (oldData: User | undefined) => {
          if (!oldData) return oldData;

          console.log({
            joinedCommunities: [
              ...oldData.joinedCommunities,
              newJoinedCommunity,
            ],
          });

          return {
            ...oldData,
            joinedCommunities: [
              ...oldData.joinedCommunities,
              newJoinedCommunity,
            ],
          };
        },
      );

      updateUserContext({
        joinedCommunities: [
          ...(queryClient.getQueryData<User>([QUERY_KEYS.CURRENT_USER])
            ?.joinedCommunities || []),
          newJoinedCommunity,
        ],
      });
    },
    onError: (error) => {
      handleApiError(error);
    },
  });
};
