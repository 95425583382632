const FlagIcon = (props) => (
  <svg
    width={18}
    height={18}
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.7589 6.0432L4.72588 1.7427C4.55187 1.6587 4.34787 1.67145 4.18362 1.77495C4.02087 1.87695 3.92188 2.05695 3.92188 2.2497V15.7497C3.92188 16.0602 4.17388 16.3122 4.48438 16.3122C4.79488 16.3122 5.04688 16.0602 5.04688 15.7497V11.2054L13.7589 7.0587C13.9554 6.96495 14.0799 6.7677 14.0799 6.55095C14.0799 6.3342 13.9554 6.1362 13.7589 6.0432Z"
      fill="white"
    />
  </svg>
);
export default FlagIcon;
