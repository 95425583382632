const HorizontalMenu = (props) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.7891 11.5634C17.7891 11.0054 18.2413 10.5526 18.7998 10.5526C19.3576 10.5526 19.8106 11.0048 19.8106 11.5634C19.8106 12.122 19.3576 12.5742 18.7998 12.5742C18.2413 12.5742 17.7891 12.1214 17.7891 11.5634Z"
      fill="#202327"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.7656 11.5634C11.7656 11.0054 12.2179 10.5526 12.7763 10.5526C13.3342 10.5526 13.7872 11.0048 13.7872 11.5634C13.7872 12.122 13.3342 12.5742 12.7763 12.5742C12.2179 12.5742 11.7656 12.1214 11.7656 11.5634Z"
      fill="#202327"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.74609 11.5634C5.74609 11.0054 6.19833 10.5526 6.75679 10.5526C7.31467 10.5526 7.76765 11.0048 7.76765 11.5634C7.76765 12.122 7.31467 12.5742 6.75679 12.5742C6.19832 12.5742 5.74609 12.1214 5.74609 11.5634Z"
      fill="#202327"
    />
  </svg>
);
export default HorizontalMenu;
