import * as React from "react";

const SearchIcon = (props) => (
  <svg
    width={25}
    height={25}
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.43711 2.625C5.4181 2.625 2.9707 5.07239 2.9707 8.09142C2.9707 11.1104 5.4181 13.5578 8.43711 13.5578C11.4561 13.5578 13.9035 11.1104 13.9035 8.09142C13.9035 5.07239 11.4561 2.625 8.43711 2.625ZM1.8457 8.09142C1.8457 4.45108 4.79677 1.5 8.43711 1.5C12.0775 1.5 15.0285 4.45108 15.0285 8.09142C15.0285 11.7318 12.0775 14.6828 8.43711 14.6828C4.79677 14.6828 1.8457 11.7318 1.8457 8.09142Z"
      fill="#202327"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.5523 11.7358L16.7639 15.9365L15.9694 16.733L11.7578 12.5323L12.5523 11.7358Z"
      fill="#202327"
    />
  </svg>
);
export default SearchIcon;
