import Button from "../Button";
import { useUser } from "../../hooks";
import CloseIcon from "../../assets/icons/close";
import { useState } from "react";
import { SocialNetworkLink } from "../../types";
import SocialMediaEditor from "../SocialMediaEditor";
import { Loader } from "../Loader";

const EditSocialMedia = ({ closeModal }: { closeModal: () => void }) => {
  const {
    user,
    updateUser,
    isUserError,
    userError,
    isUserPending,
    isUserSuccess,
  } = useUser();
  const [socialNetworkLinks, setSocialNetworkLinks] = useState<
    SocialNetworkLink[]
  >(user?.socialNetworkLinks || []);

  const handleDelete = (_id?: string) => {
    setSocialNetworkLinks(
      socialNetworkLinks.filter((elem) => elem._id !== _id),
    );
  };

  const handleSubmit = () => {
    updateUser({
      socialNetworkLinks: socialNetworkLinks.map((elem) => {
        return { networkName: elem.networkName, url: elem.url };
      }),
    });
  };

  const handleUpdate = (
    item: "networkName" | "url",
    value: string,
    _id?: string,
  ) => {
    setSocialNetworkLinks((prevState) => {
      const tmpState = [...prevState];
      const index = tmpState.findIndex((elem) => elem._id === _id);
      tmpState[index][item] = value;
      return tmpState;
    });
  };

  return (
    <div className="flex flex-col p-1 gap-4 w-full relative">
      <CloseIcon
        className="absolute top-2 right-2 cursor-pointer"
        onClick={closeModal}
      />
      <p className="title-lg-medium text-surface-900">Edit Social Media</p>
      <div className="flex flex-col w-full gap-8">
        <div className="flex flex-col w-full gap-3">
          <div className="max-h-[500px] overflow-auto">
            <SocialMediaEditor
              socialNetworkLinks={socialNetworkLinks}
              setSocialNetworkLinks={setSocialNetworkLinks}
              handleDelete={handleDelete}
              handleUpdate={handleUpdate}
              direction="col"
            />
          </div>

          {isUserPending ? (
            <div className="flex justify-center items-center">
              <Loader size="md" />
            </div>
          ) : (
            <Button className="rounded-[10px] py-3 px-5" action={handleSubmit}>
              Submit
            </Button>
          )}
          {isUserError && <p>{userError?.message}</p>}
          {isUserSuccess && <p>Social Media updated successfully</p>}
        </div>
      </div>
    </div>
  );
};

export default EditSocialMedia;
