import { useContext, useMemo } from "react";
import { Reward } from "../../hooks/useCommunity";
import BoonModal from "../Modal";
import DigitalCollectibleRewardRedemption from "./DigitalCollectibleRewardRedemption";
import DigitalGiftRewardRedemption from "./DigitalGiftRewardRedemption";
import { CloseIcon } from "../../assets/icons";
import OfflineGiftRewardRedemption from "./OfflineGiftRewardRedemption";
import { RewardContext } from "../../context/RewardContext";

export enum RewardType {
  OFFLINE_GIFT = "Offline Gift",
  DIGITAL_COLLECTIBLE = "Digital Collectible",
  DIGITAL_GIFT = "Digital Gift",
}

const RewardRedemptionModal = () => {
  const {
    activeRedeemableReward,
    setActiveRedeemableReward,
  }: {
    activeRedeemableReward: Reward;
    setActiveRedeemableReward: (data: Reward | undefined) => void;
  } = useContext(RewardContext);
  const componentMapper = useMemo(
    () => ({
      [RewardType.DIGITAL_COLLECTIBLE]: <DigitalCollectibleRewardRedemption />,
      [RewardType.DIGITAL_GIFT]: <DigitalGiftRewardRedemption />,
      [RewardType.OFFLINE_GIFT]: (
        <OfflineGiftRewardRedemption reward={activeRedeemableReward} />
      ),
    }),
    [],
  );
  return (
    <BoonModal
      onClose={() => setActiveRedeemableReward(undefined)}
      style={{ padding: "0px", overflow: "hidden" }}
      options={
        activeRedeemableReward.type === RewardType.OFFLINE_GIFT
          ? { maxHeight: "98vh" }
          : {}
      }
    >
      <div className="overflow-auto max-h-[90vh] relative">
        <CloseIcon
          color="white"
          onClick={() => setActiveRedeemableReward(undefined)}
          className="absolute top-6 right-6 cursor-pointer"
        />
        {componentMapper[activeRedeemableReward.type]}
      </div>
    </BoonModal>
  );
};

export default RewardRedemptionModal;
