import * as React from "react";
const PlusIcon = (props) => (
  <svg
    width={17}
    height={16}
    viewBox="0 0 17 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M8.5 3.33325C8.77607 3.33325 9 3.55713 9 3.83325V7.33325H12.5C12.7761 7.33325 13 7.55712 13 7.83325C13 7.98432 12.9329 8.11972 12.8271 8.21132C12.7396 8.28725 12.625 8.33325 12.5 8.33325H9V11.8333C9 12.1094 8.77607 12.3333 8.5 12.3333C8.22393 12.3333 8 12.1094 8 11.8333V8.33325H4.5C4.22396 8.33325 4 8.10939 4 7.83325C4 7.55712 4.22396 7.33325 4.5 7.33325H8V3.83325C8 3.55713 8.22393 3.33325 8.5 3.33325Z"
      fill={props?.color || "#9382FF"}
    />
  </svg>
);
export default PlusIcon;
