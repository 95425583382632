export interface AvatarTypes {
  avatarSrc: string;
  className?: string;
  imageClassName?: string;
}

const Avatar = ({
  avatarSrc,
  className = "",
  imageClassName = "",
}: AvatarTypes) => {
  return (
    <div className={`${className} w-14 h-fit`}>
      <img
        src={avatarSrc}
        className={`w-full rounded-full ${imageClassName}`}
        alt="avatar_image"
      />
    </div>
  );
};

export default Avatar;
