import { useMutation } from "@tanstack/react-query";
import { useAPI } from "./useApi";
import { useErrorHandler } from "./useErrorHandler";

const useTwitterOauth = ({ onSuccess }: { onSuccess: (data: any) => void }) => {
  const { get } = useAPI();
  const { handleApiError } = useErrorHandler();

  return useMutation({
    mutationFn: () => get(`/api/twitter/auth`),
    onSuccess: (data) => {
      onSuccess(data);
    },
    onError: (error) => {
      handleApiError(error);
    },
  });
};

export default useTwitterOauth;
