interface TaskProgressProps {
  numberOfMissions?: number;
  numberOfMissionsDone?: number;
  numberOfOngoingMissions?: number;
}

const TaskProgress = ({
  numberOfMissions,
  numberOfMissionsDone = 0,
  numberOfOngoingMissions = 0,
}: TaskProgressProps) => {
  const calculatePercentage = (number1?: number, number2?: number) =>
    number1 && number2 ? `${Math.floor((number1 / number2) * 100)}%` : "0%";
  const doneItemsPercentage = calculatePercentage(
    numberOfMissionsDone,
    numberOfMissions,
  );
  const ongoingItemsPercentage = calculatePercentage(
    numberOfOngoingMissions,
    numberOfMissions,
  );
  return (
    <div className="flex flex-col items-center w-2/3 justify-center border border-solid border-surface-250 rounded-[10px] bg-surface-150 h-[89px] ml-3 p-5">
      <div className="w-full body-xl text-surface-900">Missions</div>
      <div className="h-1.5 w-full my-2">
        <div className="w-full bg-surface-300 rounded-full h-1.5 mb-4 flex">
          <div
            className="bg-warning-400 h-1.5 rounded-full"
            style={{ width: doneItemsPercentage }}
          />
          <div
            className="bg-primary-400 h-1.5 rounded-full"
            style={{ width: ongoingItemsPercentage }}
          />
        </div>
      </div>
      <div className="w-full flex justify-between">
        <div className="body-sm text-surface-500">Total Missions</div>
        <div className="flex items-center">
          <div className="body-sm-bold text-surface-900">
            {doneItemsPercentage}
          </div>
          <div className="body-sm text-surface-500 ml-1">
            {`(${numberOfMissionsDone}/${numberOfOngoingMissions}/${numberOfMissions})`}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TaskProgress;
